import * as React from "react";
import "./Profile.css";
import { path, url } from "../request";
import { Form } from "react-bootstrap";
import { useNotify, useTranslate } from "react-admin";
import { HTTP } from "../axios";
import Button from "@mui/material/Button";

export default () => {
  const translate = useTranslate();
  const notify = useNotify();

  const [firstName, setFirstName] = React.useState(
    localStorage.getItem("firstName") ?? null
  );
  const [middleName, setMiddleName] = React.useState(
    localStorage.getItem("middleName") ?? null
  );
  const [lastName, setLastName] = React.useState(
    localStorage.getItem("lastName") ?? null
  );
  const [username, setUsername] = React.useState(
    localStorage.getItem("userName")
  );

  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);
  const [confirmNewPassword, setConfirmNewPassword] = React.useState(null);

  const [image, setImage] = React.useState(
    localStorage.getItem("image") !== "null"
      ? `${path}/${localStorage.getItem("image")}`
      : "https://via.placeholder.com/150"
  );

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  }

  const [file, setFile] = React.useState(null);
  function handleFile(e) {
    setFile(e.target.files[0]);
  }

  async function uploadFile() {
    let formData = new FormData();
    formData.append("file", file);
    if (!file) {
      notify(translate("resources.root.pleaseChooseImage"), "error");
    } else {
      const response = await HTTP.post(
        `${url}/Accounts/UploadProfileImage`,
        formData
      ).catch((err) => {
        notify(err.response.data.message, "error");
      });
      localStorage.setItem("image", response.data);
      setImage(`${path}/${response.data}`);
      window.location.reload();
    }
  }

  async function handleUpdateInfo() {
    if (!firstName || !middleName || !username) {
      notify(translate("resources.root.pleaseWriteFullName"), "error");
    } else if (
      firstName.length < 3 ||
      middleName.length < 3 ||
      username.length < 3
    ) {
      notify(translate("resources.root.namesMustBeMoreThan3Chars"), "error");
    } else {
      await HTTP.post(`${url}/Accounts/ChangeAccountInfo`, {
        firstName,
        middleName,
        lastName,
        username,
      })
        .then(() => {
          localStorage.setItem("firstName", firstName);
          localStorage.setItem("middleName", middleName);
          localStorage.setItem("lastName", lastName);
          localStorage.setItem("username", username);
          notify("resources.root.updated", "success");
        })
        .catch((err) => {
          notify(err.response.data.message, { type: "error", undoable: true });
        });
    }
  }

  async function handleUpdatePassword() {
    if (!oldPassword || !newPassword || !confirmNewPassword) {
      notify(translate("resources.root.pleaseWriteAllPasswords"), "error");
    } else if (newPassword !== confirmNewPassword) {
      notify(translate("resources.root.passwordsAreNotMatch"), "error");
    } else {
      await HTTP.post(`${url}/Accounts/ChangePassword`, {
        currentPassword: oldPassword,
        password: newPassword,
        confirmPassword: confirmNewPassword,
      })
        .catch((err) => {
          notify(err.response.data.message, { type: "error", undoable: true });
        })
        .finally(() => {
          notify("resources.root.updated", "success");
        });
    }
  }

  return (
    <div className="row p-3 text-start">
      <div className="d-flex flex-column justify-content-center align-items-center col-12 col-lg-9">
        <div className="card my-2">
          <div className="card-body">
            <div className="row gutters">
              <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                <h4 className="mt-3 mb-2 text-primary">
                  {translate("resources.root.accountInfo")}
                </h4>
              </div>
              <div className="col-12 col-md-4 my-2">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label htmlFor="firstName" className="px-1 m-0 text-nowrap">
                    {translate("resources.root.firstName")}:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    placeholder={translate("resources.root.firstName")}
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 my-2">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label htmlFor="middleName" className="px-1 m-0 text-nowrap">
                    {translate("resources.root.middleName")}:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="middleName"
                    placeholder={translate("resources.root.middleName")}
                    value={middleName}
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4 my-2">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label htmlFor="lastName" className="px-1 m-0 text-nowrap">
                    {translate("resources.root.lastName")}:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    placeholder={translate("resources.root.lastName")}
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 my-2">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label htmlFor="username" className="px-1 m-0 text-nowarp">
                    {translate("resources.root.username")}:
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="username"
                    placeholder={translate("resources.root.username")}
                    value={username}
                    onChange={(e) =>
                      setUsername(e.target.value.replace(/\s+/g, ""))
                    }
                  />
                </div>
              </div>
              <div className="col-12 col-md-6 my-2">
                <div className="form-group">
                  <button
                    type="button"
                    id="submit"
                    name="submit"
                    className="btn btn-primary btn-block"
                    onClick={handleUpdateInfo}
                  >
                    {translate("resources.root.updateInfo")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card my-2">
          <div className="card-body">
            <div className="row gutters">
              <div className=" col-12">
                <h4 className="mt-3 mb-2 text-primary">
                  {translate("resources.root.updatePassword")}
                </h4>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label htmlFor="oldPassword" className="px-1 m-0 text-nowrap">
                    {translate("resources.root.oldPassword")}:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="oldPassword"
                    placeholder={translate("resources.root.oldPassword")}
                    onChange={(e) => setOldPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label htmlFor="newPassword" className="px-1 m-0 text-nowrap">
                    {translate("resources.root.newPassword")}
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    placeholder={translate("resources.root.newPassword")}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <label
                    htmlFor="confirmNewPassword"
                    className="px-1 m-0 text-nowrap"
                  >
                    {translate("resources.root.confirmNewPassword")}:
                  </label>
                  <input
                    type="password"
                    className="form-control"
                    id="confirmNewPassword"
                    placeholder={translate("resources.root.confirmNewPassword")}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="form-group d-flex align-items-center justify-content-center m-0 p-0">
                  <button
                    type="button"
                    id="submit"
                    disabled={
                      !oldPassword ||
                      !newPassword ||
                      !confirmNewPassword ||
                      confirmNewPassword !== newPassword
                    }
                    name="submit"
                    className="btn btn-primary btn-block"
                    onClick={handleUpdatePassword}
                  >
                    {translate("resources.root.updatePassword")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-12 col-lg-3">
        <div className="card h-100">
          <div className="card-body">
            <div className="account-settings">
              <div className="user-profile">
                <div className="user-avatar">
                  <img
                    className="object-fit-contain"
                    src={image}
                    alt={`${firstName} ${middleName} ${lastName}`}
                  />
                </div>
                <h5 className="user-name">
                  {firstName} {middleName} {lastName}
                </h5>
              </div>
              <div className="about d-flex flex-column justify-content-center align-items-between">
                {/* <Form.Control
                  type={`file`}
                  id={`avatar`}
                  onChange={(e) => handleFile(e)}
                /> */}
                <Button
                  className="imageButton btn-block py-2"
                  color="primary"
                  variant="contained"
                  component="span"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    fill="currentColor"
                    className="bi bi-upload mx-2 "
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                    <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                  </svg>
                  {translate("resources.root.uploadImg")}
                  <input
                    className=""
                    type={`file`}
                    id={`avatar`}
                    accept="image/*"
                    onChange={async (e) => {
                      const file = e.target.files[0];
                      const base64 = await getBase64(file);
                      handleFile(e);
                      setImage(base64);
                    }}
                  />
                </Button>
                <Button
                  type="button "
                  color="success"
                  variant="contained"
                  id="submit"
                  name="submit"
                  className="btn-block py-2"
                  onClick={uploadFile}
                >
                  {translate("resources.root.updateAvatar")}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
