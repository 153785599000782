import * as React from "react";
import { useRedirect, useNotify, useTranslate } from "react-admin";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import Chip from "@mui/material/Chip";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import { el } from "date-fns/locale";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import { create } from "jss";
import rtl from "jss-rtl";
import { StylesProvider, jssPreset } from "@mui/styles";
import { makeStyles } from "@mui/styles";
import { Form } from "react-bootstrap";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import moment from "moment";
import loadOptions from "../../loadOptions";

export const ScRepresentativeInvoice = ({ invoice }) => {
  const notify = useNotify();
  const classes = useStyles();
  const translate = useTranslate();
  const [customers, setCustomers] = React.useState([]);
  const [previousSellPrice, setPreviousSellPrice] = React.useState(null);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [payByMain, setPayByMain] = React.useState(false);
  const [invoiceNumber, setInvoiceNumber] = React.useState();
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    invoice === "return"
      ? localStorage.getItem("scRepresentativeReturnInvoiceOrders")
        ? JSON.parse(
            localStorage.getItem("scRepresentativeReturnInvoiceOrders")
          )
        : []
      : localStorage.getItem("scRepresentativeInvoiceOrders")
      ? JSON.parse(localStorage.getItem("scRepresentativeInvoiceOrders"))
      : []
  );
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPriceInUSD, setModifiedPriceInUSD] = React.useState(0);
  const [modifiedPriceInIQD, setModifiedPriceInIQD] = React.useState(0);
  const [productDiscount, setProductDiscount] = React.useState(0);
  const [gift, setGift] = React.useState(0);
  const [productNote, setProductNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [delay, setDelay] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [jss, setJss] = React.useState(
    create({ plugins: [...jssPreset().plugins, rtl()] })
  );

  const redirect = useRedirect();

  React.useEffect(() => {
    HTTP.get(`${url}/Representatives?_end=1000&byMain=false`).then((res) => {
      setCustomers(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct &&
      selectedCustomer &&
      HTTP.get(
        `${url}/RepresentativeInvoices/GetLastPriceOfProduct?representativeId=${selectedCustomer.id}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousSellPrice(res.data);
      });
    let repe = invoice === "return" ? "true" : "false";
    {
      selectedCustomer &&
        HTTP.get(
          `${url}/Products?_end=10000&representativeStock=${repe}&representativeId=${selectedCustomer.id}`
        )
          .then((res) => {
            setProducts(res.data);
          })
          .catch((err) =>
            notify(err.response.data.message, { type: "error", undoable: true })
          );
    }
  }, [selectedCustomer, selectedProduct]);

  React.useEffect(() => {
    if (invoice === "return") {
      localStorage.removeItem("scRepresentativeReturnInvoiceOrders");
      localStorage.setItem(
        "scRepresentativeReturnInvoiceOrders",
        JSON.stringify(orders)
      );
    } else {
      localStorage.removeItem("scRepresentativeInvoiceOrders");
      localStorage.setItem(
        "scRepresentativeInvoiceOrders",
        JSON.stringify(orders)
      );
    }
  }, [orders]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcSellPrice = modifiedPriceInUSD);
  }, [modifiedPriceInUSD]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.scSellPrice = modifiedPriceInIQD);
  }, [modifiedPriceInIQD]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.scSellPrice = modifiedPriceInUSD);
  }, [modifiedPriceInUSD]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const ratio = localStorage.getItem("dollarPrice");

  return (
    <StylesProvider jss={jss}>
      <Card className={classes.root}>
        <CardContent>
          <div className={classes.invoice}>
            <div className={classes.invoiceCols}>
              <Autocomplete
                id="combo-box-demo"
                options={customers}
                getOptionLabel={(option) =>
                  `${option.firstName} ${option.middleName}`
                }
                style={{ width: 300 }}
                onChange={(el, val) => {
                  setDelay(false);
                  setSelectedCustomer(val);
                }}
                renderInput={(params) => (
                  <MaterialTextField
                    {...params}
                    error={submitAttempted && !selectedCustomer}
                    label={translate("resources.root.chooseRepresentative")}
                    variant="outlined"
                  />
                )}
                className={classes.autocomplete}
              />
              <Form.Group className={`m-4`}>
                <Form.Control
                  type="date"
                  size={"lg"}
                  onChange={(e) => setSelectedDate(e.target.value)}
                />
              </Form.Group>
              <MaterialTextField
                style={{ display: "none" }}
                label={translate("resources.root.invoiceNumber")}
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                className={classes.invoiceNumberInput}
              />
            </div>

            <div className={classes.invoiceCols}>
              <Chip
                icon={<PhoneIphoneIcon />}
                label={
                  selectedCustomer
                    ? selectedCustomer.phone
                    : translate("resources.root.phone")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                label={
                  selectedCustomer
                    ? `${translate("resources.root.currentLoan")} ${
                        selectedCustomer.payLoanByMain
                          ? selectedCustomer.mcCurrentLoan?.toLocaleString()
                          : selectedCustomer.scCurrentLoan?.toLocaleString()
                      } ${
                        selectedCustomer.payLoanByMain
                          ? `${translate("resources.root.usd")}`
                          : ` ${translate("resources.root.iqd")}`
                      }`
                    : translate("resources.root.currentLoan")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                // icon={<LocalAtmIcon />}
                label={
                  selectedCustomer
                    ? `${translate("resources.root.maxLoan")} ${
                        selectedCustomer.payLoanByMain
                          ? selectedCustomer.mcRoofLoan?.toLocaleString()
                          : selectedCustomer.scRoofLoan?.toLocaleString()
                      } ${
                        selectedCustomer.payLoanByMain
                          ? `${translate("resources.root.usd")}`
                          : ` ${translate("resources.root.iqd")}`
                      }`
                    : translate("resources.root.maxLoan")
                }
                className={classes.bottomLeftMargin}
              />
              <Chip
                // icon={<LocalAtmIcon />}
                label={
                  selectedCustomer
                    ? `${translate("resources.root.futureLoan")} ${(
                        roundToTwo(
                          selectedCustomer.payLoanByMain
                            ? parseFloat(selectedCustomer.mcCurrentLoan)
                            : parseFloat(selectedCustomer.scCurrentLoan)
                        ) +
                        (invoice === "return" ? -1 : 1) *
                          (!cashPayment
                            ? parseFloat(
                                orders
                                  .map(
                                    (el) =>
                                      (selectedCustomer.payLoanByMain
                                        ? parseFloat(el.mcSellPrice) * el.qte
                                        : parseFloat(el.scSellPrice)) * el.qte
                                  )
                                  .reduce((a, b) => a + b, 0) - discount
                              )
                            : 0)
                      )?.toLocaleString()} ${
                        selectedCustomer.payLoanByMain
                          ? `${translate("resources.root.usd")}`
                          : ` ${translate("resources.root.iqd")}`
                      }`
                    : translate("resources.root.futureLoan")
                }
                className={classes.bottomLeftMargin}
              />
            </div>
          </div>
          <div className={classes.invoice}>
            <div className={classes.toggles}>
              <FormControlLabel
                control={
                  <Switch
                    checked={cashPayment}
                    onChange={(e) => {
                      setCashPayment(e.target.checked);
                      setDelay(false);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={translate("resources.root.cash")}
              />
            </div>
          </div>
          <div className={classes.orders}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper className={classes.paper}>
                  <div className={classes.orderCols}>
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.productMeasure")} - ${
                              selectedProduct.productMeasure
                            }`
                          : translate("resources.root.productMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneMeasure")} - ${
                              selectedProduct.oneMeasure
                            }`
                          : translate("resources.root.oneMeasure")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.oneContains")} ${
                              selectedProduct.oneContains
                            } ${translate("resources.root.items")}`
                          : translate("resources.root.oneContains")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {selectedProduct && selectedProduct.sellByMain ? (
                      <Chip
                        label={
                          selectedProduct
                            ? `${translate("resources.root.totalPrice")} - $${
                                modifiedPriceInUSD > 0
                                  ? roundToTwo(modifiedPriceInUSD * orderQtt)
                                  : roundToTwo(
                                      selectedProduct.mcSellPrice * orderQtt
                                    )
                              }`
                            : translate("resources.root.totalPrice")
                        }
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      />
                    ) : (
                      <Chip
                        label={
                          selectedProduct
                            ? `${translate("resources.root.totalPrice")} - ${
                                modifiedPriceInIQD > 0
                                  ? roundToTwo(modifiedPriceInIQD * orderQtt)
                                  : roundToTwo(
                                      selectedProduct.scSellPrice * orderQtt
                                    )
                              }  IQD`
                            : translate("resources.root.totalPrice")
                        }
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      />
                    )}
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.currency")} - ${
                              selectedProduct.sellByMain
                                ? `${translate("resources.root.usd")}`
                                : ` ${translate("resources.root.iqd")}`
                            }`
                          : translate("resources.root.currency")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                    {previousSellPrice && (
                      <Chip
                        label={
                          selectedProduct &&
                          `${previousSellPrice.scSellPrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                        }
                        className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                        style={{
                          backgroundColor: "lightgreen",
                          color: "black",
                          marginLeft: "auto",
                        }}
                      />
                    )}
                  </div>
                  <div className={classes.orderCols}>
                    <div style={{ width: "350px" }}>
                      <AsyncSelect
                        defaultOptions
                        menuPortalTarget={document.body}
                        loadOptions={loadOptions.products}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                        onChange={(e) => {
                          setSelectedProduct(e.product);
                          setOrderQtt(1);
                          setModifiedPriceInIQD(
                            e.product ? e.product.scSellPrice : 0
                          );
                        }}
                      />
                    </div>
                    {/*<Autocomplete*/}
                    {/*  id="combo-box-demo"*/}
                    {/*  options={products}*/}
                    {/*  getOptionLabel={(option) =>*/}
                    {/*    `${option.name} - ${option.barcode}`*/}
                    {/*  }*/}
                    {/*  style={{ width: 300 }}*/}
                    {/*  onChange={(el, val) => {*/}
                    {/*    setSelectedProduct(val);*/}
                    {/*    setOrderQtt(0);*/}
                    {/*    setModifiedPriceInUSD(0);*/}
                    {/*    setModifiedPriceInIQD(0);*/}
                    {/*  }}*/}
                    {/*  renderInput={(params) => (*/}
                    {/*    <MaterialTextField*/}
                    {/*      {...params}*/}
                    {/*      label={translate("resources.root.chooseProduct")}*/}
                    {/*      variant="outlined"*/}
                    {/*    />*/}
                    {/*  )}*/}
                    {/*/>*/}
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.quantity")}
                      disabled={!selectedProduct}
                      value={orderQtt}
                      onChange={(e) => {
                        //TODO conditional rendering
                        !invoice !== "return"
                          ? ((parseFloat(e.target.value) +
                              orders
                                .filter(
                                  (el) => el.productId === selectedProduct.id
                                )
                                .map(
                                  (el) =>
                                    parseFloat(el.qte) + parseFloat(el.giftQte)
                                )
                                .reduce((a, b) => a + b, 0) +
                              gift <=
                              (selectedProduct &&
                                parseFloat(selectedProduct.qteInStock)) &&
                              e.target.value >= 0) ||
                              selectedProduct.canSellBellowZero) &&
                            setOrderQtt(e.target.value)
                          : setOrderQtt(e.target.value);
                      }}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      label={translate("resources.root.max")}
                      disabled={true}
                      value={selectedProduct ? selectedProduct.qteInStock : 0}
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.priceInUSD")}
                      disabled={
                        !selectedProduct ||
                        (selectedProduct && !selectedProduct.sellByMain)
                      }
                      value={
                        modifiedPriceInUSD <= 0 || modifiedPriceInIQD > 0
                          ? selectedProduct
                            ? selectedProduct.sellByMain
                              ? selectedProduct.mcSellPrice
                              : modifiedPriceInIQD <= 0
                              ? roundToTwo(selectedProduct.scSellPrice / ratio)
                              : roundToTwo(modifiedPriceInIQD / ratio)
                            : 0
                          : modifiedPriceInUSD
                      }
                      onChange={(e) =>
                        setModifiedPriceInUSD(parseFloat(e.target.value))
                      }
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.priceInIQD")}
                      disabled={
                        !selectedProduct ||
                        (selectedProduct && selectedProduct.sellByMain)
                      }
                      value={
                        modifiedPriceInIQD <= 0 || modifiedPriceInUSD > 0
                          ? selectedProduct
                            ? !selectedProduct.sellByMain
                              ? selectedProduct.scSellPrice
                              : modifiedPriceInUSD <= 0
                              ? roundToTwo(selectedProduct.mcSellPrice * ratio)
                              : roundToTwo(modifiedPriceInUSD * ratio)
                            : 0
                          : modifiedPriceInIQD
                      }
                      onChange={(e) =>
                        setModifiedPriceInIQD(parseFloat(e.target.value))
                      }
                      className={classes.orderNumberInput}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.gifts")}
                      disabled={!selectedProduct}
                      value={gift}
                      onChange={(e) =>
                        parseFloat(e.target.value) +
                          orders
                            .filter((el) => el.productId === selectedProduct.id)
                            .map(
                              (el) =>
                                parseFloat(el.qte) + parseFloat(el.giftQte)
                            )
                            .reduce((a, b) => a + b, 0) +
                          parseFloat(orderQtt) <=
                          (selectedProduct &&
                            parseFloat(selectedProduct.qteInStock)) &&
                        e.target.value >= 0 &&
                        setGift(parseFloat(e.target.value))
                      }
                      className={classes.orderNumberInput}
                    />
                    <Button
                      variant="contained"
                      className={classes.botton}
                      disabled={parseFloat(orderQtt) <= 0 || !selectedProduct}
                      onClick={() => {
                        parseFloat(orderQtt) > 0 &&
                          setOrders((prev) => {
                            const found = prev.filter(
                              (el) => el.productId === selectedProduct.id
                            );
                            if (found.length <= 0) {
                              return [
                                ...prev,
                                {
                                  productId: selectedProduct.id,
                                  mcPurchasePrice:
                                    selectedProduct.mcPurchasePrice,

                                  productMeasure:
                                    selectedProduct.productMeasure,
                                  oneContains: selectedProduct.oneContains,
                                  oneMeasure: selectedProduct.oneMeasure,
                                  scDiscount: discount,

                                  giftQte: gift,
                                  mcLastPrice:
                                    selectedProduct.mcSellPrice - discount,
                                  scLastPrice:
                                    selectedProduct.scSellPrice -
                                    discount * ratio,
                                  payByMain: payByMain,
                                  barcode: selectedProduct.barcode,
                                  name: selectedProduct.name,
                                  qte: parseFloat(orderQtt),
                                  mcSellPrice:
                                    modifiedPriceInIQD + modifiedPriceInUSD > 0
                                      ? modifiedPriceInUSD > 0
                                        ? modifiedPriceInUSD
                                        : modifiedPriceInIQD / ratio
                                      : selectedProduct.sellByMain
                                      ? selectedProduct.mcSellPrice
                                      : roundToTwo(
                                          selectedProduct.scSellPrice / ratio
                                        ),
                                  scSellPrice:
                                    modifiedPriceInIQD + modifiedPriceInUSD > 0
                                      ? modifiedPriceInIQD > 0
                                        ? modifiedPriceInIQD
                                        : modifiedPriceInUSD * ratio
                                      : !selectedProduct.sellByMain
                                      ? selectedProduct.scSellPrice
                                      : selectedProduct.mcSellPrice * ratio,
                                  scTotalPrice:
                                    modifiedPriceInIQD + modifiedPriceInUSD > 0
                                      ? modifiedPriceInUSD > 0
                                        ? modifiedPriceInUSD *
                                          ratio *
                                          (parseFloat(orderQtt) +
                                            parseFloat(el.qte ? el.qte : 0))
                                        : modifiedPriceInIQD *
                                          (parseFloat(orderQtt) +
                                            parseFloat(el.qte ? el.qte : 0))
                                      : (selectedProduct.sellByMain
                                          ? selectedProduct.mcSellPrice * ratio
                                          : selectedProduct.scSellPrice) *
                                        (parseFloat(orderQtt) +
                                          parseFloat(el.qte ? el.qte : 0)),
                                },
                              ];
                            } else {
                              const newOrder = prev.map(
                                (el) =>
                                  el.productId === selectedProduct.id && {
                                    productId: selectedProduct.id,
                                    mcPurchasePrice:
                                      selectedProduct.mcPurchasePrice,

                                    productMeasure:
                                      selectedProduct.productMeasure,
                                    oneContains: selectedProduct.oneContains,
                                    oneMeasure: selectedProduct.oneMeasure,
                                    scDiscount: discount,

                                    giftQte: el.giftQte + gift,
                                    mcLastPrice:
                                      selectedProduct.mcSellPrice - discount,
                                    scLastPrice:
                                      selectedProduct.scSellPrice -
                                      discount * ratio,
                                    payByMain: payByMain,
                                    barcode: selectedProduct.barcode,
                                    name: selectedProduct.name,
                                    qte:
                                      orderToEdit > 0
                                        ? parseFloat(orderQtt)
                                        : parseFloat(orderQtt) +
                                          parseFloat(el.qte ? el.qte : 0),
                                    mcSellPrice: selectedProduct.sellByMain
                                      ? selectedProduct.mcSellPrice
                                      : roundToTwo(
                                          selectedProduct.scSellPrice / ratio
                                        ),
                                    scSellPrice: !selectedProduct.sellByMain
                                      ? selectedProduct.scSellPrice
                                      : selectedProduct.mcSellPrice * ratio,
                                    scTotalPrice:
                                      modifiedPriceInIQD + modifiedPriceInUSD >
                                      0
                                        ? modifiedPriceInUSD > 0
                                          ? modifiedPriceInUSD *
                                            ratio *
                                            (parseFloat(orderQtt) +
                                              parseFloat(el.qte ? el.qte : 0))
                                          : roundToTwo(modifiedPriceInIQD) *
                                            (parseFloat(orderQtt) +
                                              parseFloat(el.qte ? el.qte : 0))
                                        : (selectedProduct.sellByMain
                                            ? selectedProduct.mcSellPrice *
                                              ratio
                                            : selectedProduct.scSellPrice) *
                                          (parseFloat(orderQtt) +
                                            parseFloat(el.qte ? el.qte : 0)),
                                  }
                              );

                              return [
                                ...prev.filter(
                                  (el) => el.productId !== selectedProduct.id
                                ),
                                ...newOrder.filter((el) => el !== false),
                              ];
                            }
                          });
                        setOrderQtt(0);
                        setOrderToEdit(-1);
                        setGift(0);
                        setDelay(false);
                      }}
                    >
                      {translate("resources.root.addOrder")}
                    </Button>
                  </div>

                  <div className={classes.orderCols}>
                    <TableContainer
                      component={Paper}
                      className={classes.tableContainer}
                    >
                      <Table
                        stickyHeader
                        className={classes.table}
                        size="small"
                        aria-label="a dense table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              {translate("resources.root.barcode")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.name")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.quantity")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.gifts")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.price")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.totalPrice")}
                            </TableCell>
                            <TableCell align="right">
                              {translate("resources.root.actions")}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody className={classes.scrollable}>
                          {orders &&
                            orders.map((order) => (
                              <TableRow key={order.barcode}>
                                <TableCell component="th" scope="row">
                                  {order.barcode}
                                </TableCell>
                                <TableCell align="right">
                                  {order.name}
                                </TableCell>
                                <TableCell align="right">{order.qte}</TableCell>
                                <TableCell align="right">
                                  {order.giftQte}
                                </TableCell>
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.scSellPrice
                                  )?.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  {roundToTwo(
                                    order.scTotalPrice
                                  )?.toLocaleString()}
                                </TableCell>
                                <TableCell align="right">
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: translate(
                                          "resources.root.makeSure"
                                        ),
                                        text: translate(
                                          "resources.root.confirmPerminantDelete"
                                        ),
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          setOrders((prev) => {
                                            return prev.filter(
                                              (el) =>
                                                el.productId != order.productId
                                            );
                                          });
                                        } else {
                                          swal(
                                            translate(
                                              "resources.root.deleteCancel"
                                            )
                                          );
                                        }
                                      });
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                            ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Chip
                      label={`${translate(
                        "resources.root.totalPrice"
                      )} - ${orders
                        .map(
                          (el) =>
                            parseFloat(el.scSellPrice) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0)
                        ?.toLocaleString()} ${translate("resources.root.iqd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate("resources.root.finalPrice")} - ${(
                        orders
                          .map(
                            (el) =>
                              parseFloat(el.scSellPrice) * parseFloat(el.qte)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0) - discount
                      )?.toLocaleString()} ${translate("resources.root.iqd")}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalQuantity"
                      )} - ${orders
                        .map((el) => parseFloat(el.qte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.totalGifts"
                      )} - ${orders
                        .map((el) => parseFloat(el.giftQte))
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <Chip
                      label={`${translate(
                        "resources.root.finalQuantity"
                      )} - ${orders
                        .map(
                          (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                        )
                        .reduce((a, b) => a + b, 0)
                        ?.toLocaleString()}`}
                      className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                    />
                    <FormControlLabel
                      className={classes.inPercentage}
                      control={
                        <Switch
                          checked={discountInPercent}
                          onChange={(e) =>
                            setDiscountInPercent(e.target.checked)
                          }
                          name="checkedB"
                          color="primary"
                        />
                      }
                      label={translate("resources.root.inPercentage")}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.discountInPercentage")}
                      disabled={!discountInPercent}
                      value={
                        discountInPercent
                          ? roundToTwo(percentageDiscount)
                          : roundToTwo(
                              parseFloat(
                                roundToTwo(
                                  (discount * 100) /
                                    orders
                                      .map(
                                        (el) =>
                                          parseFloat(el.scSellPrice) *
                                          parseFloat(el.qte)
                                      )
                                      .reduce((a, b) => roundToTwo(a + b), 0)
                                )
                              )
                            )
                      }
                      variant="filled"
                      onChange={(e) => {
                        e.target.value >= 0 &&
                          e.target.value <= 100 &&
                          setPercentageDiscount(e.target.value);
                        setDiscount(
                          roundToTwo(
                            (orders
                              .map(
                                (el) =>
                                  parseFloat(el.scSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => a + b, 0) /
                              100) *
                              e.target.value
                          )
                        );
                      }}
                      className={classes.discount}
                    />
                    <MaterialTextField
                      type="number"
                      label={translate("resources.root.discount")}
                      disabled={discountInPercent}
                      value={
                        !discountInPercent
                          ? roundToTwo(discount)
                          : roundToTwo(
                              (orders
                                .map(
                                  (el) =>
                                    parseFloat(el.scSellPrice) *
                                    parseFloat(el.qte)
                                )
                                .reduce((a, b) => a + b, 0) /
                                100) *
                                percentageDiscount
                            )
                      }
                      variant="filled"
                      onChange={(e) => {
                        e.target.value >= 0 &&
                          e.target.value <=
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.scSellPrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => a + b, 0) &&
                          setDiscount(e.target.value);
                        setPercentageDiscount(
                          parseFloat(
                            (discount * 100) /
                              orders
                                .map(
                                  (el) =>
                                    parseFloat(el.scSellPrice) *
                                    parseFloat(el.qte)
                                )
                                .reduce((a, b) => roundToTwo(a + b), 0)
                          )
                        );
                      }}
                      className={classes.discount}
                    />
                    <Snackbar
                      open={open}
                      autoHideDuration={4000}
                      onClose={handleClose}
                    >
                      <Alert onClose={handleClose} severity="error">
                        {errorMessage}
                      </Alert>
                    </Snackbar>
                  </div>
                  <div className={classes.orderCols}>
                    <MaterialTextField
                      label={translate("resources.root.note")}
                      value={productNote}
                      onChange={(e) => setProductNote(e.target.value)}
                      className={classes.noteInput}
                    />
                    <MaterialTextField
                      label={translate("resources.root.driver")}
                      value={driver}
                      onChange={(e) => setDriver(e.target.value)}
                      className={classes.orderTextInput}
                    />
                  </div>
                </Paper>
              </Grid>
            </Grid>
          </div>
        </CardContent>
        <CardActions>
          <Button
            size="medium"
            color="secondary"
            disabled={delay}
            variant="contained"
            onClick={() => {
              const sc = localStorage.getItem("dollarPrice");

              setSubmitAttempted(true);
              if (!selectedCustomer) {
                setErrorMessage(
                  translate("resources.root.selectRepresentative")
                );
                setOpen(true);
                return;
              }
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                setDelay(true);
                if (willDelete) {
                  //TODO conditional rendering
                  HTTP.post(
                    `${url}/${
                      invoice === "return"
                        ? "RepresentativeReturnInvoices"
                        : "RepresentativeInvoices"
                    }`,
                    {
                      invoiceNumber,
                      representativeId: selectedCustomer.id,
                      date: moment(selectedDate).add(3, "hours"),
                      scTotalPrice: orders
                        .map(
                          (el) =>
                            parseFloat(el.scSellPrice) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => a + b, 0),

                      scFinalPrice:
                        orders
                          .map(
                            (el) =>
                              parseFloat(el.scSellPrice) * parseFloat(el.qte)
                          )
                          .reduce((a, b) => a + b, 0) - discount,

                      isCash: cashPayment,
                      payByMain: false,
                      totalQte: orders
                        .map((el) => el.qte)
                        .reduce((a, b) => a + b, 0),
                      totalGiftQte: orders
                        .map((el) => el.giftQte)
                        .reduce((a, b) => a + b, 0),
                      scInvoiceDiscount: (
                        cashPayment
                          ? payByMain
                          : selectedCustomer && selectedCustomer.payLoanByMain
                      )
                        ? discount
                        : roundToTwo(discount / sc),

                      scOrdersDiscount: orders
                        .map((el) => el.scDiscount)
                        .reduce((a, b) => a + b, 0),

                      scFinalDiscount:
                        orders
                          .map((el) => el.scDiscount)
                          .reduce((a, b) => a + b, 0) + discount,

                      note: productNote,
                      scLoanBefore: selectedCustomer.scCurrentLoan,

                      scLoanAfter: (
                        cashPayment
                          ? cashPayment
                          : selectedCustomer.payLoanByMain
                      )
                        ? selectedCustomer.scCurrentLoan
                        : selectedCustomer.scCurrentLoan + modifiedPriceInUSD,

                      driver,
                      representativeOrders: orders,
                      representativeReturnOrders: orders,
                    }
                  )
                    .then((res) => {
                      if (invoice === "return") {
                        localStorage.removeItem(
                          "scRepresentativeReturnInvoiceOrders"
                        );
                      } else {
                        localStorage.removeItem(
                          "scRepresentativeInvoiceOrders"
                        );
                      }
                      redirect("/RepresentativeInvoices");
                    })
                    .catch((err) =>
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      })
                    );
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.create")}
          </Button>
          <Button
            onClick={() => {
              swal({
                title: translate("resources.root.makeSure"),
                text: translate("resources.root.confirmCreateNewInvoice"),
                icon: "warning",
                buttons: true,
                dangerMode: true,
              }).then((willDelete) => {
                if (willDelete) {
                  setOrders([]);
                } else {
                  swal(translate("resources.root.createCancel"));
                }
              });
            }}
          >
            {translate("resources.root.clearOrders")}
          </Button>
        </CardActions>
      </Card>
    </StylesProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
    marginTop: 72,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "250px !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {
    minWidth: 650,
  },
  tableContainer: {
    marginTop: 20,
    maxHeight: "350px !important",
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "140%",
      marginLeft: theme.direction === "rtl" ? 0 : -230,
      marginRight: theme.direction === "rtl" ? -230 : -230,
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "80%",
      marginLeft: 265,
      paddingTop: 60,
      //   marginRight: 230,
    },
  },
}));
