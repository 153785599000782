import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  Show,
  Filter,
  useNotify,
  DateField,
  useRedirect,
  SimpleShowLayout,
  NumberField,
  FunctionField,
  useTranslate,
  BooleanField,
  BooleanInput,
  NumberInput,
  ShowButton,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async";
import { useLocation, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { ListActions } from "../templates/ListActions";
import { permissions } from "../Permissions/AllPermission";
import loadOptions from "../../loadOptions";

export const BankAccountsList = (props) => {
  return (
    <List
      actions={<ListActions createNoPermission={"true"} />}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <Datagrid bulkActionButtons={<BulkAction {...props} />}>
        <TextField source="id" label="resources.root.id" />
        <TextField source="name" label="resources.root.name" />
        <NumberField
          source="mcCurrentBalance"
          options={{ style: "currency", currency: "USD" }}
          label="resources.root.mcCurrentBalance"
        />
        <NumberField
          source="scCurrentBalance"
          options={{ style: "currency", currency: "IQD" }}
          label="resources.root.scCurrentBalance"
        />
        <TextField source="detail" label="resources.root.detail" />
        <TextField
          source="accountNumber"
          label="resources.root.accountNumber"
        />
        <BooleanField
          source="allowBellowZero"
          label="resources.root.allowBelowZero"
        />
        <DateField
          source="openingDate"
          label="resources.root.openingDate"
          locales={"en-GB"}
        />

        {hasPermission([permissions?.bankAccountView]) && <ShowButton />}
        {hasPermission([permissions?.bankAccountUpdate]) && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const BankAccountsShow = (props) => {
  const translate = useTranslate();
  const redirect = useRedirect();

  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [selectedUser, setSelectedUser] = React.useState(0);
  const [allowedUsers, setAllowedUsers] = React.useState([]);
  const notify = useNotify();
  const { id } = useParams();

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  const fetchBankAccountUsers = () => {
    HTTP.get(`${url}/BankAccounts/${id}/users?_start=${start}&_end=${end}`)
      .then((response) => {
        setAllowedUsers(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        handleRequestResponse(notify, error);
      });
  };
  React.useEffect(() => {
    fetchBankAccountUsers();
  }, [start, end]);

  const swalWithBootstrapButtons = Swal.mixin({
    customClass: {
      confirmButton: "btn btn-success",
      cancelButton: "btn btn-danger",
    },
    buttonsStyling: false,
  });
  const handlePostPermission = async () => {
    HTTP.post(`/BankAccounts/${id}/users?userId=${selectedUser.id}`)
      .then(() => {
        swalWithBootstrapButtons.fire({
          title: `${translate("resources.root.swalSuccess")}`,
          icon: "success",
        });
      })
      .catch((error) => {
        handleRequestResponse(notify, error);
      })
      .then(() => {
        fetchBankAccountUsers();
      });
  };
  const handleRemoveOrder = (userId) => {
    HTTP.delete(`/BankAccounts/${id}/users/${userId}`)
      .then(() => {
        swalWithBootstrapButtons.fire({
          title: translate("resources.root.swalDeleted"),
          icon: "success",
        });
      })
      .then(() => {
        fetchBankAccountUsers();
      })

      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        handleRequestResponse(notify, error);
      });
  };
  return (
    <Show
      filters={<SearchFilter />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
    >
      <TabbedShowLayout>
        <Tab label="resources.root.info">
          <SimpleShowLayout>
            <TextField source="name" label="resources.root.name" />
            <FunctionField
              label="resources.root.BalanceInUSD"
              render={(record) =>
                `${record.mcCurrentBalance?.toLocaleString()}`
              }
            />
            <FunctionField
              label="resources.root.balanceInIQD"
              render={(record) =>
                `${record.scCurrentBalance?.toLocaleString()}`
              }
            />
            <FunctionField
              label="resources.root.depositInUSD"
              render={(record) => `${record.totalMcDeposit?.toLocaleString()}`}
            />
            <FunctionField
              label="resources.root.depositInIQD"
              render={(record) => `${record.totalScDeposit?.toLocaleString()}`}
            />
            <FunctionField
              label="resources.root.withdrawInUSD"
              render={(record) => `${record.totalMcWithdraw?.toLocaleString()}`}
            />
            <FunctionField
              label="resources.root.withdrawInIQD"
              render={(record) => `${record.totalScWithdraw?.toLocaleString()}`}
            />
            <FunctionField
              label="resources.root.withdrawInIQD"
              render={(record) => `${record.totalScWithdraw?.toLocaleString()}`}
            />
            <BooleanField
              source="allowBellowZero"
              label="resources.root.allowBelowZero"
            />
            <TextField source="detail" label="resources.root.details" />
            <TextField
              source="accountNumber"
              label="resources.root.accountNumber"
            />
            <FunctionField
              label="resources.root.totalMcTransferFromOther"
              render={(record) =>
                `${record.totalMcTransferFromOther?.toLocaleString()}`
              }
            />
            <FunctionField
              label="resources.root.totalScTransferFromOther"
              render={(record) =>
                `${record.totalScTransferFromOther?.toLocaleString()}`
              }
            />
            <FunctionField
              label="resources.root.totalMcTransferToOther"
              render={(record) =>
                `${record.totalMcTransferToOther?.toLocaleString()}`
              }
            />
            <FunctionField
              label="resources.root.totalScTransferToOther"
              render={(record) =>
                `${record.totalScTransferToOther?.toLocaleString()}`
              }
            />
            <DateField
              source="openingDate"
              label="resources.root.openingDate"
              locales={"en-GB"}
            />
            <EditButton />
          </SimpleShowLayout>
        </Tab>
        {console.log(window.role.toLowerCase())}
        {window.role.toLowerCase() === "superadmin" && (
          <Tab label="resources.root.permissions">
            <Row
              style={{ fontSize: "15px" }}
              className="justify-content-between align-items-center w-100 text-center"
            >
              <Col className="col-8 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.user")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="flex-grow-1 w-100"
                  loadOptions={loadOptions.users}
                  onChange={(e) => {
                    setSelectedUser(e.user ?? 0);
                  }}
                />
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  onClick={() => {
                    handlePostPermission();
                  }}
                >
                  {translate(`resources.root.add`)}
                </Button>
              </Col>
            </Row>
            <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
              <Col>
                <Table id={`exportableTable`} bordered hover>
                  <thead>
                    <tr>
                      <th>{translate("resources.root.id")}</th>
                      <th>{translate("resources.root.name")}</th>
                      <th>{translate("resources.root.phone")}</th>
                      <th>{translate("resources.root.role")}</th>
                      <th>{translate("resources.root.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allowedUsers.map((user) => {
                      return (
                        <tr
                          key={user.id}
                          style={{ backgroundColor: "#dcf7f7" }}
                        >
                          <td> {user.id}</td>
                          <td>{`${user.firstName} ${user.middleName} ${user.lastName}`}</td>
                          <td>{user.phoneNumber}</td>
                          <td>{user.userType}</td>
                          <td
                            onClick={() => handleRemoveOrder(user.id)}
                            className="d-flex align-items-center justify-content-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              className="bi bi-trash3 btn btn-danger p-1 "
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>
                      <option value={1000}>1000</option>
                      <option value={2000}>2000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    shape="rounded"
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </Col>
            </Row>
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

export const BankAccountsCreate = (props) => {
  const notify = useNotify();
  const redirect = useRedirect();
  const onFailure = (error) => {
    if (error.status === 406) {
      notify(`resources.root.bankAccountExistsAlready`, {
        type: "warning",
        undoable: true,
      });
    } else if (error.status === 401) {
      localStorage.removeItem("token");
      redirect("/login");
    }
  };
  return (
    <Create {...props} onFailure={onFailure}>
      <SimpleForm>
        <h1>You don't have permission to do this</h1>
        {/*  <TextInput source="name" label="resources.root.name"/> 
        <TextInput source="detail" label="resources.root.detail" options={{ multiLine: true }} />
        <NumberInput source="mcInitialBalance" label="resources.root.initialBalanceDollar"/>
        <TextInput source="accountNumber" label="resources.root.accountNumber"/>
        <NumberInput source="scInitialBalance" label="resources.root.initialBalanceDinar"/>
        <BooleanInput source="allowBellowZero" label="resources.root.allowBelowZero" /> */}
      </SimpleForm>
    </Create>
  );
};

export const BankAccountsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" label="resources.root.name" />
      <TextInput
        source="detail"
        label="resources.root.detail"
        options={{ multiLine: true }}
      />
      <BooleanInput
        source="allowBellowZero"
        label="resources.root.allowBelowZero"
      />
    </SimpleForm>
  </Edit>
);
