import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  FunctionField,
  Show,
  ShowButton,
  TabbedShowLayout,
  Tab,
  Create,
  BooleanField,
  FormTab,
  TabbedForm,
  useTranslate,
  useNotify,
  DateField,
  useRefresh,
  useResourceDefinition,
  DateInput,
  ReferenceField,
} from "react-admin";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import { url, handleRequestResponse } from "../../request";
import moment from "moment";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { ScPurchaseInvoiceNew } from "./ScPurchaseInvoiceNew";
import { McPurchaseInvoiceNew } from "./McPurchaseInvoiceNew";
import MuiAlert from "@mui/lab/Alert";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { Form } from "react-bootstrap";
import MaterialTextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import swal from "sweetalert";
import Swal from "sweetalert2";
import PrintIcon from "@mui/icons-material/Print";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import DeleteIcon from "@mui/icons-material/Delete";
import Snackbar from "@mui/material/Snackbar";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import Page from "../CustomPages/SellReceipt/Page";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { useParams } from "react-router-dom";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import { permissions } from "../Permissions/AllPermission";
import CreatePurchaseInvoice from "../invoices/CreatePurchaseInvoice";
import loadOptions from "../../loadOptions";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();
  const notify = useNotify();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        {props.selectedIds.length === 1 && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                title: "Multiple inputs",
                html:
                  '<label className="text-dark">Pay Price:</label> <input value="0" id="swal-input1" type="number" className="swal2-input">' +
                  ' <label className="text-dark">Forgive:</label> <input  value="0" id="swal-input2" type="number" className="swal2-input">',
                showCancelButton: true,
                focusConfirm: false,
                inputValue: 0,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.submit"),
                cancelButtonText: translate("ra.action.cancel"),
                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/Pay?invoiceId=${
                      props.selectedIds[0]
                    }&payPrice=${
                      document.getElementById("swal-input1").value
                    }&forgivePrice=${
                      document.getElementById("swal-input2").value
                    }`
                  )
                    .then(() => {
                      notify(translate("resources.root.PaidSuccessfully"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.pay")}
          </Button>
        )}
        {((props.selectedIds.length === 1 &&
          process.env.REACT_APP_APP_NAME === "Bahez") ||
          process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                icon: "warning",
                title: translate("resources.root.swalTitle"),
                text: translate("resources.root.payTaxText"),
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("ra.action.cancel"),

                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/PayTaxPayment/${
                      props.selectedIds[0]
                    }?toggle=${true}`
                  )
                    .then(() => {
                      notify(translate("resources.root.paidSuccessfully"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.payTax")}
          </Button>
        )}
        {((props.selectedIds.length === 1 &&
          process.env.REACT_APP_APP_NAME === "Bahez") ||
          process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
          <Button
            color="primary"
            style={{ marginBottom: 15 }}
            onClick={() => {
              const { value: formValues } = Swal.fire({
                icon: "warning",
                title: translate("resources.root.swalTitle"),
                text: translate("resources.root.payTaxText"),
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonColor: "#0c813b",
                cancelButtonColor: "#992412",
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("ra.action.cancel"),

                preConfirm: () => {
                  HTTP.get(
                    `${url}/PurchaseInvoices/UnPayTaxPayment/${
                      props.selectedIds[0]
                    }?toggle=${true}`
                  )
                    .then(() => {
                      notify(translate("resources.root.cancledTax"), {
                        type: "success",
                      });
                      swal.stopLoading();
                      swal.close();
                      refresh();
                    })
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                        Swal.fire({
                          title: "Error",
                          html: err.response.data.message,
                        });
                      } else {
                        swal.stopLoading();
                        swal.close();
                      }
                    });
                },
              });
            }}
          >
            <LocalAtmIcon /> &nbsp; {translate("resources.root.cancleTax")}
          </Button>
        )}

        <BulkAction
          {...props}
          bulkDeleteButton={true}
          hasPrintOrders={"PurchaseInvoice"}
          hasDelete="purchaseinvoice.delete"
        />
      </div>
    </React.Fragment>
  );
};

export const PurchasesList = (props) => {
  const [currency, setCurrency] = React.useState("default");

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print force>
          <List
            debounce={1500}
            filters={<SearchFilter driver={true} date={true} />}
            pagination={<MyCustomPagination />}
            {...props}
            sort={{ field: "id", order: "DESC" }}
            actions={
              <ListActions
                setCurrency={setCurrency}
                currency={currency}
                hasCreate="purchaseinvoice.create"
              />
            }
          >
            <Datagrid
              bulkActionButtons={
                // <BulkAction
                //   {...props}
                //   bulkDeleteButton={true}
                //   hasPrintOrders={"PurchaseInvoice"}
                // />
                <BulkActionButtons />
              }
            >
              <TextField source="id" label={translate("resources.root.id")} />
              <ReferenceField
                label="resources.root.supplier"
                source="supplierId"
                reference="Suppliers"
                link={(record) =>
                  `/Reports/SupplierStatement/?id=${record.supplier.id}`
                }
              >
                <TextField source="supplierName" />
              </ReferenceField>
              {localStorage.getItem("showInvoiceNumber") === "true" && (
                <TextField
                  source="invoiceNumber"
                  label="resources.root.invoiceNumber"
                />
              )}
              {/* <FunctionField
                                render={(record) => record.supplier.owner}
                                label="resources.root.owner"
                            /> */}
              {currency === "default" && (
                <FunctionField
                  label="resources.root.price"
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    record.payByMain
                      ? `$${(
                          record.mcFinalPrice -
                          (record.mcTax + record.mcTransport + record.mcOther)
                        )?.toLocaleString()}`
                      : `${(
                          record.scFinalPrice -
                          (record.scTax + record.scTransport + record.scOther)
                        )?.toLocaleString()} ${translate("resources.root.iqd")}`
                  }
                />
              )}
              {currency !== "default" && (
                <FunctionField
                  label={translate("resources.root.price")}
                  sortBy={"mcFinalPrice"}
                  render={(record) =>
                    currency === "dollar"
                      ? `$${(
                          record.mcFinalPrice -
                          (record.mcTax + record.mcTransport + record.mcOther)
                        )?.toLocaleString()}`
                      : `${(
                          record.scFinalPrice -
                          (record.scTax + record.scTransport + record.scOther)
                        )?.toLocaleString()} ${translate("resources.root.iqd")}`
                  }
                />
              )}
              {/* paid price of the incovie */}
              {process.env.REACT_APP_APP_NAME === "Mediply" &&
                currency === "default" && (
                  <FunctionField
                    label="resources.root.paid"
                    sortBy={"mcTotalPaidPrice"}
                    render={(record) =>
                      record.payByMain
                        ? `$${record.mcTotalPaidPrice?.toLocaleString()}`
                        : `${record.scTotalPaidPrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                    }
                  />
                )}
              {process.env.REACT_APP_APP_NAME === "Mediply" &&
                currency !== "default" && (
                  <FunctionField
                    label="resources.root.paid"
                    sortBy={"mcTotalPaidPrice"}
                    render={(record) =>
                      currency === "dollar"
                        ? `$${record.mcTotalPaidPrice?.toLocaleString()}`
                        : `${record.scTotalPaidPrice?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                    }
                  />
                )}
              {currency !== "default" &&
                (process.env.REACT_APP_APP_NAME === "Bahez" ||
                  process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
                  <FunctionField
                    label="resources.root.tax"
                    sortBy={"mcTax"}
                    render={(record) =>
                      currency === "dollar"
                        ? `$${(
                            record.mcTax +
                            record.mcOther +
                            record.mcTransport
                          )?.toLocaleString()}`
                        : `${(
                            record.scTax +
                            record.scOther +
                            record.scTransport
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                    }
                  />
                )}
              {currency === "default" &&
                (process.env.REACT_APP_APP_NAME === "Bahez" ||
                  process.env.REACT_APP_APP_NAME === "IndustryTemp") && (
                  <FunctionField
                    label="resources.root.tax"
                    sortBy={"mcTax"}
                    render={(record) =>
                      record.payByMain
                        ? `$${(
                            record.mcTax +
                            record.mcOther +
                            record.mcTransport
                          )?.toLocaleString()}`
                        : `${(
                            record.scTax +
                            record.scOther +
                            record.scTransport
                          )?.toLocaleString()} ${translate(
                            "resources.root.iqd"
                          )}`
                    }
                  />
                )}
              <BooleanField
                source="isTaxPaid"
                label={translate("resources.root.tax")}
              />
              {/* end of the price  */}
              <FunctionField
                label={translate("resources.root.quantity")}
                render={(record) =>
                  (record.totalQte - record.totalGiftQte)?.toLocaleString()
                }
              />
              <TextField
                source="totalGiftQte"
                label="resources.root.giftQuantity"
              />
              <TextField
                source="totalQte"
                label="resources.root.totalQuantity"
              />
              <FunctionField
                render={(record) =>
                  moment(record.date).format("DD/MM/YYYY hh:mm:ss A")
                }
                sortBy={"date"}
                label="resources.root.date"
              />
              <BooleanField
                source="isCash"
                label={translate("resources.root.cash")}
              />
              {localStorage.getItem("showNote") === "true" && (
                <TextField source="note" label="resources.root.note" />
              )}
              {localStorage.getItem("showDriver") === "true" && (
                <TextField source="driver" label="resources.root.driver" />
              )}
              {localStorage.getItem("showLastUser") === "true" && (
                <TextField source="lastUser" label="resources.root.lastUser" />
              )}
              {localStorage.getItem("showCreatedAt") === "true" && (
                <FunctionField
                  sortBy={"createdAt"}
                  label="resources.root.createdAt"
                  render={(record) =>
                    record.createdAt
                      ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                      : "-"
                  }
                />
              )}{" "}
              {hasPermission([permissions?.purchaseInvoiceView]) && (
                <ShowButton />
              )}
              {hasPermission([permissions?.purchaseInvoiceUpdate]) && (
                <EditButton />
              )}
            </Datagrid>
          </List>
        </Print>
      </PrintProvider>
    </div>
  );
};

export const PurchasesCreate = (props) => (
  <Create {...props} component="div">
    {process.env.REACT_APP_SINGLE_CURRENCY === "true" ? (
      <TabbedForm>
        <FormTab label="resources.root.purchase">
          <CreatePurchaseInvoice />
        </FormTab>
        {process.env.REACT_APP_SHOW_MC === "true" ? (
          <FormTab label="resources.root.dollarPurchase">
            <McPurchaseInvoiceNew />
          </FormTab>
        ) : (
          <FormTab label="resources.root.dinnarPurchase">
            <ScPurchaseInvoiceNew />
          </FormTab>
        )}
      </TabbedForm>
    ) : localStorage.getItem("sellMainCurrency") === "true" ? (
      <TabbedForm>
        <FormTab label="resources.root.purchase">
          <CreatePurchaseInvoice />
        </FormTab>
        <FormTab label="resources.root.dollarPurchase">
          <McPurchaseInvoiceNew />
        </FormTab>
        <FormTab label="resources.root.dinnarPurchase">
          <ScPurchaseInvoiceNew />
        </FormTab>
      </TabbedForm>
    ) : (
      <TabbedForm>
        <FormTab label="resources.root.purchase">
          <CreatePurchaseInvoice />
        </FormTab>
        <FormTab label="resources.root.dollarPurchase">
          <McPurchaseInvoiceNew />
        </FormTab>
        <FormTab label="resources.root.dinnarPurchase">
          <ScPurchaseInvoiceNew />
        </FormTab>
      </TabbedForm>
    )}
  </Create>
);

export const PurchasesShow = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const classes = useStyles();
  const [orders, setOrders] = React.useState([]);
  const [invoice, setInvoice] = React.useState([]);
  React.useEffect(() => {
    HTTP.get(`${url}/PurchaseInvoices/${id}`).then((res) => {
      setOrders(res.data.purchaseOrders);
      setInvoice(res.data);
    });
  }, [props]);

  const translate = useTranslate();

  return (
    <div>
      <PrintProvider>
        <Print single={true} name="foo">
          <div className={classes.printWrapper}>
            <Page
              invoice={invoice}
              title={translate("resources.root.purchaseInvoice")}
              order={invoice.purchaseOrders}
              human={invoice.supplier}
            />
          </div>
        </Print>
        <NoPrint force>
          <Show>
            <TabbedShowLayout>
              <Tab label={translate("resources.root.invoice")}>
                <TextField
                  source="invoiceNumber"
                  label={translate("resources.root.invoiceNumber")}
                />
                <TextField
                  source="supplier.supplierName"
                  label={translate("resources.root.supplierName")}
                />
                <TextField
                  source="supplier.owner"
                  label={translate("resources.root.owner")}
                />
                <FunctionField
                  label={translate("resources.root.quantity")}
                  render={(record) =>
                    `${record.totalQte - record.totalGiftQte}`
                  }
                />
                <TextField
                  source="totalGiftQte"
                  label={translate("resources.root.giftQuantity")}
                />
                <TextField
                  source="totalQte"
                  label={translate("resources.root.finalQuantity")}
                />
                <DateField
                  source="date"
                  label="resources.root.date"
                  locales={"en-GB"}
                  showTime={true}
                />
                <TextField
                  source="note"
                  label={translate("resources.root.note")}
                />
                <TextField
                  source="driver"
                  label={translate("resources.root.driver")}
                />

                <FunctionField
                  label="resources.root.basePrice"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${(
                          record.mcTotalPrice -
                          (record.mcTax + record.mcOther + record.mcTransport)
                        )?.toLocaleString()}`
                      : `${(
                          record.scTotalPrice -
                          (record.scTax + record.scOther + record.scTransport)
                        )?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.totalPrice"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcTotalPrice?.toLocaleString()}`
                      : `${record.scTotalPrice?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.finalPrice"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcFinalPrice?.toLocaleString()}`
                      : `${record.scFinalPrice?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.finalDiscount"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcFinalDiscount?.toLocaleString()}`
                      : `${record.scFinalDiscount?.toLocaleString()} IQD`
                  }
                />

                <FunctionField
                  label="resources.root.tax"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcTax?.toLocaleString()}`
                      : `${record.scTax?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.other"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcOther?.toLocaleString()}`
                      : `${record.scOther?.toLocaleString()} IQD`
                  }
                />
                <FunctionField
                  label="resources.root.transport"
                  render={(record) =>
                    invoice.payByMain
                      ? `$${record.mcTransport?.toLocaleString()}`
                      : `${record.scTransport?.toLocaleString()} IQD`
                  }
                />
              </Tab>
              <Tab label={translate("resources.root.orders")}>
                <TableContainer
                  component={Paper}
                  className={classes.tableContainer}
                >
                  <Table
                    stickyHeader
                    className={classes.table}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.barcode")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.name")}
                        </TableCell>
                        {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.serial")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.quantity")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.gifts")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.productMeasure")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.basePrice")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.smallMeasurePrice")}
                        </TableCell>
                        {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                          <TableCell align="right">
                            {translate("resources.root.discount")}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          {translate("resources.root.totalPrice")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.scrollable}>
                      {orders &&
                        orders.map((order) => (
                          <TableRow key={order.product.barcode}>
                            <TableCell component="th" scope="row">
                              {order.product.barcode}
                            </TableCell>
                            <TableCell align="right">
                              <a href={`#/Products/${order.product.id}`}>
                                {order.product.name}
                              </a>
                            </TableCell>
                            {process.env.REACT_APP_PRODUCT_SERIAL ===
                              "true" && (
                              <TableCell align="right">
                                {order.productSerial?.serialNumber}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {order.qte + " + " + order.smallMeasureQte}
                            </TableCell>
                            <TableCell align="right">
                              {order.giftQte +
                                " + " +
                                order.smallMeasureGiftQte}
                            </TableCell>
                            <TableCell align="right">{`${order.productMeasure} => ${order.oneContains} ${order.oneMeasure}`}</TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcBasePurchasePrice?.toLocaleString()
                                : order.scBasePurchasePrice?.toLocaleString()}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcPurchasePrice?.toLocaleString()
                                : order.scPurchasePrice?.toLocaleString()}
                            </TableCell>
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcSmallMeasurePurchasePrice?.toLocaleString()
                                : order.scSmallMeasurePurchasePrice?.toLocaleString()}
                            </TableCell>
                            {process.env.REACT_APP_ORDER_DISCOUNT ===
                              "true" && (
                              <TableCell align="right">
                                {order.payByMain
                                  ? order.mcDiscount?.toLocaleString()
                                  : order.scDiscount?.toLocaleString()}
                              </TableCell>
                            )}
                            <TableCell align="right">
                              {order.payByMain
                                ? order.mcLastPrice?.toLocaleString()
                                : order.scLastPrice?.toLocaleString()}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Tab>
            </TabbedShowLayout>
          </Show>
          <div className="d-flex flex-wrap justify-content-start align-items-center gap-2 my-2">
            <Button
              variant="contained"
              className={classes.botton}
              onClick={() => {
                let sidebar = document.getElementsByClassName("MuiDrawer-root");
                sidebar[0].style.display = "none";
                window.print();
                sidebar[0].style.display = "block";
              }}
            >
              {translate("resources.root.print")}
            </Button>
            <Button
              variant="contained"
              className={`${classes.botton} ${classes.bottomLeftMargin}`}
              onClick={() => {
                let ords = orders.map((el) => ({
                  productId: el.productId,
                  giftQte: el.giftQte,
                  scLastPrice: el.scLastPrice,
                  mcLastPrice: el.mcLastPrice,
                  payByMain: el.payByMain,
                  barcode: el.product.barcode,
                  name: el.product.name,
                  productSerial: el.productSerial,
                  qte: el.qte,
                  scPurchasePrice: el.scPurchasePrice,
                  mcPurchasePrice: el.mcPurchasePrice,
                  scBasePurchasePrice: el.scBasePurchasePrice,
                  mcBasePurchasePrice: el.mcBasePurchasePrice,
                  scInitPrice: el.scPurchasePrice,
                  mcInitPrice: el.mcPurchasePrice,
                  scDiscount: el.scDiscount,
                  mcDiscount: el.mcDiscount,
                  scSellPrice: el.scSellPrice,
                  mcSellPrice: el.mcSellPrice,
                  scTotalPrice: el.scLastPrice,
                  mcTotalPrice: el.mcLastPrice,
                  note: el.note,
                }));
                if (
                  localStorage.getItem("purchaseOrders") &&
                  JSON.parse(localStorage.getItem("purchaseOrders")).length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "purchaseOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/PurchaseInvoices/create");
                    }
                  });
                } else {
                  localStorage.setItem("purchaseOrders", JSON.stringify(ords));
                  window.open("#/PurchaseInvoices/create");
                }
              }}
            >
              {translate("resources.root.copyPurchase")}
            </Button>
            <Button
              variant="contained"
              className={`${classes.botton} ${classes.bottomLeftMargin}`}
              onClick={() => {
                let ords = orders.map((el) => ({
                  productId: el.productId,
                  giftQte: el.giftQte,
                  scLastPrice: el.scLastPrice,
                  mcLastPrice: el.mcLastPrice,
                  payByMain: el.payByMain,
                  barcode: el.product.barcode,
                  name: el.product.name,
                  productSerial: el.productSerial,
                  qte: el.qte,
                  scPurchasePrice: el.scPurchasePrice,
                  mcPurchasePrice: el.mcPurchasePrice,

                  scDiscount: el.scDiscount,
                  mcDiscount: el.mcDiscount,
                  scSellPrice: el.scSellPrice,
                  mcSellPrice: el.mcSellPrice,
                  scTotalPrice: el.scSellPrice * el.qte,
                  mcTotalPrice: el.mcSellPrice * el.qte,
                  note: el.note,
                }));
                if (
                  localStorage.getItem("InvoiceOrders") &&
                  JSON.parse(localStorage.getItem("InvoiceOrders")).length > 0
                ) {
                  Swal.fire({
                    title: translate(
                      "resources.root.ConfirmDeleteCurrentInvoice"
                    ),
                    showDenyButton: true,
                    confirmButtonColor: "rgba(30, 111, 195, 1)",
                    confirmButtonText: translate("resources.root.copyAnyway"),
                    denyButtonText: translate("resources.root.dontCopy"),
                  }).then((result) => {
                    if (result.isConfirmed) {
                      Swal.fire(
                        translate("resources.root.invoiceHasBeenCopied"),
                        "",
                        "success"
                      );
                      localStorage.setItem(
                        "InvoiceOrders",
                        JSON.stringify(ords)
                      );
                      window.open("#/SellInvoices/create");
                    }
                  });
                } else {
                  localStorage.setItem("InvoiceOrders", JSON.stringify(ords));
                  window.open("#/SellInvoices/create");
                }
              }}
            >
              {translate("resources.root.copySell")}
            </Button>
          </div>
        </NoPrint>
      </PrintProvider>
    </div>
  );
};

export const PurchasesEdit = () => {
  const { id } = useParams();
  const props = useResourceDefinition();
  const notify = useNotify();
  const classes = useStyles();
  const [purchaseInvoices, setPurchaseInvoices] = React.useState([]);
  const [purchaseOrders, setPurchaseOrders] = React.useState([]);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [payByMain, setPayByMain] = React.useState(true);
  const [address, setAddress] = React.useState(null);
  const [orderQtt, setOrderQtt] = React.useState(0);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [orders, setOrders] = React.useState(
    localStorage.getItem("orders")
      ? JSON.parse(localStorage.getItem("orders"))
      : []
  );
  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [gift, setGift] = React.useState(0);
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [previousPurchasePrice, setPreviousPurchasePrice] =
    React.useState(null);
  const [modifiedPriceInUSD, setModifiedPriceInUSD] = React.useState(0);
  const [modifiedPriceInIQD, setModifiedPriceInIQD] = React.useState(0);
  const [productSerial, setProductSerial] = React.useState(null);
  const [productSerialExpire, setProductSerialExpire] = React.useState(
    moment(new Date()).add(6, "M")
  );

  const translate = useTranslate();

  React.useEffect(() => {
    if (id !== 0) {
      HTTP.get(`${url}/PurchaseInvoices/${id}`).then((res) => {
        setPayByMain(res.data.payByMain);
        setPurchaseInvoices(res.data);
        setPurchaseOrders(res.data.purchaseOrders);
        setAddress(res.data.address);
      });
    }
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);

  React.useEffect(() => {
    selectedProduct &&
      purchaseInvoices &&
      HTTP.get(
        `${url}/PurchaseInvoices/GetLastPriceOfProduct?customerId=${purchaseInvoices.customerId}&productId=${selectedProduct.id}`
      ).then((res) => {
        setPreviousPurchasePrice(res.data);
      });
  }, [purchaseInvoices, selectedProduct]);

  React.useEffect(() => {
    selectedProduct && (selectedProduct.mcPurchasePrice = modifiedPrice);
  }, [modifiedPrice]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  function roundToTwo(num) {
    return +(Math.round(num + "e+3") + "e-3");
  }

  const ratio = localStorage.getItem("dollarPrice");

  return (
    <Card className={classes.root}>
      <CardContent>
        <div className={classes.invoice}>
          <div className={classes.invoiceCols}>
            <Form.Group className={`m-4`}>
              <Form.Control
                type="date"
                size={"lg"}
                onChange={(e) => setSelectedDate(e.target.value)}
              />
            </Form.Group>
            <MaterialTextField
              label={translate("resources.root.address")}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              className={classes.addressInput}
            />
          </div>

          <div className={classes.invoiceCols}>
            <Chip
              icon={<AssignmentIndIcon />}
              label={
                purchaseInvoices.customer
                  ? `${purchaseInvoices.customer.firstName} ${purchaseInvoices.customer.middleName}`
                  : translate("resources.root.name")
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              icon={<PhoneIphoneIcon />}
              label={
                purchaseInvoices.customer
                  ? purchaseInvoices.customer.phone
                  : translate("resources.root.phone")
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              label={
                purchaseInvoices.customer
                  ? `${translate("resources.root.currentLoan")} ${
                      purchaseInvoices.customer.mcCurrentLoan
                    } ${
                      purchaseInvoices.customer.payLoanByMain
                        ? translate("resources.root.usd")
                        : " " + translate("resources.root.iqd")
                    }`
                  : `${translate("resources.root.currentLoan")}`
              }
              className={classes.bottomLeftMargin}
            />
            <Chip
              // icon={<LocalAtmIcon />}
              label={
                purchaseInvoices.customer
                  ? `${translate("resources.root.maxLoan")} ${
                      purchaseInvoices.customer.mcRoofLoan
                    } ${
                      purchaseInvoices.customer.payLoanByMain
                        ? translate("resources.root.usd")
                        : " " + translate("resources.root.iqd")
                    }`
                  : `${translate("resources.root.maxLoan")}`
              }
              className={classes.bottomLeftMargin}
            />
          </div>
        </div>
        <div className={classes.invoice}>
          <div className={classes.toggles}>
            {purchaseInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch
                    checked={purchaseInvoices.payByMain}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={
                  purchaseInvoices.payByMain
                    ? translate("resources.root.dollar")
                    : translate("resources.root.dinar")
                }
              />
            )}
            {purchaseInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch checked={true} name="checkedB" color="primary" />
                }
                label={translate("resources.root.isCash")}
              />
            )}
            {!purchaseInvoices.isCash && (
              <FormControlLabel
                control={
                  <Switch checked={false} name="checkedB" color="primary" />
                }
                label={translate("resources.root.isCash")}
              />
            )}
          </div>
        </div>
        <div className={classes.orders}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {/* Chips above the product  */}
                <div className={classes.orderCols}>
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.productMeasure")} - ${
                            selectedProduct.productMeasure
                          }`
                        : translate("resources.root.productMeasure")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.oneMeasure")} - ${
                            selectedProduct.oneMeasure
                          }`
                        : translate("resources.root.oneMeasure")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.oneContains")} ${
                            selectedProduct.oneContains
                          } ${translate("resources.root.items")}`
                        : translate("resources.root.oneContains")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  {selectedProduct && payByMain ? (
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.totalPrice")} - $${
                              modifiedPriceInUSD > 0
                                ? roundToTwo(modifiedPriceInUSD * orderQtt)
                                : roundToTwo(
                                    selectedProduct.mcPurchasePrice * orderQtt
                                  )
                            }`
                          : translate("resources.root.totalPrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                  ) : (
                    <Chip
                      label={
                        selectedProduct
                          ? `${translate("resources.root.totalPrice")} - ${
                              modifiedPriceInIQD > 0
                                ? roundToTwo(modifiedPriceInIQD * orderQtt)
                                : roundToTwo(
                                    selectedProduct.scPurchasePrice * orderQtt
                                  )
                            }  IQD`
                          : translate("resources.root.totalPrice")
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                    />
                  )}
                  <Chip
                    label={
                      selectedProduct
                        ? `${translate("resources.root.currency")} - ${
                            payByMain
                              ? translate("resources.root.usd")
                              : " " + translate("resources.root.iqd")
                          }`
                        : translate("resources.root.currency")
                    }
                    className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                  />
                  {previousPurchasePrice && (
                    <Chip
                      label={
                        selectedProduct && payByMain
                          ? `${previousPurchasePrice.mcPurchasePrice?.toLocaleString()} ${translate(
                              "resources.root.usd"
                            )}`
                          : `${previousPurchasePrice.scPurchasePrice?.toLocaleString()} ${translate(
                              "resources.root.iqd"
                            )}`
                      }
                      className={`${classes.bottomLeftMargin} ${classes.primaryChips}`}
                      style={{
                        backgroundColor: "lightgreen",
                        color: "black",
                        marginLeft: "auto",
                      }}
                    />
                  )}
                </div>
                {/* Product enter detail */}
                <div className={classes.orderCols}>
                  <div style={{ width: "350px" }}>
                    <AsyncSelect
                      defaultOptions
                      menuPortalTarget={document.body}
                      loadOptions={loadOptions.products}
                      styles={{
                        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      }}
                      onChange={(e) => {
                        setSelectedProduct(e.product);
                        setOrderQtt(1);
                      }}
                    />
                  </div>
                  {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                    <div style={{ width: "250px" }}>
                      <MaterialTextField
                        label={translate("resources.root.serial")}
                        value={productSerial}
                        onChange={(e) => {
                          setProductSerial(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                    <div>
                      <Form.Group className={`m-4`}>
                        <Datetime
                          initialValue={moment(new Date()).add(6, "M")}
                          defaultShow={true}
                          timeFormat={false}
                          dateFormat="YYYY-MM-DD"
                          onChange={(e) => {
                            setProductSerialExpire(
                              e.format("YYYY-MM-DD HH:mm:ss")
                            );
                          }}
                        />
                      </Form.Group>
                    </div>
                  )}
                  {/*<Autocomplete*/}
                  {/*    id="combo-box-demo"*/}
                  {/*    options={products}*/}
                  {/*    // inputValue={selectedProduct ? selectedProduct.name : ''}*/}
                  {/*    getOptionLabel={(option) =>*/}
                  {/*        `${option.name} - ${option.barcode}`*/}
                  {/*    }*/}
                  {/*    style={{ width: 300 }}*/}
                  {/*    onChange={(el, val) => {*/}
                  {/*      setSelectedProduct(val);*/}
                  {/*      setOrderQtt(0);*/}
                  {/*      setModifiedPriceInUSD(0);*/}
                  {/*      setModifiedPriceInIQD(0);*/}
                  {/*    }}*/}
                  {/*    renderInput={(params) => (*/}
                  {/*        <MaterialTextField*/}
                  {/*            {...params}*/}
                  {/*            label={translate("resources.root.chooseProduct")}*/}
                  {/*            variant="outlined"*/}
                  {/*        />*/}
                  {/*    )}*/}
                  {/*/>*/}

                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.quantity")}
                    disabled={!selectedProduct}
                    value={orderQtt}
                    onFocus={(event) => event.target.select()}
                    onChange={(e) => {
                      setOrderQtt(e.target.value);
                    }}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    label={translate("resources.root.max")}
                    disabled={true}
                    value={selectedProduct ? selectedProduct.qteInStock : 0}
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.priceInUSD")}
                    disabled={
                      !selectedProduct || (selectedProduct && !payByMain)
                    }
                    value={
                      modifiedPriceInUSD <= 0 || modifiedPriceInIQD > 0
                        ? selectedProduct
                          ? payByMain
                            ? selectedProduct.mcPurchasePrice
                            : modifiedPriceInIQD <= 0
                            ? roundToTwo(
                                selectedProduct.scPurchasePrice / ratio
                              )
                            : roundToTwo(modifiedPriceInIQD / ratio)
                          : 0
                        : modifiedPriceInUSD
                    }
                    onChange={(e) =>
                      setModifiedPriceInUSD(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.priceInIQD")}
                    disabled={
                      !selectedProduct || (selectedProduct && payByMain)
                    }
                    value={
                      modifiedPriceInIQD <= 0 || modifiedPriceInUSD > 0
                        ? selectedProduct
                          ? !payByMain
                            ? selectedProduct.scPurchasePrice
                            : modifiedPriceInUSD <= 0
                            ? roundToTwo(
                                selectedProduct.mcPurchasePrice * ratio
                              )
                            : roundToTwo(modifiedPriceInUSD * ratio)
                          : 0
                        : modifiedPriceInIQD
                    }
                    onChange={(e) =>
                      setModifiedPriceInIQD(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <MaterialTextField
                    type="number"
                    label={translate("resources.root.gifts")}
                    disabled={!selectedProduct}
                    value={gift}
                    onChange={(e) =>
                      parseFloat(e.target.value) +
                        orders
                          .filter((el) => el.productId === selectedProduct.id)
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0) +
                        e.target.value >=
                        0 && setGift(parseFloat(e.target.value))
                    }
                    className={classes.orderNumberInput}
                  />
                  <Button
                    variant="contained"
                    className={classes.botton}
                    disabled={orderQtt <= 0 || !selectedProduct}
                    onClick={() => {
                      swal({
                        title: `${translate("resources.root.areYouSure")}`,
                        text: `${translate("resources.root.toAddNewOrder")}`,
                        icon: "warning",
                        buttons: true,
                        dangerMode: true,
                      }).then((willDelete) => {
                        if (willDelete) {
                          const sc = localStorage.getItem("dollarPrice");
                          HTTP.post(`${url}/PurchaseOrders`, {
                            purchaseInvoiceId: purchaseInvoices.id,
                            productId: selectedProduct.id,
                            productMeasure: selectedProduct.productMeasure,
                            oneContains: selectedProduct.oneContains,
                            productSerial: {
                              serialNumber: productSerial,
                              expirationDate: productSerialExpire,
                            },
                            oneMeasure: selectedProduct.oneMeasure,
                            mcDiscount: discount,
                            scDiscount: discount * sc,
                            giftQte: gift,
                            mcLastPrice:
                              selectedProduct.mcPurchasePrice - discount,
                            scLastPrice:
                              selectedProduct.scPurchasePrice - discount * sc,
                            payByMain: purchaseInvoices.payByMain,
                            barcode: selectedProduct.barcode,
                            name: selectedProduct.name,
                            qte: orderQtt,
                            mcPurchasePrice:
                              modifiedPriceInUSD > 0
                                ? modifiedPriceInUSD
                                : selectedProduct.mcPurchasePrice,
                            scPurchasePrice:
                              modifiedPriceInIQD > 0
                                ? modifiedPriceInIQD
                                : selectedProduct.scPurchasePrice,
                            mcTotalPrice:
                              selectedProduct.mcPurchasePrice * orderQtt,
                            scTotalPrice:
                              selectedProduct.scPurchasePrice * orderQtt,
                          })
                            .then((res) => {
                              if (id !== 0) {
                                HTTP.get(`${url}/PurchaseInvoices/${id}`).then(
                                  (res) => {
                                    setPurchaseInvoices(res.data);
                                    setPurchaseOrders(res.data.purchaseOrders);
                                  }
                                );
                              }
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                          setOrderQtt(0);
                          setOrderToEdit(-1);
                          setGift(0);
                        }
                      });
                    }}
                  >
                    {translate("resources.root.addOrder")}
                  </Button>
                </div>

                <div className={classes.orderCols}>
                  <TableContainer
                    component={Paper}
                    className={classes.tableContainer}
                  >
                    <Table
                      stickyHeader
                      className={classes.table}
                      size="small"
                      aria-label="a dense table"
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            {translate("resources.root.barcode")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.name")}
                          </TableCell>
                          {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                            <TableCell align="right">
                              {translate("resources.root.serial")}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {translate("resources.root.quantity")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.gifts")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.price")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.discount")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.finalPrice")}
                          </TableCell>
                          <TableCell align="right">
                            {translate("resources.root.actions")}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.scrollable}>
                        {purchaseOrders &&
                          purchaseOrders.map((order) => (
                            <TableRow key={order.product.barcode}>
                              <TableCell component="th" scope="row">
                                {order.product.barcode}
                              </TableCell>
                              <TableCell align="right">
                                {order.product.name}
                              </TableCell>
                              {process.env.REACT_APP_PRODUCT_SERIAL ===
                                "true" && (
                                <TableCell align="right">
                                  {order.productSerial?.serialNumber}
                                </TableCell>
                              )}
                              <TableCell align="right">{order.qte}</TableCell>
                              <TableCell align="right">
                                {order.giftQte}
                              </TableCell>
                              <TableCell align="right">
                                {purchaseInvoices.payByMain
                                  ? roundToTwo(
                                      order.mcPurchasePrice
                                    )?.toLocaleString()
                                  : roundToTwo(
                                      order.scPurchasePrice
                                    )?.toLocaleString()}
                              </TableCell>
                              <TableCell align="right">
                                {purchaseInvoices.payByMain
                                  ? roundToTwo(
                                      order.mcDiscount
                                    )?.toLocaleString()
                                  : roundToTwo(
                                      order.scDiscount
                                    )?.toLocaleString()}
                              </TableCell>
                              <TableCell align="right">
                                {purchaseInvoices.payByMain
                                  ? roundToTwo(
                                      order.mcLastPrice
                                    )?.toLocaleString()
                                  : roundToTwo(
                                      order.scLastPrice
                                    )?.toLocaleString()}
                              </TableCell>
                              <TableCell ell align="right">
                                {purchaseOrders.length > 1 && (
                                  <DeleteIcon
                                    onClick={() => {
                                      swal({
                                        title: `${translate(
                                          "resource.root.areYouSure"
                                        )}`,
                                        icon: "warning",
                                        buttons: true,
                                        dangerMode: true,
                                      }).then((willDelete) => {
                                        if (willDelete) {
                                          HTTP.delete(
                                            `${url}/PurchaseOrders/${order.id}`
                                          )
                                            .then((res) => {
                                              setPurchaseOrders((prev) => {
                                                return prev.filter(
                                                  (el) => el.id != res.data.id
                                                );
                                              });
                                            })
                                            .catch((err) =>
                                              notify(
                                                err.response.data.message,
                                                {
                                                  type: "error",
                                                  undoable: true,
                                                }
                                              )
                                            );
                                        }
                                      });
                                    }}
                                  />
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {purchaseInvoices.payByMain ? (
                    <div>
                      <Chip
                        label={`${translate(
                          "resources.root.totalPrice"
                        )} - ${"$"}${purchaseOrders
                          .map(
                            (el) =>
                              parseFloat(el.mcPurchasePrice) *
                              parseFloat(el.qte)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0)
                          ?.toLocaleString()} 
                      `}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.finalPrice"
                        )} - ${"$"}${(
                          purchaseOrders
                            .map(
                              (el) =>
                                parseFloat(el.mcPurchasePrice) *
                                parseFloat(el.qte)
                            )
                            .reduce((a, b) => roundToTwo(a + b), 0) - discount
                        )?.toLocaleString()} 
                      `}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalQuantity"
                        )} - ${purchaseOrders
                          .map((el) => parseFloat(el.qte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalGifts"
                        )} - ${purchaseOrders
                          .map((el) => parseFloat(el.giftQte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.finalQuantity"
                        )} - ${purchaseOrders
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                    </div>
                  ) : (
                    <div>
                      <Chip
                        label={`${translate(
                          "resources.root.totalPrice"
                        )} - ${purchaseOrders
                          .map(
                            (el) =>
                              parseFloat(el.scPurchasePrice) *
                              parseFloat(el.qte)
                          )
                          .reduce((a, b) => roundToTwo(a + b), 0)
                          ?.toLocaleString()} 
                      IQD`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate("resources.root.finalPrice")} - ${(
                          purchaseOrders
                            .map(
                              (el) =>
                                parseFloat(el.scPurchasePrice) *
                                parseFloat(el.qte)
                            )
                            .reduce((a, b) => roundToTwo(a + b), 0) - discount
                        )?.toLocaleString()} 
                      IQD`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalQuantity"
                        )} - ${purchaseOrders
                          .map((el) => parseFloat(el.qte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.totalGifts"
                        )} - ${purchaseOrders
                          .map((el) => parseFloat(el.giftQte))
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                      <Chip
                        label={`${translate(
                          "resources.root.finalQuantity"
                        )} - ${purchaseOrders
                          .map(
                            (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                          )
                          .reduce((a, b) => a + b, 0)
                          ?.toLocaleString()}`}
                        className={`${classes.topLeftMargin} ${classes.primaryChips}`}
                      />
                    </div>
                  )}
                  <MaterialTextField
                    type="number"
                    className={classes.inPercentage}
                    label={translate("resources.root.discount")}
                    disabled={true}
                    value={
                      purchaseInvoices.payByMain
                        ? purchaseInvoices.mcDiscount
                        : purchaseInvoices.scDiscount
                    }
                    variant="filled"
                    onChange={(e) => {
                      e.target.value >= 0 &&
                        e.target.value <=
                          orders
                            .map(
                              (el) =>
                                parseFloat(el.mcPurchasePrice) *
                                parseFloat(el.qte)
                            )
                            .reduce((a, b) => a + b, 0) &&
                        setDiscount(e.target.value);
                      setPercentageDiscount(
                        parseFloat(
                          (discount * 100) /
                            orders
                              .map(
                                (el) =>
                                  parseFloat(el.mcPurchasePrice) *
                                  parseFloat(el.qte)
                              )
                              .reduce((a, b) => roundToTwo(a + b), 0)
                        )
                      );
                    }}
                  />
                  <Snackbar
                    open={open}
                    autoHideDuration={4000}
                    onClose={handleClose}
                  >
                    <Alert onClose={handleClose} severity="error">
                      {errorMessage}
                    </Alert>
                  </Snackbar>
                </div>
                <div className={classes.orderCols}>
                  <MaterialTextField
                    label={translate("resources.root.note")}
                    value={purchaseInvoices.note}
                    disabled={true}
                    className={classes.noteInput}
                  />
                  <MaterialTextField
                    label={translate("resources.root.driver")}
                    value={purchaseInvoices.driver}
                    disabled={true}
                    className={classes.orderTextInput}
                  />
                </div>
              </Paper>
            </Grid>
          </Grid>
        </div>
      </CardContent>
    </Card>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  invoiceNumberInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  table: {},
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    display: "none",
    width: "100%",
    height: "950",
    ["@media print"]: {
      // eslint-disable-line no-useless-computed-key
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
