import * as React from "react";
import {
  List,
  Datagrid,
  TextField as ReactAdminTextField,
  Create,
  DateField,
  Show,
  SimpleShowLayout,
  FunctionField,
  ArrayField,
  useTranslate,
  NumberField,
  useNotify,
  ShowButton,
} from "react-admin";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { Col, Row } from "react-bootstrap";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import Swal from "sweetalert2";
import { HTTP } from "../../axios";
import Autocomplete from "@mui/lab/Autocomplete";
import { url, handleRequestResponse } from "../../request";
import TextField from "@mui/material/TextField";
import { Form } from "react-bootstrap";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import loadOptions from "../../loadOptions";

export const ProcessInvoicesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();
  return (
    <List
      debounce={1500}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      {...props}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="processinvoice.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction
            {...props}
            hasPrintOrders={"ProcessInvoice"}
            hasDelete="processinvoice.list"
          />
        }
      >
        <NumberField source="id" label="resources.root.id" />
        <FunctionField
          sortBy={"totalProcessQte"}
          label="resources.root.producedQuantity"
          render={(record) =>
            `${
              record.totalProcessQte && record.totalProcessQte?.toLocaleString()
            } + ${
              record.totalProcessSmallQte &&
              record.totalProcessSmallQte?.toLocaleString()
            }`
          }
        />
        <FunctionField
          sortBy={"totalProducedQte"}
          label="resources.root.producedQte"
          render={(record) =>
            `${
              record.totalProducedQte &&
              record.totalProducedQte?.toLocaleString()
            } + ${
              record.totalProducedSmallQte &&
              record.totalProducedSmallQte?.toLocaleString()
            }`
          }
        />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcTotalProcessPrice"}
            label="resources.root.totalProcessPrice"
            render={(record) =>
              record.purchaseByMain
                ? `$${
                    record.mcTotalProcessPrice &&
                    record.mcTotalProcessPrice?.toLocaleString()
                  }`
                : `${
                    record.scTotalProcessPrice &&
                    record.scTotalProcessPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            sortBy={"mcTotalProducedPrice"}
            label="resources.root.totalProducedPrice"
            render={(record) =>
              record.purchaseByMain
                ? `$${
                    record.mcTotalProducedPrice &&
                    record.mcTotalProducedPrice?.toLocaleString()
                  }`
                : `${
                    record.scTotalProducedPrice &&
                    record.scTotalProducedPrice?.toLocaleString()
                  } ${translate("resources.root.iqd")}`
            }
          />
        )}
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        <ReactAdminTextField source="note" label="resources.root.note" />
        {localStorage.getItem("showLastUser") === "true" && (
          <ReactAdminTextField
            source="lastUser"
            label="resources.root.lastUser"
          />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}{" "}
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const ProcessInvoicesShow = (props) => {
  const translate = useTranslate();
  return (
    <Show>
      <SimpleShowLayout>
        <FunctionField
          label="resources.root.producedQuantity"
          render={(record) =>
            `${
              record.totalProcessQte && record.totalProcessQte?.toLocaleString()
            } + ${
              record.totalProcessSmallQte &&
              record.totalProcessSmallQte?.toLocaleString()
            }`
          }
        />
        <FunctionField
          label="resources.root.producedQte"
          render={(record) =>
            `${
              record.totalProducedQte &&
              record.totalProducedQte?.toLocaleString()
            } + ${
              record.totalProducedSmallQte &&
              record.totalProducedSmallQte?.toLocaleString()
            }`
          }
        />
        <FunctionField
          label="resources.root.totalProcessPrice"
          render={(record) =>
            `${
              record.scTotalProcessPrice &&
              record.scTotalProcessPrice?.toLocaleString()
            } ${translate("resources.root.iqd")}`
          }
        />
        <FunctionField
          label="resources.root.totalProducedPrice"
          render={(record) =>
            `${
              record.scTotalProducedPrice &&
              record.scTotalProducedPrice?.toLocaleString()
            } ${translate("resources.root.iqd")}`
          }
        />
        <DateField
          source="date"
          label="resources.root.date"
          locales={"en-GB"}
        />
        <ReactAdminTextField source="note" label="resources.root.note" />
        <Row>
          <Col xs={6}>
            <h4>{translate("resources.root.processOrders")}</h4>
            <ArrayField source="processOrders">
              <Datagrid>
                <FunctionField
                  label="resources.root.product"
                  render={(record) => {
                    return (
                      <a href={`#Products/${record.product.id}/show`}>
                        {record.product.name}
                      </a>
                    );
                  }}
                />
                <FunctionField
                  label="resources.root.barcode"
                  render={(record) => record.product.barcode}
                />
                <ReactAdminTextField
                  source="qte"
                  label="resources.root.processQuantity"
                />
              </Datagrid>
            </ArrayField>
          </Col>
          <Col xs={6}>
            <h4>{translate("resources.root.produceOrders")}</h4>
            <ArrayField source="produceOrders">
              <Datagrid>
                <FunctionField
                  label="resources.root.product"
                  render={(record) => {
                    return (
                      <a href={`#Products/${record.product.id}/show`}>
                        {record.product.name}
                      </a>
                    );
                  }}
                />
                <FunctionField
                  label="resources.root.barcode"
                  render={(record) => record.product.barcode}
                />
                <ReactAdminTextField
                  source="qte"
                  label="resources.root.producedQuantity"
                />
              </Datagrid>
            </ArrayField>
          </Col>
        </Row>
      </SimpleShowLayout>
    </Show>
  );
};

export const ProcessInvoicesCreate = (props) => {
  const translate = useTranslate();
  const notify = useNotify();

  const [products, setProducts] = React.useState([]);
  const [generalData, setGeneralData] = React.useState({
    note: null,
    date: moment(new Date()).format("YYYY-MM-DD"),
  });
  const [processOrders, setProcessOrders] = React.useState(
    JSON.parse(localStorage.getItem("processOrders")) || []
  );
  const [produceOrders, setProduceOrders] = React.useState(
    JSON.parse(localStorage.getItem("produceOrders")) || []
  );
  localStorage.setItem("processOrders", JSON.stringify(processOrders));
  localStorage.setItem("produceOrders", JSON.stringify(produceOrders));

  const [selectedProcessOrder, setSelectedProcessOrder] = React.useState(0);
  const [processQty, setProcessQty] = React.useState(0);
  const [processSmallMeasureQty, setProcessSmallMeasureQty] = React.useState(0);

  const [selectedProduceOrder, setSelectedProduceOrder] = React.useState(0);
  const [produceQty, setProduceQty] = React.useState(0);
  const [produceSmallMeasureQty, setProduceSmallMeasureQty] = React.useState(0);

  const processHandlePushValue = (
    newObject,
    isProcess = false,
    isProduce = false
  ) => {
    if (isProcess) {
      const existingValueIndex = processOrders?.findIndex(
        (order) => order?.productId === newObject?.productId
      );
      if (existingValueIndex === -1) {
        setProcessOrders((prevOrders) => [...prevOrders, newObject]);
        setSelectedProcessOrder(0);
        setProcessQty(0);
        setProcessSmallMeasureQty(0);
      } else {
        const tempOrders = processOrders;

        tempOrders.map((order, index) => {
          if (index === existingValueIndex) {
            order.qte += newObject.qte;
            order.smallMeasureQte += newObject.smallMeasureQte;
          }
        });
        setProcessOrders(tempOrders);
        setSelectedProcessOrder(null);
        setProcessQty(0);
        setProcessSmallMeasureQty(0);
      }
    } else if (isProduce) {
      const existingValueIndex = produceOrders?.findIndex(
        (order) => order.productId === newObject?.productId
      );

      if (existingValueIndex === -1) {
        setProduceOrders((prevOrders) => [...prevOrders, newObject]);
        setSelectedProduceOrder(0);
        setProduceQty(0);
        setProduceSmallMeasureQty(0);
      } else {
        const tempOrders = produceOrders;

        tempOrders.map((order, index) => {
          if (index === existingValueIndex) {
            order.qte += newObject.qte;
            order.smallMeasureQte += newObject.smallMeasureQte;
          }
        });
        setProduceOrders(tempOrders);
        setSelectedProduceOrder(null);
        setProduceQty(0);
        setProduceSmallMeasureQty(0);
      }
    }
  };

  const handleRemoveOrder = (
    productIdToRemove,
    isProcess = false,
    isProduce = false
  ) => {
    if (isProcess) {
      let updatedOrders = processOrders.filter(
        (order) => order.productId !== productIdToRemove
      );
      setProcessOrders(updatedOrders);
    } else if (isProduce) {
      let updatedOrders = produceOrders.filter(
        (order) => order.productId !== productIdToRemove
      );
      setProduceOrders(updatedOrders);
    }
  };

  React.useEffect(() => {
    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, []);
  return (
    <Create {...props} redirect="list" className="">
      <div className="p-4 bg-white">
        <div className="d-flex justify-content-start align-items-center w-100">
          <div className="col-6 col-md-3 d-flex justify-content-center align-items-center border border-secondary p-0 rounded bg-secondary">
            <div className="">
              <Form.Label className="m-0 text-nowrap p-1 px-2 text-white">
                {translate("resources.root.date")}
              </Form.Label>{" "}
            </div>
            <Form.Control
              className="p-1 "
              type="date"
              value={generalData.date}
              onChange={(e) => {
                setGeneralData((prevState) => ({
                  ...prevState,
                  date: e.target.value,
                }));
              }}
            />
          </div>
          <TextField
            style={{ width: 300 }}
            className="col-6 col-md-3 px-2"
            label={translate("resources.root.note")}
            variant="outlined"
            onChange={(e) =>
              setGeneralData((prevState) => ({
                ...prevState,
                note: e.target.value,
              }))
            }
          />
        </div>
        <div className="d-flex justify-content-between align-items-start my-5">
          <div className="border rounded d-flex flex-column justify-content-center align-items-center mx-2 p-2 grow-1">
            <div className="btn btn-primary btn-sm text-center w-100 mt-2 cursor-normal">
              {translate("resources.root.processOrders")}
            </div>
            {selectedProcessOrder?.id > 0 && (
              <div className=" d-flex justify-content-center align-items-center mt-4">
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white fs-6">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.productMeasure")} -{" "}
                    {selectedProcessOrder?.productMeasure}{" "}
                  </p>
                </div>
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white fs-6">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.oneMeasure")} -{" "}
                    {selectedProcessOrder?.oneMeasure}{" "}
                  </p>
                </div>
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white fs-6">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.oneContains")} -{" "}
                    {selectedProcessOrder?.oneContains}{" "}
                  </p>
                </div>{" "}
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.sellPrice")} -{" "}
                    {selectedProcessOrder?.sellByMain
                      ? `$${selectedProcessOrder?.mcSellPrice}`
                      : `${selectedProcessOrder?.scSellPrice} IQD`}
                  </p>
                </div>
              </div>
            )}
            <div className="row justify-content-between align-items-center w-100 mt-5">
              {localStorage.getItem("useOldSelect") === "true" ? (
                <Autocomplete
                  className="col-12 col-md-6"
                  options={products}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.barcode} - (${option.qteInStock})`
                  }
                  style={{ width: 300 }}
                  onChange={(e, val) => {
                    setSelectedProcessOrder(val);
                    setProcessQty(0);
                    setProcessSmallMeasureQty(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("resources.root.product")}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <AsyncSelect
                  defaultOptions
                  className="col-12 col-md-6 w-100"
                  loadOptions={loadOptions.products}
                  getOptionLabel={(option) =>
                    `${option.product.name} - ${option.product.barcode}`
                  }
                  onChange={(e, val) => {
                    setSelectedProcessOrder(e.product);
                    setProcessQty(0);
                    setProcessSmallMeasureQty(0);
                  }}
                />
              )}
              <TextField
                style={{ width: 300 }}
                type="number"
                className="col-12 col-md-2 px-2"
                label={translate("resources.root.qty")}
                variant="outlined"
                name="qte"
                disabled={!selectedProcessOrder}
                value={processQty}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) {
                    return setProcessQty(0);
                  }
                  setProcessQty(parseFloat(e.target.value));
                }}
              />
              <TextField
                style={{ width: 300 }}
                type="number"
                className="col-12 col-md-2 px-2"
                label={translate("resources.root.smallQty")}
                variant="outlined"
                disabled={!selectedProcessOrder}
                name="smallMeasureQte"
                value={processSmallMeasureQty}
                onChange={(e, val) => {
                  if (e.target.value < 0 || !e.target.value) {
                    return setProcessSmallMeasureQty(0);
                  }
                  setProcessSmallMeasureQty(parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  let currentValue = parseFloat(e.target.value);

                  if (currentValue >= selectedProcessOrder?.oneContains) {
                    setProcessSmallMeasureQty(
                      currentValue % selectedProcessOrder?.oneContains
                    );
                    setProcessQty(
                      parseFloat(processQty) +
                        parseInt(
                          currentValue / selectedProcessOrder?.oneContains
                        )
                    );
                  }
                }}
              />
              <div className="col-2 px-1">
                <button
                  type="button"
                  className="btn btn-success btn-block text-center"
                  onClick={() =>
                    processHandlePushValue(
                      {
                        productId: selectedProcessOrder?.id,
                        productName: selectedProcessOrder?.name,
                        productBarcode: selectedProcessOrder?.barcode,
                        productQteInStock: selectedProcessOrder?.qteInStock,
                        qte: processQty,
                        smallMeasureQte: processSmallMeasureQty,
                      },
                      true
                    )
                  }
                >
                  {translate("resources.root.add")}
                </button>
              </div>
              <hr />
              {processOrders?.length > 0 && (
                <table className="col-12 table table-striped text-center mt-4">
                  <thead>
                    <tr>
                      <th scope="col">{translate("resources.root.name")}</th>
                      <th scope="col">{translate("resources.root.barcode")}</th>
                      <th scope="col">
                        {translate("resources.root.qteInStock")}
                      </th>
                      <th scope="col">{translate("resources.root.sendQty")}</th>
                      <th scope="col">
                        {translate("resources.root.totalSmallQte")}
                      </th>
                      <th scope="col">{translate("resources.root.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {processOrders?.map((order) => {
                      return (
                        <tr key={order.productId}>
                          <th>{order?.productName}</th>
                          <th>{order?.productBarcode}</th>
                          <td>{order?.productQteInStock}</td>
                          <td>{order?.qte}</td>
                          <td>{order?.smallMeasureQte}</td>
                          <td
                            onClick={() =>
                              handleRemoveOrder(order?.productId, true, false)
                            }
                            className="d-flex align-items-center justify-content-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              className="bi bi-trash3 btn btn-danger p-1 "
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="border rounded d-flex flex-column justify-content-center align-items-center mx-2 p-2 grow-1">
            <div className="btn btn-primary btn-sm text-center w-100 mt-2 cursor-normal">
              {translate("resources.root.produceOrders")}
            </div>{" "}
            {selectedProduceOrder > 0 && (
              <div className=" d-flex justify-content-center align-items-center mt-4">
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white fs-6">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.productMeasure")} -{" "}
                    {selectedProduceOrder?.productMeasure}{" "}
                  </p>
                </div>
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white fs-6">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.oneMeasure")} -{" "}
                    {selectedProduceOrder?.oneMeasure}{" "}
                  </p>
                </div>
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white fs-6">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.oneContains")} -{" "}
                    {selectedProduceOrder?.oneContains}{" "}
                  </p>
                </div>{" "}
                <div className="rounded-pill p-1 px-2 mx-1 bg-primary text-white">
                  <p className="p-0 m-0 fs-sm">
                    {translate("resources.root.sellPrice")} -{" "}
                    {selectedProduceOrder?.sellByMain
                      ? `$${selectedProduceOrder?.mcSellPrice}`
                      : `${selectedProduceOrder?.scSellPrice} IQD`}
                  </p>
                </div>
              </div>
            )}
            <div className="row justify-content-between align-items-center w-100 mt-5">
              {localStorage.getItem("useOldSelect") === "true" ? (
                <Autocomplete
                  className="col-12 col-md-6"
                  options={products}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.barcode} - (${option.qteInStock})`
                  }
                  style={{ width: 300 }}
                  onChange={(e, val) => {
                    setSelectedProduceOrder(val);
                    setProduceQty(0);
                    setProduceSmallMeasureQty(0);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={translate("resources.root.product")}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <AsyncSelect
                  defaultOptions
                  className="col-12 col-md-6 w-100"
                  loadOptions={loadOptions.products}
                  getOptionLabel={(option) =>
                    `${option.product.name} - ${option.product.barcode}`
                  }
                  onChange={(e, val) => {
                    setSelectedProduceOrder(e.product);
                    setProduceQty(0);
                    setProduceSmallMeasureQty(0);
                  }}
                />
              )}
              <TextField
                style={{ width: 300 }}
                type="number"
                className="col-12 col-md-2 px-2"
                label={translate("resources.root.qty")}
                variant="outlined"
                disabled={!selectedProduceOrder}
                name="qte"
                value={produceQty}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) {
                    return setProduceQty(0);
                  }
                  setProduceQty(parseFloat(e.target.value));
                }}
              />
              <TextField
                style={{ width: 300 }}
                type="number"
                className="col-12 col-md-2 px-2"
                label={translate("resources.root.smallQty")}
                variant="outlined"
                disabled={!selectedProduceOrder}
                name="smallMeasureQte"
                value={produceSmallMeasureQty}
                onChange={(e) => {
                  if (e.target.value < 0 || !e.target.value) {
                    return setProduceSmallMeasureQty(0);
                  }
                  setProduceSmallMeasureQty(parseFloat(e.target.value));
                }}
                onBlur={(e) => {
                  let currentValue = parseFloat(e.target.value);

                  if (currentValue >= selectedProduceOrder?.oneContains) {
                    setProduceSmallMeasureQty(
                      currentValue % selectedProduceOrder?.oneContains
                    );
                    setProduceQty(
                      parseFloat(produceQty) +
                        parseInt(
                          currentValue / selectedProduceOrder?.oneContains
                        )
                    );
                  }
                }}
              />
              <div className="col-2 px-1">
                <button
                  type="button"
                  className="btn btn-success btn-block text-center"
                  onClick={() =>
                    processHandlePushValue(
                      {
                        productId: selectedProduceOrder?.id,
                        productName: selectedProduceOrder?.name,
                        productBarcode: selectedProduceOrder?.barcode,
                        productQteInStock: selectedProduceOrder?.qteInStock,
                        qte: produceQty,
                        smallMeasureQte: produceSmallMeasureQty,
                      },
                      false,
                      true
                    )
                  }
                >
                  {translate("resources.root.add")}
                </button>
              </div>
              <hr />
              {produceOrders?.length > 0 && (
                <table className="col-12 table table-striped text-center mt-4">
                  <thead>
                    <tr>
                      <th scope="col">{translate("resources.root.name")}</th>
                      <th scope="col">{translate("resources.root.barcode")}</th>
                      <th scope="col">
                        {translate("resources.root.qteInStock")}
                      </th>
                      <th scope="col">{translate("resources.root.sendQty")}</th>
                      <th scope="col">
                        {translate("resources.root.totalSmallQte")}
                      </th>
                      <th scope="col">{translate("resources.root.actions")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {produceOrders?.map((order, index) => {
                      return (
                        <tr key={order?.productId}>
                          <th>{order?.productName}</th>
                          <th>{order?.productBarcode}</th>
                          <td>{order?.productQteInStock}</td>
                          <td>{order?.qte}</td>
                          <td>{order?.smallMeasureQte}</td>
                          <td
                            onClick={() =>
                              handleRemoveOrder(order?.productId, false, true)
                            }
                            className="d-flex align-items-center justify-content-center"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              className="bi bi-trash3 btn btn-danger p-1 "
                              viewBox="0 0 16 16"
                            >
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5" />
                            </svg>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </div>
        <button
          onClick={async (e) => {
            const swalWithBootstrapButtons = Swal.mixin({
              customClass: {
                confirmButton: "btn btn-success",
                cancelButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
            swalWithBootstrapButtons
              .fire({
                title: translate("resources.root.swalTitle"),
                text: translate("resources.root.swalTransferProduct"),
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: translate("resources.root.yes"),
                cancelButtonText: translate("resources.root.no"),
              })
              .then((result) => {
                if (result.isConfirmed) {
                  [generalData.processOrders] = [processOrders];
                  [generalData.produceOrders] = [produceOrders];

                  HTTP.post(`/ProcessInvoices`, generalData)
                    .then((res) => {
                      setGeneralData({
                        note: null,
                        date: moment(new Date()).format("YYYY-MM-DD"),
                      });
                      setProcessOrders([]);
                      setProduceOrders([]);
                    })
                    .then(() => {
                      swalWithBootstrapButtons.fire({
                        title: translate("resources.root.swalSuccess"),
                        icon: "success",
                      });
                    })
                    .catch((err) => handleRequestResponse(notify, err));
                } else if (
                  /* Read more about handling dismissals below */
                  result.dismiss === Swal.DismissReason.cancel
                ) {
                }
              });
          }}
          className="btn btn-outline-primary btn-block mt-5"
        >
          {translate("resources.root.submit")}
        </button>
      </div>
    </Create>
  );
};
