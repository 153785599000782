import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  FunctionField,
  useTranslate,
  BooleanField,
  useNotify,
} from "react-admin";
import { ListActions } from "../templates/ListActions";
import { MyCustomPagination, SearchFilter } from "../../App";
import { HTTP } from "../../axios";
import { handleRequestResponse } from "../../request";
const BulkActionButtons = (props) => {
  console.log(props.selectedIds);
  const translate = useTranslate();
  const notify = useNotify();

  const fixSupplierLoan = async () => {
    try {
      const res = await HTTP.post(
        "/Utilities/FixSupplierLoan",
        props.selectedIds
      );
      notify(
        res?.response?.data?.message ??
          res?.response?.data?.title ??
          JSON.stringify(res?.response?.data) ??
          `${translate("resources.root.swalSuccess")} - `,
        {
          type: "success",
          undoable: true,
        }
      );
    } catch (error) {
      handleRequestResponse(notify, error);
    }
  };

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <button onClick={fixSupplierLoan} className="btn btn-primary">
          {translate("union")}
        </button>
      </div>
    </React.Fragment>
  );
};

export const CheckSuppliersLoanList = (props) => {
  const translate = useTranslate();

  return (
    <List
      debounce={1500}
      {...props}
      pagination={<MyCustomPagination />}
      filters={<SearchFilter />}
      title="List of posts"
      sort={{ field: "id", order: "DESC" }}
      resource="/Utilities/CheckSupplierLoan"
      actions={<ListActions createNoPermission />}
    >
      <Datagrid bulkActionButtons={<BulkActionButtons />}>
        <TextField source="id" label="resources.root.id" />
        <FunctionField
          sortBy={"firstName"}
          label="resources.root.supplierName"
          render={(record) => (
            <a
              href={`#/Reports/SupplierStatement/?id=${record.id}`}
              target={`_blank`}
            >{`${record.name} `}</a>
          )}
        />

        <FunctionField
          sortBy={"mcCurrentLoan"}
          label="resources.root.currentLoan"
          render={(record) =>
            record?.payLoanByMain
              ? `$ ${record.mcCurrentLoan?.toLocaleString()}`
              : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                  "resources.root.iqd"
                )}`
          }
        />

        <FunctionField
          sortBy={"mcExpectedLoan"}
          label="resources.root.expectedLoan"
          render={(record) =>
            record?.payLoanByMain
              ? `$ ${record.mcExpectedLoan?.toLocaleString()}`
              : `${record.scExpectedLoan?.toLocaleString()} ${translate(
                  "resources.root.iqd"
                )}`
          }
        />
        <BooleanField source="payLoanByMain" label="resources.root.isDollar" />
      </Datagrid>
    </List>
  );
};
