import * as React from "react";
import { url } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import _ from "lodash";
import Checkbox from "@mui/material/Checkbox";
import { useSearchParams } from "react-router-dom";
import { Balance } from "@mui/icons-material";
import loadOptions from "../../loadOptions";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [params, setParams] = useSearchParams();

  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(params?.get("id") ? 10000 : 10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(params?.get("id") ? 10000 : 10);
  const [data, setData] = React.useState([]);
  const [dataRender, setDataRender] = React.useState([]);
  const [customer, setCustomer] = React.useState(params?.get("id") || 0);
  const [customerObject, setCustomerObject] = React.useState({});
  const [onlyDeposite, setOnlyDeposite] = React.useState(false);
  const [onlyWithdraw, setOnlyWithdraw] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    params?.get("id") ? null : moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    params?.get("id") ? null : moment(new Date()).format("YYYY-MM-DD")
  );
  const [startFromZero, setStartFromZero] = React.useState(false);
  const [balance, setBalance] = React.useState({
    mcPreviousBalance: 0,
    scPreviousBalance: 0,
    mcCurrentBalance: 0,
    scCurrentBalance: 0,
    mcInitialBalance: 0,
    scInitialBalance: 0,
    mcPpenAccountQty: 0,
    scPpenAccountQty: 0,
  });
  const [isLoading, setIsLoading] = React.useState(false);
  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData(customerObject) {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    await HTTP.get(
      `${url}/Reports/CustomerAccountStatement/${customer}?_start=${start}&_end=${end}&zero=${startFromZero}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        const tempBalance = {
          mcPreviousBalance: response.headers.mcpreviousbalance ?? 0,
          scPreviousBalance: response.headers.scpreviousbalance ?? 0,

          mcCurrentBalance: response.headers.mccurrentbalance ?? 0,
          scCurrentBalance: response.headers.sccurrentbalance ?? 0,

          mcInitialBalance: response.headers.mcinitialbalance ?? 0,
          scInitialBalance: response.headers.scinitialbalance ?? 0,

          mcOpenAccountQty: response.headers.mcopenaccountbalance ?? 0,
          scOpenAccountQty: response.headers.scopenaccountbalance ?? 0,
        };
        setBalance(tempBalance);

        let tempData = [];
        if (onlyDeposite === false && onlyWithdraw === true) {
          tempData = response?.data?.filter((item) => item.isWithdraw);
        } else if (onlyDeposite === true && onlyWithdraw === false) {
          tempData = response?.data?.filter((item) => !item.isWithdraw);
        } else {
          tempData = response?.data;
        }

        if (tempData?.length > 0) {
          let newBalance = 0;
          let firstBalance = startFromZero
            ? 0
            : customerObject?.payLoanByMain
            ? parseFloat(tempBalance?.mcInitialBalance ?? 0) +
              parseFloat(tempBalance?.mcPreviousBalance ?? 0)
            : parseFloat(tempBalance?.scInitialBalance ?? 0) +
              parseFloat(tempBalance?.scPreviousBalance ?? 0);

          newBalance = tempData[0].isWithdraw
            ? firstBalance +
              parseFloat(
                customerObject.payLoanByMain
                  ? tempData[0].mcWithdraw
                  : tempData[0].scWithdraw
              )
            : firstBalance -
              parseFloat(
                customerObject.payLoanByMain
                  ? tempData[0].mcDeposit
                  : tempData[0].scDeposit
              );
          tempData[0].balance = newBalance;

          for (let i = 1; i < tempData?.length; i++) {
            let temp = tempData[i].isWithdraw
              ? newBalance +
                parseFloat(
                  customerObject.payLoanByMain
                    ? tempData[i].mcWithdraw
                    : tempData[i].scWithdraw
                )
              : newBalance -
                parseFloat(
                  customerObject.payLoanByMain
                    ? tempData[i].mcDeposit
                    : tempData[i].scDeposit
                );
            tempData[i].balance = temp;
            newBalance = temp;
          }
        }
        setData(tempData);
        setDataRender(tempData);
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          redirect("/Login");
        }
        console.log(error?.response?.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      if (customerObject) {
        getData(customerObject);
      }
    },
    [startFromZero, start, end, onlyDeposite, onlyWithdraw]
  );

  React.useEffect(
    async function fetchCustomerData() {
      if (params?.get("id")) {
        await HTTP.get(`${url}/customers/${params.get("id")}`).then(
          (response) => {
            setCustomerObject(response.data);
            getData(response.data);
          }
        );
      }
    },
    [0]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div>
      <PrintProvider>
        <Print single name={`customerStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.customerStatement")}
              {data.length === 0
                ? ""
                : "(" +
                  customerObject.firstName +
                  " " +
                  customerObject.middleName +
                  ")"}
            </h1>
          </div>
          <NoPrint force>
            {isLoading && <LoadingScreen />}
            <Row
              style={{ fontSize: "15px" }}
              className="justify-content-center align-items-center m-2"
            >
              {!params?.get("id") && (
                <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
                  <p className="m-0 mr-2 text-nowrap">
                    {translate("resources.root.customer")} :
                  </p>
                  <AsyncSelect
                    defaultOptions
                    className="flex-grow-1"
                    loadOptions={loadOptions.customers}
                    onChange={(e) => {
                      setCustomer(e ? e.value : 0);
                      setCustomerObject(e ? e.customer : 0);
                      setData([]);
                      setDataRender([]);
                    }}
                  />
                </Col>
              )}
              <Col
                className={`col-12 ${
                  params?.get("id") ? "col-lg-12" : "col-lg-8"
                } mt-4`}
              >
                <div className="row justify-content-center align-items-center">
                  <Col className="col-12 col-md-6 mb-3 mb-lg-0">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3  mb-lg-0">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </div>
              </Col>
              <Col className="col-12 col-md-7 mt-4">
                <h5 className={`text-center`}>
                  {startDate && startDate.length > 0 ? (
                    <>
                      {translate("resources.root.previousBalance")}:{" "}
                      {customerObject.payLoanByMain ? (
                        <span>
                          ${" "}
                          {parseFloat(
                            balance?.mcPreviousBalance
                          ).toLocaleString()}{" "}
                        </span>
                      ) : (
                        <span>
                          {parseFloat(
                            balance?.scPreviousBalance
                          ).toLocaleString()}{" "}
                          {translate("resources.root.iqd")}
                        </span>
                      )}
                    </>
                  ) : (
                    <span>
                      {translate("resources.root.initialBalance")}:{" "}
                      {customerObject.payLoanByMain ? (
                        <span>
                          ${" "}
                          {parseFloat(
                            balance?.mcInitialBalance
                          ).toLocaleString()}{" "}
                        </span>
                      ) : (
                        <span>
                          {parseFloat(
                            balance?.scInitialBalance
                          ).toLocaleString()}{" "}
                          {translate("resources.root.iqd")}
                        </span>
                      )}
                    </span>
                  )}{" "}
                  -{" "}
                  <span>
                    {translate("resources.root.currentBalance")}:{" "}
                    {customerObject.payLoanByMain ? (
                      <span>
                        ${" "}
                        {parseFloat(balance?.mcCurrentBalance).toLocaleString()}{" "}
                      </span>
                    ) : (
                      <span>
                        {parseFloat(balance?.scCurrentBalance).toLocaleString()}{" "}
                        {translate("resources.root.iqd")}
                      </span>
                    )}
                  </span>
                </h5>
              </Col>{" "}
              <Col className="col-6 col-md-2 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        disabled={data.length === 0}
                        onChange={(e) => setStartFromZero(e.target.checked)}
                        name="gilad"
                        defaultChecked={startFromZero}
                      />
                    }
                    label={translate("resources.root.startFromZero")}
                  />
                </InputGroup>
              </Col>{" "}
              <Col className="col-6 col-md-3 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    control={
                      <Checkbox
                        disabled={data.length === 0}
                        value={onlyDeposite}
                        onChange={(e) => setOnlyDeposite(e.target.checked)}
                      />
                    }
                    label={translate("resources.root.withdraw")}
                  />
                  <FormControlLabel
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    control={
                      <Checkbox
                        disabled={data.length === 0}
                        value={onlyWithdraw}
                        onChange={(e) => setOnlyWithdraw(e.target.checked)}
                      />
                    }
                    label={translate("resources.root.deposit")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={data.length === 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={data.length === 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    let sidBarStyle = sidebar[0].style.display;
                    let MenuStyle = appMenu[0].style.display;
                    let appBarMargin = appBar[0].style.marginTop;
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";

                    window.print();

                    sidebar[0].style.display = sidBarStyle;
                    appMenu[0].style.display = MenuStyle;
                    appBar[0].style.marginTop = appBarMargin;
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  disabled={customer === 0}
                  onClick={() => {
                    setIsLoading(true);
                    getData(customerObject);
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    {customerObject.payLoanByMain ? (
                      <>
                        <th>{translate("resources.root.dollarWithdraw")}</th>
                        <th>{translate("resources.root.dollarDeposit")}</th>
                      </>
                    ) : (
                      <>
                        <th>{translate("resources.root.dinarWithdraw")}</th>
                        <th>{translate("resources.root.dinarDeposit")}</th>
                      </>
                    )}

                    <th>{translate("resources.root.balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#dcf7f7" }}>
                    <td colSpan={5} className="text-center">
                      {startDate && startDate.length > 0 ? (
                        <span>
                          {translate("resources.root.previousBalance")}
                        </span>
                      ) : (
                        <span>
                          {translate("resources.root.initialBalance")}
                        </span>
                      )}
                    </td>
                    <td>
                      {!startFromZero ? (
                        startDate && startDate.length > 0 ? (
                          <span>
                            {customerObject.payLoanByMain ? (
                              <span>
                                ${" "}
                                {parseFloat(
                                  balance.mcPreviousBalance
                                ).toLocaleString()}
                              </span>
                            ) : (
                              <span>
                                {parseFloat(
                                  balance.scPreviousBalance
                                ).toLocaleString()}{" "}
                                {translate("resources.root.iqd")}
                              </span>
                            )}
                          </span>
                        ) : (
                          <span>
                            {customerObject.payLoanByMain ? (
                              <span>
                                ${" "}
                                {parseFloat(
                                  balance.mcInitialBalance
                                ).toLocaleString()}
                              </span>
                            ) : (
                              <span>
                                {parseFloat(
                                  balance.scInitialBalance
                                ).toLocaleString()}{" "}
                                {translate("resources.root.iqd")}
                              </span>
                            )}
                          </span>
                        )
                      ) : (
                        0
                      )}
                    </td>
                  </tr>

                  {!isLoading &&
                    dataRender &&
                    dataRender.map((one, index) => {
                      return (
                        <tr
                          key={index}
                          className={`${
                            one.isWithdraw ? "bg-green" : "bg-red"
                          }`}
                        >
                          <td>
                            <a
                              href={
                                one.description === "Customer Payment"
                                  ? `#/CustomerPayments/${one.id}/show`
                                  : one.description === "Customer Invoice"
                                  ? `#/SellInvoices/${one.id}/show`
                                  : one.description === "Return Invoice"
                                  ? `#/SellReturnInvoices/${one.id}/show`
                                  : one.description === "Customer Visit"
                                  ? `#/CustomerVisits/${one.id}/show`
                                  : "#/"
                              }
                              target={"_blank"}
                            >
                              {one.id}
                            </a>
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          {customerObject.payLoanByMain ? (
                            <>
                              <td>$ {one.mcWithdraw.toLocaleString()}</td>
                              <td>$ {one.mcDeposit.toLocaleString()}</td>
                            </>
                          ) : (
                            <>
                              <td>{one.scWithdraw.toLocaleString()} IQD</td>
                              <td>{one.scDeposit.toLocaleString()} IQD</td>
                            </>
                          )}

                          <td>
                            <span>
                              {customerObject.payLoanByMain ? (
                                <span>
                                  ${" "}
                                  {parseFloat(
                                    one.balance.toFixed(3)
                                  ).toLocaleString()}{" "}
                                </span>
                              ) : (
                                <span>
                                  {parseFloat(
                                    one.balance.toFixed(3)
                                  ).toLocaleString()}{" "}
                                  {translate("resources.root.iqd")}
                                </span>
                              )}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>

              {!params?.get("id") && (
                <NoPrint force>
                  <div style={{ position: "relative" }}>
                    <InputGroup style={{ width: "200px", float: "right" }}>
                      <InputGroup.Text>
                        {translate("resources.root.perPage")}
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setPerPage(e.target.value)}
                        as="select"
                        custom
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                        <option value={2000}>2000</option>
                      </Form.Control>
                    </InputGroup>
                    <Pagination
                      shape="rounded"
                      activePage={activePage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={parseInt(totalItems)}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText={translate("resources.root.previous")}
                      nextPageText={translate("resources.root.next")}
                      itemClass="page-item"
                      linkClass="page-link"
                    />
                    <div className={`clearfix`}> </div>
                  </div>
                </NoPrint>
              )}
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
