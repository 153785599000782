import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  AutocompleteInput,
  required,
  TabbedForm,
  FormTab,
  FormDataConsumer,
  maxValue,
  minValue,
  number,
  useTranslate,
  useRefresh,
  downloadCSV,
  BooleanField,
  useNotify,
  Show,
  SimpleShowLayout,
  DateField,
  ShowButton,
  NumberField,
  ArrayField,
  ImageField,
  ImageInput,
  TabbedShowLayout,
  Tab,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Autocomplete from "@mui/lab/Autocomplete";
import MaterialTextField from "@mui/material/TextField";
import DeleteIcon from "@mui/icons-material/Delete";
import MaterialButton from "@mui/material/Button";
import swal from "sweetalert";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { hasPermission, MyCustomPagination, SearchFilter } from "../../App";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Input from "@mui/material/Input";
import { FaCalendar } from "react-icons/fa";
import BulkAction from "../CustomComponents/BulkActions";
import jsonExport from "jsonexport/dist";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import { url, path, handleRequestResponse } from "../../request";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UpdateIcon from "@mui/icons-material/Update";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import FilledInput from "@mui/material/FilledInput";
import Tooltip from "@mui/material/Tooltip";
import AttachmentTab from "../CustomComponents/AttachmentTab";
import { permissions } from "./../Permissions/AllPermission";

const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <React.Fragment>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Button
          color="primary"
          style={{ marginBottom: 15 }}
          onClick={() => {
            swal({
              text: `${translate("resources.root.days")}`,
              content: "input",
              button: {
                text: `${translate("resources.root.update")}`,
                closeModal: false,
              },
            }).then((days) => {
              if (days) {
                HTTP.post(
                  `${url}/Customers/BatchUpdateLimitDays?days=${days}`,
                  props.selectedIds
                )
                  .then(() => {
                    swal.stopLoading();
                    swal.close();
                    refresh();
                  })
                  .catch((err) => {
                    if (err) {
                      swal("Oh noes!", "The AJAX request failed!", "error");
                    } else {
                      swal.stopLoading();
                      swal.close();
                    }
                  });
              } else {
                swal(
                  `${translate("resources.root.pleaseEnterNumberOfDays")}`,
                  "",
                  "error"
                );
              }
            });
          }}
        >
          <FaCalendar /> &nbsp; {translate("resources.root.updateLimitDays")}
        </Button>
        <BulkAction {...props} hasDelete="customer.delete" />
      </div>
    </React.Fragment>
  );
};

export const CustomersList = (props) => {
  const role = localStorage.getItem("role") ?? "NoUser";
  const translate = useTranslate();
  const [currency, setCurrency] = React.useState("default");
  const [customers, setCustomers] = React.useState([]);
  const exporter = (data) => {
    const dataForExport = data.map((data) => {
      let {
        id,
        firstName,
        middleName,
        lastName,
        male,
        dob,
        email,
        phone,
        phone2,
        address,
        mcCurrentLoan,
        scCurrentLoan,
        mcOpenAccountLoan,
        scOpenAccountLoan,
        payLoanByMain,
        mcRoofLoan,
        scRoofLoan,
      } = data;
      let fullName = firstName + " " + middleName + " " + lastName;
      let gender = male
        ? translate("resources.root.male")
        : translate("resources.root.female");
      let birthdate = moment(dob).format("YYYY-MM-DD");
      let phoneNumber = phone.replace("964", "0");
      let phoneNumber2 = phone2 ? phone2.replace("964", "0") : "-";
      let currentLoan = payLoanByMain ? mcCurrentLoan : scCurrentLoan;
      let initialLoan = payLoanByMain ? mcOpenAccountLoan : scOpenAccountLoan;
      let roofLoan = payLoanByMain ? mcRoofLoan : scRoofLoan;
      return {
        id,
        fullName,
        gender,
        birthdate,
        email,
        phoneNumber,
        phoneNumber2,
        address,
        currentLoan,
        initialLoan,
        roofLoan,
      };
    });
    jsonExport(
      dataForExport,
      {
        headers: [
          "id",
          "fullName",
          "gender",
          "birthdate",
          "email",
          "phoneNumber",
          "phoneNumber2",
          "address",
          "currentLoan",
          "initialLoan",
          "roofLoan",
        ],
        rename: [
          translate("resources.root.id"),
          translate("resources.root.name"),
          translate("resources.root.gender"),

          process.env.REACT_APP_APP_NAME === "Mediply"
            ? translate("resources.root.startingDate")
            : translate("resources.root.birthdate"),

          translate("resources.root.email"),
          translate("resources.root.phone"),
          translate("resources.root.phone2"),
          translate("resources.root.address"),
          translate("resources.root.currentLoan"),
          translate("resources.root.initialLoan"),
          translate("resources.root.roofLoan"),
        ],
      },
      (err, csv) => {
        downloadCSV(
          "\ufeff" + csv,
          "customers." + moment(new Date()).format("YYMMDDHHmmssa")
        );
      }
    );
  };
  return (
    <List
      debounce={1500}
      {...props}
      exporter={exporter}
      pagination={<MyCustomPagination />}
      filters={<SearchFilter />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="customer.create"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkActionButtons setOrders={setCustomers} />}
      >
        <TextField source="id" label="resources.root.id" />
        <FunctionField
          sortBy={"firstName"}
          label="resources.root.customerName"
          render={(record) => (
            <a
              href={`#/Reports/CustomerStatement/?id=${record.id}`}
              target={`_blank`}
            >{`${record.firstName} ${record.middleName} ${record.lastName}`}</a>
          )}
        />
        <TextField source="email" label="resources.root.email" />
        {(role.toLowerCase() === "admin" ||
          role.toLowerCase() === "accountant" ||
          role.toLowerCase() === "superadmin") && (
          <TextField source="phone" label="resources.root.phone" />
        )}
        <TextField source="address" label="resources.root.address" />
        {currency === "default" && (
          <FunctionField
            sortBy={"mcCurrentLoan"}
            label="resources.root.currentLoan"
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (record.payLoanByMain
                ? `$ ${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            sortBy={"mcCurrentLoan"}
            label="resources.root.currentLoan"
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (currency === "dollar"
                ? `$ ${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        <BooleanField source="payLoanByMain" label="resources.root.isDollar" />
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}
        {hasPermission([permissions?.customerView]) && <ShowButton />}
        {hasPermission([permissions?.customerUpdate]) && <EditButton />}
      </Datagrid>
    </List>
  );
};

export const CustomersCreate = (props) => {
  const [choices, setChoices] = React.useState([]);
  const [selectedChoices, setSelectedChoices] = React.useState([]);
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  const classes = useStyles();
  React.useEffect(() => {
    HTTP.get(`${url}/Stores?_end=1000`).then((res) => {
      setChoices(res.data);
    });
  }, []);

  const optionRenderer = (choice) => `${choice.title}`;
  return (
    <Create
      {...props}
      transform={(data) => ({
        ...data,
        storeIds: [data.storeIds],
        country: "Iraq",
        city: "Erbil",
      })}
      redirect="list"
    >
      <SimpleForm defaultValues={{ discountRate: 0 }}>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <AutocompleteInput
              source="storeIds"
              choices={choices}
              optionText={optionRenderer}
              onSelect={(val) => setSelectedChoices([...selectedChoices, val])}
              label="resources.root.storeIds"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="firstName"
              label="resources.root.firstName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="middleName"
              label="resources.root.middleName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              label="resources.root.lastName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label="resources.root.email"
              className={classes.input}
            />
            <TextInput
              source="address"
              label="resources.root.address"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone"
              label="resources.root.phone"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label="resources.root.phone2"
              className={classes.input}
            />
            <BooleanInput
              source="male"
              label={gender ? "resources.root.male" : "resources.root.female"}
              defaultValue={true}
              onChange={(e) => setGender(e.target.checked)}
              className={classes.input}
              validate={[required()]}
            />

            <NumberInput
              source="loanLimitDays"
              label="resources.root.loanLimitDays"
              className={classes.input}
            />
          </div>
          <div className={classes.formCol}>
            {process.env.REACT_APP_APP_NAME === "Mediply" ? (
              <DateInput
                source="dob"
                label="resources.root.startingDate"
                defaultValue={moment().subtract(18, "years")}
                className={classes.input}
                validate={[required()]}
              />
            ) : (
              <DateInput
                source="dob"
                label="resources.root.dob"
                defaultValue={moment().subtract(18, "years")}
                className={classes.input}
                validate={[required()]}
              />
            )}
            <TextInput
              source="nationality"
              label="resources.root.nationality"
              defaultValue="Iraqi"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="currentWork"
              label="resources.root.currentWork"
              className={classes.input}
            />
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            <TextInput
              source="idCardNumber"
              label="resources.root.idCardNumber"
              className={classes.input}
            />
            <TextInput
              source="idCardType"
              label="resources.root.idCardType"
              className={classes.input}
            />
            {currency ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="mcRoofLoan"
                defaultValue={0}
                label="resources.root.mcRoofLoan"
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="scRoofLoan"
                defaultValue={0}
                label="resources.root.scRoofLoan"
                className={classes.input}
              />
            )}
            {currency ? (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.mcRoofLoan ? formData.mcRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.mcRoofLoan ? formData.mcRoofLoan : 0}
                      min={0}
                      defaultValue={0}
                      source="mcOpenAccountLoan"
                      label="resources.root.mcOpenAccountLoan"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[
                        required(),
                        maxValue(formData.scRoofLoan ? formData.scRoofLoan : 0),
                        minValue(0),
                      ]}
                      max={formData.scRoofLoan ? formData.scRoofLoan : 0}
                      min={0}
                      defaultValue={0}
                      source="scOpenAccountLoan"
                      label="resources.root.scOpenAccountLoan"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            )}
            <BooleanInput
              source="payLoanByMain"
              className={classes.input}
              label={currency ? "resources.root.usd" : "resources.root.iqd"}
              defaultValue={true}
              onChange={(e) => setCurrency(e.target.checked)}
            />

            <NumberInput
              source="discountRate"
              min={0}
              max={100}
              step={1}
              label="resources.root.discountRate"
              className={classes.input}
            />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const CustomersEdit = (props) => {
  const [stores, setStores] = React.useState([]);
  const [storeCustomers, setStoreCustomers] = React.useState([]);
  const [storeId, setStoreId] = React.useState();
  const [separatedNumber, setSeparatedNumber] = React.useState(0);
  const [customer, setCustomer] = React.useState(0);
  const [currentLoan, setCurrentLoan] = React.useState(0);
  const refresh = useRefresh();
  const { id } = useParams();

  const classes = useStyles();
  const translate = useTranslate();
  const notify = useNotify();

  function isInArray(value, array) {
    return array.indexOf(value) > -1;
  }

  React.useEffect(() => {
    HTTP.get(`${url}/Customers/${id}`).then((res) => {
      setCustomer(res.data);
      res.data.storeCustomers && setStoreCustomers(res.data.storeCustomers);
    });
  }, []);

  React.useEffect(() => {
    HTTP.get(`${url}/Stores?_end=10000`).then((res) => {
      setStores(
        res.data.filter(
          (el) =>
            !isInArray(
              el.id,
              storeCustomers.map((el) => el.store.id)
            )
        )
      );
    });
  }, [storeCustomers]);

  return (
    <Edit
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <TabbedForm>
        <FormTab label="resources.root.customers">
          <div className={classes.container}>
            <div className={classes.formCol}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextInput
                    source="firstName"
                    label="resources.root.firstName"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="middleName"
                    label="resources.root.middleName"
                    className={classes.input}
                  />
                  <TextInput
                    source="lastName"
                    label="resources.root.lastName"
                    className={classes.input}
                  />
                  <TextInput
                    source="email"
                    label="resources.root.email"
                    className={classes.input}
                  />
                  <TextInput
                    source="address"
                    label="resources.root.address"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="phone"
                    label="resources.root.phone"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="phone2"
                    label="resources.root.phone2"
                    className={classes.input}
                  />
                  <BooleanInput
                    source="male"
                    label="resources.root.male"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <NumberInput
                    source="loanLimitDays"
                    label="resources.root.loanLimitDays"
                    className={classes.input}
                  />
                  <NumberInput
                    source="discountRate"
                    min={0}
                    max={100}
                    step={1}
                    label="resources.root.discountRate"
                    className={classes.input}
                  />

                  <ImageInput
                    source="attachment"
                    label="resources.root.attachment"
                    accept="image/*"
                  >
                    <ImageField source="src" title="title" />
                  </ImageInput>
                  <FunctionField
                    label="resources.root.attachment"
                    render={(record) =>
                      record.attachment !== null && (
                        <div style={{ position: "relative" }}>
                          <button
                            type="button"
                            className="btn btn-danger p-2"
                            style={{ right: "0px", position: "absolute" }}
                            onClick={() => {
                              HTTP.delete(
                                `${url}/Customers/DeleteCustomerImage?id=${id}`
                              )
                                .then(() => {
                                  notify("Image deleted successfully", {
                                    type: "success",
                                    undoable: true,
                                  });
                                  refresh();
                                })
                                .catch((err) =>
                                  notify(err.response.data.message, {
                                    type: "error",
                                    undoable: true,
                                  })
                                );
                            }}
                          >
                            <span>&times;</span>
                          </button>
                          <img
                            style={{
                              width: 200,
                              height: 200,
                              objectFit: "scale-down",
                              cursor: "pointer",
                            }}
                            src={`${path}${record.attachment}`}
                            alt="Attachment"
                            onClick={() =>
                              window.open(
                                `${path}${record.attachment}`,
                                "_blank"
                              )
                            }
                          />
                        </div>
                      )
                    }
                  />
                </Grid>
                <Grid item xs={6}>
                  {process.env.REACT_APP_APP_NAME === "Mediply" ? (
                    <DateInput
                      source="dob"
                      label="resources.root.startingDate"
                      className={classes.input}
                      validate={[required()]}
                    />
                  ) : (
                    <DateInput
                      source="dob"
                      label="resources.root.dob"
                      className={classes.input}
                      validate={[required()]}
                    />
                  )}
                  <TextInput
                    source="country"
                    label="resources.root.country"
                    className={classes.input}
                  />
                  <TextInput
                    source="city"
                    label="resources.root.city"
                    className={classes.input}
                  />
                  <TextInput
                    source="nationality"
                    label="resources.root.nationality"
                    className={classes.input}
                    validate={[required()]}
                  />
                  <TextInput
                    source="currentWork"
                    label="resources.root.currentWork"
                    className={classes.input}
                  />
                  <TextInput
                    source="note"
                    label="resources.root.note"
                    className={classes.input}
                  />
                  <TextInput
                    source="idCardNumber"
                    label="resources.root.idCardNumber"
                    className={classes.input}
                  />
                  <TextInput
                    source="idCardType"
                    label="resources.root.idCardType"
                    className={classes.input}
                  />
                  <FormDataConsumer>
                    {({ formData, ...rest }) => {
                      setSeparatedNumber(
                        formData.payLoanByMain
                          ? parseFloat(formData.mcRoofLoan)
                          : parseFloat(formData.scRoofLoan)
                      );
                      return formData.payLoanByMain ? (
                        <NumberInput
                          validate={[
                            required(),
                            number(),
                            minValue(formData.mcCurrentLoan),
                          ]}
                          min={formData.mcCurrentLoan}
                          source="mcRoofLoan"
                          label="resources.root.mcRoofLoan"
                          className={classes.input}
                          onChange={(e) =>
                            setSeparatedNumber(parseFloat(e.target.value) || 0)
                          }
                          helperText={
                            separatedNumber > 0
                              ? `$${separatedNumber?.toLocaleString()}`
                              : ""
                          }
                        />
                      ) : (
                        <NumberInput
                          validate={[
                            required(),
                            number(),
                            minValue(formData.scCurrentLoan),
                          ]}
                          min={formData.scCurrentLoan}
                          source="scRoofLoan"
                          label="resources.root.scRoofLoan"
                          className={classes.input}
                          onChange={(e) =>
                            setSeparatedNumber(parseFloat(e.target.value) || 0)
                          }
                          helperText={
                            separatedNumber > 0
                              ? `${separatedNumber?.toLocaleString()} ${translate(
                                  "resources.root.iqd"
                                )}`
                              : ""
                          }
                        />
                      );
                    }}
                  </FormDataConsumer>
                </Grid>
              </Grid>
            </div>
          </div>
        </FormTab>
        <FormTab label="resources.root.store">
          <div className={classes.addStoreSection}>
            <Autocomplete
              id="combo-box-demo"
              options={stores}
              onChange={(e, val) => (val ? setStoreId(val.id) : setStoreId(0))}
              getOptionLabel={(option) => option.title}
              style={{ width: 300 }}
              renderInput={(params) => (
                <MaterialTextField
                  {...params}
                  label={translate("resources.root.store")}
                  variant="outlined"
                />
              )}
            />
            {customer.payLoanByMain && (
              <Input
                type={"number"}
                variant="outlined"
                label={"resources.root.currentLoan"}
                defaultValue={currentLoan}
                onChange={(e) => setCurrentLoan(e.target.value)}
              />
            )}
            {!customer.payLoanByMain && (
              <Input
                type={"number"}
                variant="outlined"
                label={"resources.root.currentLoan"}
                defaultValue={currentLoan}
                onChange={(e) => setCurrentLoan(e.target.value)}
              />
            )}
            <MaterialButton
              variant="outlined"
              disabled={!storeId}
              onClick={() => {
                HTTP.post(`${url}/StoreCustomers`, {
                  customerId: id,
                  storeId: storeId,
                  byMain: customer.payLoanByMain,
                  mcOpenAccountLoan: currentLoan,
                  scOpenAccountLoan: currentLoan,
                })
                  .then((res) => {
                    HTTP.get(`${url}/Customers/${id}`);
                    res.data.storeCustomers &&
                      setStoreCustomers(res.data.storeCustomers);
                    notify("resources.root.dollarPriceGetSuccess", {
                      type: "success",
                    });
                  })
                  .catch((err) => handleRequestResponse(notify, err));
                setStoreId(0);
              }}
            >
              {translate("resources.root.add")}
            </MaterialButton>
          </div>
          <TableContainer component={Paper} className={classes.table}>
            <Table aria-label="simple table">
              <TableHead className={classes.TableHead}>
                <TableRow>
                  <TableCell className={classes.TableHeadCell}>
                    {translate("resources.root.title")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.description")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.openAccountLoan")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.currentLoan")}
                  </TableCell>
                  <TableCell align="right" className={classes.TableHeadCell}>
                    {translate("resources.root.action")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {storeCustomers &&
                  storeCustomers.map((row) => (
                    <TableRow key={row.store.id}>
                      <TableCell>{row.store.title}</TableCell>
                      <TableCell align="right">
                        {row.store.description}
                      </TableCell>
                      <TableCell align="right">
                        {customer.payLoanByMain
                          ? row.mcOpenAccountLoan?.toLocaleString()
                          : row.scOpenAccountLoan?.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        {customer.payLoanByMain
                          ? row.mcCurrentLoan?.toLocaleString()
                          : row.scCurrentLoan?.toLocaleString()}
                      </TableCell>
                      <TableCell align="right">
                        <DeleteIcon
                          onClick={() => {
                            swal({
                              title: translate("resources.root.swalTitle"),
                              text: translate("resources.root.swalText"),
                              icon: "warning",
                              buttons: true,
                              dangerMode: true,
                            }).then((willDelete) => {
                              if (willDelete) {
                                HTTP.delete(`${url}/StoreCustomers/${row.id}`)
                                  .then((res) => {
                                    HTTP.get(`${url}/Customers/${id}`).then(
                                      (res) => {
                                        res.data.storeCustomers &&
                                          setStoreCustomers(
                                            res.data.storeCustomers
                                          );
                                        swal(
                                          translate(
                                            "resources.root.swalDeleted"
                                          ),
                                          {
                                            icon: "success",
                                          }
                                        );
                                      }
                                    );
                                  })
                                  .catch((err) =>
                                    notify(err.response.data.message, {
                                      type: "error",
                                      undoable: true,
                                    })
                                  )
                                  .finally((res) => {
                                    HTTP.get(`${url}/Customers/${id}`).then(
                                      (res) => {
                                        res.data.storeCustomers &&
                                          setStoreCustomers(
                                            res.data.storeCustomers
                                          );
                                      }
                                    );
                                  });
                              } else {
                                swal(
                                  translate(
                                    "resources.root.swalDeleteCancelled"
                                  )
                                );
                              }
                            });
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export const CustomersShow = (props) => {
  const role = localStorage.getItem("role") ?? "NoUser";
  const translate = useTranslate();
  const refresh = useRefresh();
  const { id } = useParams();
  const notify = useNotify();
  const classes = useStyles();
  const [visits, setVisits] = React.useState([]);
  const [visitsLabTests, setVisitsLabTests] = React.useState([]);
  const [visitsMedItems, setVisitsMedItems] = React.useState([]);
  const [visitsProducts, setVisitsProducts] = React.useState([]);
  const [visitsXRays, setVisitsXRays] = React.useState([]);

  const [keyToAdd, setKeyToAdd] = React.useState(null);
  const [valueToAdd, setValueToAdd] = React.useState(null);

  const [titleAttachment, setTitleAttachment] = React.useState(null);
  const [attachmentNote, setAttachmentNote] = React.useState(null);
  const [attachment, setAttachment] = React.useState(null);

  let valueToUpdate = "";
  let customerKeyValue = {
    basePath: `"/CustomerKeyValues"`,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    hasDelete: true,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "CustomerKeyValues",
    exporter: true,
  };

  let custoemrAttachments = {
    basePath: `"/CustomerAttachments"`,
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    hasDelete: true,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "CustomerAttachments",
    exporter: true,
  };

  const updateSellPrice = (e, key, id) => {
    HTTP.put(`${url}/CustomerKeyValues/${id}`, {
      id: id,
      key: key,
      value: valueToUpdate,
    })
      .then((res) => {
        notify("Successfully Updated", { type: "success" });
        refresh();
      })
      .catch((err) => {
        notify(err.response.data.message, { type: "error", undoable: true });
      });
  };
  const AddNewKeyValue = () => {
    HTTP.post(`${url}/CustomerKeyValues`, {
      key: keyToAdd,
      value: valueToAdd,
      referenceId: id,
    })
      .then((res) => {
        notify("Successfully Added", { type: "success" });
        refresh();
        setKeyToAdd(null);
        setValueToAdd(null);
      })
      .catch((err) => {
        notify(err.response.data.message, { type: "error", undoable: true });
      });
  };

  const AddNewAttachment = () => {
    HTTP.post(`${url}/CustomerAttachments`, {
      title: titleAttachment,
      note: attachmentNote,
      attachment: attachment,
      referenceId: id,
    })
      .then((res) => {
        notify("Successfully Added", { type: "success" });
        refresh();
        setTitleAttachment(null);
        setAttachmentNote(null);
        setAttachment(null);
      })
      .catch((err) => {
        notify(err.response.data.message, { type: "error", undoable: true });
      });
  };
  React.useEffect(() => {
    if (process.env.REACT_APP_APP_NAME === "Hana") {
      HTTP.get(`/CustomerVisits?subLevelId=${id}`)
        .then((response) => {
          setVisits(response.data);
        })
        .catch((err) => handleRequestResponse(notify, err));
      HTTP.get(`/CustomerVisitLabTests/${id}?forCustomer=${true}`)
        .then((response) => {
          setVisitsLabTests(response.data);
        })
        .catch((err) => handleRequestResponse(notify, err));
      HTTP.get(`/CustomerVisitMedItems/${id}?forCustomer=${true}`)
        .then((response) => {
          setVisitsMedItems(response.data);
        })
        .catch((err) => handleRequestResponse(notify, err));
      HTTP.get(`/CustomerVisitProducts/${id}?forCustomer=${true}`)
        .then((response) => {
          setVisitsProducts(response.data);
        })
        .catch((err) => handleRequestResponse(notify, err));
      HTTP.get(`/CustomerVisitXRays/${id}?forCustomer=${true}`)
        .then((response) => {
          setVisitsXRays(response.data);
        })
        .catch((err) => handleRequestResponse(notify, err));
    }
  }, []);
  return (
    <Show transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}>
      <TabbedShowLayout>
        <Tab label="resources.root.info">
          <SimpleShowLayout>
            <FunctionField
              label="resources.root.customerName"
              render={(record) =>
                `${record.firstName} ${record.middleName} ${record.lastName}`
              }
            />
            <BooleanField source="male" label="resources.root.gender" />

            {process.env.REACT_APP_APP_NAME === "Mediply" ? (
              <DateField source="dob" label="resources.root.startingDate" />
            ) : (
              <DateField source="dob" label="resources.root.dob" />
            )}

            <TextField source="email" label="resources.root.email" />
            {(role.toLowerCase() === "admin" ||
              role.toLowerCase() === "accountant" ||
              role.toLowerCase() === "superadmin") && (
              <>
                <TextField source="phone" label="resources.root.phone" />
                <TextField source="phone2" label="resources.root.phone2" />
              </>
            )}
            <TextField source="country" label="resources.root.country" />
            <TextField source="city" label="resources.root.city" />
            <TextField source="address" label="resources.root.address" />
            <TextField source="note" label="resources.root.note" />
            <TextField
              source="nationality"
              label="resources.root.nationality"
            />
            <FunctionField
              label="resources.root.currentLoan"
              render={(record) =>
                record.payLoanByMain
                  ? record.mcCurrentLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : record.scCurrentLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")
              }
            />
            <FunctionField
              label="resources.root.roofLoan"
              render={(record) =>
                record.payLoanByMain
                  ? record.mcRoofLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : record.scRoofLoan?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")
              }
            />
            <FunctionField
              label="resources.root.openAccountLoan"
              render={(record) =>
                record.payLoanByMain
                  ? `${record.mcOpenAccountLoan?.toLocaleString()} ${translate(
                      "resources.root.usd"
                    )}`
                  : `${record.scOpenAccountLoan?.toLocaleString()} ${translate(
                      "resources.root.iqd"
                    )}`
              }
            />

            <FunctionField
              label="resources.root.attachment"
              render={(record) =>
                record.attachment !== null && (
                  <div style={{ position: "relative" }}>
                    <img
                      style={{
                        width: 200,
                        height: 200,
                        objectFit: "scale-down",
                        cursor: "pointer",
                      }}
                      src={`${path}${record.attachment}`}
                      alt="Attachment"
                      onClick={() =>
                        window.open(`${path}${record.attachment}`, "_blank")
                      }
                    />
                  </div>
                )
              }
            />
            <ArrayField source="storeCustomers" label="resources.root.stores">
              <Datagrid>
                <TextField source="store.title" label="resources.root.store" />
                <FunctionField
                  label="resources.root.currentLoan"
                  render={(record) =>
                    record.byMain
                      ? record.mcCurrentLoan?.toLocaleString() +
                        " " +
                        translate("resources.root.usd")
                      : record.scCurrentLoan?.toLocaleString() +
                        " " +
                        translate("resources.root.iqd")
                  }
                />
                <FunctionField
                  label="resources.root.openAccountLoan"
                  render={(record) =>
                    record.byMain
                      ? record.mcOpenAccountLoan?.toLocaleString() +
                        " " +
                        translate("resources.root.usd")
                      : record.scOpenAccountLoan?.toLocaleString() +
                        " " +
                        translate("resources.root.iqd")
                  }
                />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Tab>
        {process.env.REACT_APP_APP_NAME === "Hana" &&
          hasPermission([permissions.customerVisit]) && (
            <Tab label="resources.root.visits">
              <div className="table100 ver2 m-b-110">
                <table data-vertable="ver2">
                  <thead>
                    <tr className="row100 head">
                      <th className="column100 column2">
                        {translate("resources.root.id")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.doctor")}
                      </th>
                      {(role.toLowerCase() === "admin" ||
                        role.toLowerCase() === "accountant" ||
                        role.toLowerCase() === "superadmin") && (
                        <th className="column100 column4">
                          {translate("resources.root.price")}
                        </th>
                      )}
                      <th className="column100 column5">
                        {translate("resources.root.date")}
                      </th>
                      <th className="column100 column6">
                        {translate("resources.root.note")}
                      </th>
                      <th className="column100 column7">
                        {translate("resources.root.type")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visits.map((visit) => {
                      return (
                        <tr className="row100">
                          <td className="column100 column1">
                            <a
                              href={`/#/CustomerVisits/${visit.id}/show`}
                              target="_blank"
                            >
                              {visit.id}
                            </a>
                          </td>
                          <td className="column100 column2">
                            {visit.doctorName}
                          </td>
                          {(role.toLowerCase() === "admin" ||
                            role.toLowerCase() === "accountant" ||
                            role.toLowerCase() === "superadmin") && (
                            <td className="column100 column3">
                              {`${visit.calculatedTotalPrice?.toLocaleString()} IQD`}
                            </td>
                          )}
                          <td className="column100 column4">
                            {moment(visit.date).format("YYYY-MM-DD -- HH:MM")}
                          </td>
                          <td className="column100 column5">{visit.note}</td>
                          <td className="column100 column6">
                            {visit.feature1
                              ? translate("resources.root.miner")
                              : translate("resources.root.regular")}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          )}{" "}
        {process.env.REACT_APP_APP_NAME === "Hana" &&
          hasPermission([permissions.customerVisitLabTests]) && (
            <Tab label="resources.root.labTest">
              <div className="table100 ver2 m-b-110">
                <table data-vertable="ver2">
                  <thead>
                    <tr className="row100 head">
                      <th className="column100 column2">
                        {translate("resources.root.id")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.name")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.category")}
                      </th>

                      <th className="column100 column5">
                        {translate("resources.root.date")}
                      </th>
                      <th className="column100 column6">
                        {translate("resources.root.note")}
                      </th>
                      <th className="column100 column7">
                        {translate("resources.root.result")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitsLabTests.map((visit) => {
                      return (
                        <tr className="row100">
                          <td className="column100 column1">
                            <a
                              href={`/#/CustomerVisits/${visit.customerVisitId}/show`}
                              target="_blank"
                            >
                              {visit.customerVisitId}
                            </a>
                          </td>
                          <td className="column100 column2">
                            {visit.labTestName}
                          </td>
                          <td className="column100 column2">
                            {visit.labTestCategoryName}
                          </td>

                          <td className="column100 column4">
                            {moment(visit.date).format("YYYY-MM-DD -- HH:MM")}
                          </td>
                          <td className="column100 column5">{visit.note}</td>
                          <td className="column100 column6">{visit.result}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          )}{" "}
        {process.env.REACT_APP_APP_NAME === "Hana" &&
          hasPermission([permissions.customerVisitMedItems]) && (
            <Tab label="resources.root.medItems">
              <div className="table100 ver2 m-b-110">
                <table data-vertable="ver2">
                  <thead>
                    <tr className="row100 head">
                      <th className="column100 column2">
                        {translate("resources.root.id")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.name")}
                      </th>

                      <th className="column100 column5">
                        {translate("resources.root.date")}
                      </th>
                      <th className="column100 column6">
                        {translate("resources.root.note")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitsMedItems.map((visit) => {
                      return (
                        <tr className="row100">
                          <td className="column100 column1">
                            <a
                              href={`/#/CustomerVisits/${visit.customerVisitId}/show`}
                              target="_blank"
                            >
                              {visit.customerVisitId}
                            </a>
                          </td>
                          <td className="column100 column2">
                            {visit.medItemName}
                          </td>

                          <td className="column100 column4">
                            {moment(visit.date).format("YYYY-MM-DD -- HH:MM")}
                          </td>
                          <td className="column100 column5">{visit.note}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          )}
        {process.env.REACT_APP_APP_NAME === "Hana" &&
          hasPermission([permissions.customerVisitXRays]) && (
            <Tab label="resources.root.XRays">
              <div className="table100 ver2 m-b-110">
                <table data-vertable="ver2">
                  <thead>
                    <tr className="row100 head">
                      <th className="column100 column1">
                        {translate("resources.root.id")}
                      </th>
                      <th className="column100 column2">
                        {translate("resources.root.name")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.date")}
                      </th>
                      <th className="column100 column4">
                        {translate("resources.root.note")}
                      </th>
                      <th className="column100 column5">
                        {translate("resources.root.findings")}
                      </th>
                      <th className="column100 column6">
                        {translate("resources.root.impression")}
                      </th>
                      <th className="column100 column7">
                        {translate("resources.root.tags")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitsXRays.map((visit) => {
                      return (
                        <tr className="row100">
                          <td className="column100 column1">
                            <a
                              href={`/#/CustomerVisits/${visit.customerVisitId}/show`}
                              target="_blank"
                            >
                              {visit.customerVisitId}
                            </a>
                          </td>
                          <td className="column100 column2">
                            {visit.xRayName}
                          </td>

                          <td className="column100 column4">
                            {moment(visit.date).format("YYYY-MM-DD -- HH:MM")}
                          </td>
                          <td className="column100 column5">{visit.note}</td>
                          <td className="column100 column5">
                            {visit.findings}
                          </td>
                          <td className="column100 column5">
                            {visit.impression}
                          </td>
                          <td className="column100 column5">{visit.tags}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          )}
        {process.env.REACT_APP_APP_NAME === "Hana" &&
          hasPermission([permissions.customerVisitProducts]) && (
            <Tab label="resources.root.products">
              <div className="table100 ver2 m-b-110">
                <table data-vertable="ver2">
                  <thead>
                    <tr className="row100 head">
                      <th className="column100 column1">
                        {translate("resources.root.id")}
                      </th>
                      <th className="column100 column2">
                        {translate("resources.root.name")}
                      </th>
                      <th className="column100 column3">
                        {translate("resources.root.date")}
                      </th>
                      <th className="column100 column4">
                        {translate("resources.root.note")}
                      </th>
                      <th className="column100 column5">
                        {translate("resources.root.findings")}
                      </th>
                      <th className="column100 column6">
                        {translate("resources.root.impression")}
                      </th>
                      <th className="column100 column7">
                        {translate("resources.root.tags")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {visitsProducts.map((visit) => {
                      return (
                        <tr className="row100">
                          <td className="column100 column1">
                            <a
                              href={`/#/CustomerVisits/${visit.customerVisitId}/show`}
                              target="_blank"
                            >
                              {visit.customerVisitId}
                            </a>
                          </td>
                          <td className="column100 column2">
                            {visit.xRayName}
                          </td>

                          <td className="column100 column4">
                            {moment(visit.date).format("YYYY-MM-DD -- HH:MM")}
                          </td>
                          <td className="column100 column5">{visit.note}</td>
                          <td className="column100 column5">
                            {visit.findings}
                          </td>
                          <td className="column100 column5">
                            {visit.impression}
                          </td>
                          <td className="column100 column5">{visit.tags}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </Tab>
          )}
        {process.env.REACT_APP_APP_NAME === "Hana" && (
          <Tab label={translate("resources.root.details")}>
            <div>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  Add
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container className={classes.container} spacing={2}>
                    <Grid xs={5} className="px-3">
                      <MaterialTextField
                        className={classes.input}
                        id="text-key"
                        label={translate("resources.root.name")}
                        fullWidth
                        variant="filled"
                        value={keyToAdd}
                        onChange={(e) => setKeyToAdd(e.target.value)}
                      />
                    </Grid>
                    <Grid xs={5} className="px-3">
                      <MaterialTextField
                        className={classes.input}
                        id="text-value"
                        label={translate("resources.root.value")}
                        fullWidth
                        variant="filled"
                        value={valueToAdd}
                        onChange={(e) => setValueToAdd(e.target.value)}
                      />
                    </Grid>
                    <Grid xs={2} className="px-3">
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={AddNewKeyValue}
                      >
                        {translate("resources.root.add")}
                      </Button>
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <List
                filters={<SearchFilter />}
                {...customerKeyValue}
                filter={{ subLevelId: id }}
                sort={{ field: "id", order: "DESC" }}
              >
                <Datagrid>
                  <TextField source="id" label="resources.root.id" />
                  <TextField source="key" label="resources.root.name" />
                  {/* <TextField source="value" label="resources.root.value" /> */}
                  <FunctionField
                    label={translate("resources.root.value")}
                    render={(record) => (
                      <div>
                        <FormControl
                          sx={{ m: 1, width: "25ch" }}
                          variant="filled"
                        >
                          <InputLabel htmlFor="filled-adornment-password">
                            {translate("resources.root.value")}
                          </InputLabel>
                          <FilledInput
                            id="filled-adornment-password"
                            type="text"
                            defaultValue={record.value}
                            onChange={(e) => (valueToUpdate = e.target.value)}
                            endAdornment={
                              <InputAdornment position="end">
                                <Tooltip
                                  title={translate("resources.root.update")}
                                >
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={(e) =>
                                      updateSellPrice(e, record.key, record.id)
                                    }
                                    edge="end"
                                  >
                                    <UpdateIcon />
                                  </IconButton>
                                </Tooltip>
                              </InputAdornment>
                            }
                          />
                        </FormControl>
                      </div>

                      // <MaterialTextField
                      //   label={translate("resources.root.value")}
                      //   defaultValue={`${record.value}`}
                      //   onBlur={(event) =>
                      //     updateSellPrice(event, record.id, false)
                      //   }
                      // />
                    )}
                  />
                </Datagrid>
              </List>
            </div>
          </Tab>
        )}{" "}
        {process.env.REACT_APP_APP_NAME === "Hana" && (
          <Tab label={translate("resources.root.attachments")}>
            <AttachmentTab id={id} endPoint="CustomerAttachments" />
            {/* <div>
              <Accordion defaultExpanded>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  {translate("resources.root.addNewAttachment")}
                </AccordionSummary>
                <AccordionDetails>
                  <div className="grid-attachment-customer-show">
                    <div className="">
                      <MaterialTextField
                        className={classes.input}
                        id="text-key"
                        label={translate("resources.root.title")}
                        fullWidth
                        variant="filled"
                        value={titleAttachment}
                        onChange={(e) => setTitleAttachment(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <MaterialTextField
                        className={classes.input}
                        id="text-value"
                        label={translate("resources.root.note")}
                        fullWidth
                        variant="filled"
                        value={attachmentNote}
                        onChange={(e) => setAttachmentNote(e.target.value)}
                      />
                    </div>
                    <div className="">
                      <Button
                        className="imageButton btn-block d-flex justify-content-center align-items-center gap-2"
                        color="primary"
                        variant="contained"
                        component="span"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-upload mx-1"
                          viewBox="0 0 16 16"
                        >
                          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
                          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
                        </svg>
                        {translate("resources.root.uploadImg")}
                        <input
                          className=""
                          type="file"
                          accept="image/*"
                          onChange={async (e) => {
                            const file = e.target.files[0];
                            const base64 = await getBase64(file);

                            setAttachment(base64);
                          }}
                        />
                      </Button>
                    </div>{" "}
                    <div className="">
                      <Button
                        variant="contained"
                        fullWidth
                        onClick={AddNewAttachment}
                      >
                        {translate("resources.root.add")}
                      </Button>
                    </div>
                  </div>
                  {attachment && (
                    <div className="my-4 w-50 mx-auto">
                      <img
                        className="w-100"
                        src={attachment}
                        style={{
                          objectFit: "scale-down",
                          cursor: "pointer",
                          borderRadius: "1rem",
                        }}
                      />
                    </div>
                  )}
                </AccordionDetails>
              </Accordion>
              <List
                {...custoemrAttachments}
                filter={{ subLevelId: id }}
                sort={{ field: "id", order: "DESC" }}
              >
                <Datagrid>
                  <TextField source="id" label="resources.root.id" />
                  <TextField source="title" label="resources.root.title" />
                  <TextField source="note" label="resources.root.note" />
                  <FunctionField
                    label={`resources.root.image`}
                    render={(record) => (
                      <img
                        style={{
                          width: 75,
                          height: 75,
                          objectFit: "scale-down",
                          cursor: "pointer",
                        }}
                        src={
                          record.attachment !== null
                            ? `${path}${record.attachment}`
                            : `https://via.placeholder.com/75`
                        }
                        alt={record.name}
                        onClick={() =>
                          window.open(
                            record.attachment !== null
                              ? `${path}${record.attachment}`
                              : `https://via.placeholder.com/75`,
                            "_blank"
                          )
                        }
                      />
                    )}
                  />
                </Datagrid>
              </List>
            </div> */}
          </Tab>
        )}
        {/* <Tab label="resources.root.attachments"></Tab>
        <Tab label="resources.root.moreInfo"></Tab> */}
      </TabbedShowLayout>
    </Show>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    textAlign: "start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 800,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 800,
  },
});
