import React from "react";
import PrintProvider, { Print } from "react-easy-print";
import Page from "./SellReceipt/Page";
import { makeStyles } from "@mui/styles";
import { useResourceDefinition, useTranslate } from "react-admin";
import { HTTP } from "../../axios";
import { url, handleRequestResponse } from "../../request";
import swal from "sweetalert";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import moment from "moment";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { toCsv } from "../CustomComponents/Helpers";
import { FaPrint } from "react-icons/fa";
import { Button, Col, Form, Row, Table, InputGroup } from "react-bootstrap";
import { FormControlLabel, Switch } from "@mui/material";
import AsyncSelect from "react-select/async/dist/react-select.esm";
import { NoPrint } from "react-easy-print/lib/es";
import loadOptions from "../../loadOptions";

export const PrintInvoiceOrders = (props) => {
  const params = useParams();
  const queryString = require("query-string");
  const type = params.type;
  const { search } = useLocation();
  const translate = useTranslate();
  const classes = useStyles();
  const [products, setProducts] = React.useState([]);
  const [totalQty, setTotalQty] = React.useState(0);
  const [totalSmallQty, setTotalSmallQty] = React.useState(0);
  const [mcTotalPrice, setMcTotalPrice] = React.useState(0);
  const [scTotalPrice, setScTotalPrice] = React.useState(0);
  const [mcTotalDiscount, setMcTotalDiscount] = React.useState(0);
  const [scTotalDiscount, setScTotalDiscount] = React.useState(0);
  const [groupItems, setGroupItems] = React.useState(false);
  const [showDate, setShowDate] = React.useState(false);
  const [showTotalPrice, setShowTotalPrice] = React.useState(false);
  const [showInvoiceId, setShowInvoiceId] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [category, setCategory] = React.useState(0);
  const [paramsSearch, setParamsSearch] = useSearchParams();

  async function getData() {
    let $category = "";
    if (category !== 0) {
      $category = `&subLevelId=${category}`;
    }

    let normalQuery = `${url}/Utilities/GetBatchOrders?_end=1000&groupProducts=${groupItems}${$category}&Type=${type}`;
    let otherProductQuery = `${url}/StoreInventoryInvoice/GetOtherProducts`;

    let ids = queryString
      .parse(search, { arrayFormat: "bracket" })
      ["ids"].map((x) => parseInt(x));
    await HTTP.post(
      paramsSearch.get("isOtherProduct") === "true"
        ? otherProductQuery
        : normalQuery,
      ids
    )
      .then((res) => {
        setProducts(res.data);
        setTotalQty(res.headers["totalqty"]);
        setTotalSmallQty(res.headers["totalsmallqty"]);

        setMcTotalPrice(res.headers["mctotalprice"]);
        setScTotalPrice(res.headers["sctotalprice"]);
        setMcTotalDiscount(res.headers["mcdiscount"]);
        setScTotalDiscount(res.headers["scdiscount"]);
      })
      .catch((err) => {
        if (err) {
          swal("Oh noes!", "The AJAX request failed!", "error");
        } else {
          swal.stopLoading();
          swal.close();
        }
      })
      .finally(() => {
        let sidebar = document.getElementsByClassName("MuiDrawer-root");
        let appMenu = document.getElementsByClassName("MuiPaper-root");
        let appBar = document.getElementsByClassName("theRoot");
        let sidebar2 = document.getElementsByClassName("RaSidebar-fixed");
        sidebar[0].style.display = "none";
        appMenu[0].style.display = "none";
        appBar[0].style.marginTop = "0px";
        sidebar2[0].style.display = "none";
        setIsLoading(false);
      });
  }

  React.useEffect(() => {
    setIsLoading(true);
    getData();
  }, [groupItems]);

  return (
    <PrintProvider>
      {" "}
      <Print single name="foo">
        {paramsSearch.get("isOtherProduct") !== "true" && (
          <NoPrint force>
            <Row className="p-4 justify-content-center align-items-center">
              <Col className="col-4 d-flex align-items-center justify-content-start px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.category")} :
                </p>
                <AsyncSelect
                  isClearable
                  defaultOptions
                  className="w-100"
                  loadOptions={loadOptions.categories}
                  onChange={(e) => setCategory(e ? e.value : 0)}
                />
              </Col>
              <Col className="col-md-2 py-2 ">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setGroupItems(e.currentTarget.checked)}
                        defaultChecked={groupItems}
                      />
                    }
                    label={translate("resources.root.groupItems")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-md-2 py-2 ">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setShowDate(e.currentTarget.checked)}
                        defaultChecked={showDate}
                      />
                    }
                    label={translate("resources.root.showDate")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-md-2 py-2 ">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) =>
                          setShowTotalPrice(e.currentTarget.checked)
                        }
                        defaultChecked={showTotalPrice}
                      />
                    }
                    label={translate("resources.root.showTotal")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-md-2 py-2 ">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) =>
                          setShowInvoiceId(e.currentTarget.checked)
                        }
                        defaultChecked={showInvoiceId}
                      />
                    }
                    label={translate("resources.root.showInvoice")}
                  />
                </InputGroup>
              </Col>
            </Row>
          </NoPrint>
        )}
        <Print single name="foo">
          <div className="d-flex justify-content-center gap-4 align-items-center mt-4">
            {showDate && (
              <h6>
                {translate("resources.root.date")}:{" "}
                {new Date().toISOString().slice(0, 10)}
              </h6>
            )}
            {showTotalPrice && (
              <h6>
                {translate("resources.root.totalQty")}:{" "}
                {totalQty?.toLocaleString()} {" + "}
                {totalSmallQty?.toLocaleString()}
              </h6>
            )}
            {showTotalPrice && (
              <h6>
                {translate("resources.root.totalPrice")}:{" "}
                {products && products[0] && products[0].byMain
                  ? parseFloat(mcTotalPrice)?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : parseFloat(scTotalPrice)?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")}
              </h6>
            )}
            {showTotalPrice && (
              <h6>
                {translate("resources.root.discount")}:{" "}
                {products && products[0] && products[0].byMain
                  ? mcTotalDiscount?.toLocaleString() +
                    " " +
                    translate("resources.root.usd")
                  : scTotalDiscount?.toLocaleString() +
                    " " +
                    translate("resources.root.iqd")}
              </h6>
            )}
          </div>
        </Print>
        <NoPrint force>
          <Row className="p-4 justify-content-center align-items-center">
            <Col className="col-4 mt-4">
              <Button
                id="export"
                variant={"primary btn-block"}
                disabled={products.length === 0}
                onClick={() => {
                  toCsv(
                    document.getElementById("exportableTable"),
                    "download.csv"
                  );
                }}
              >
                {translate("resources.root.exportCsv")}
              </Button>
            </Col>
            <Col className="col-4 mt-4">
              <Button
                variant={"outline-primary btn-block"}
                disabled={products.length === 0}
                onClick={() => {
                  let sidebar =
                    document.getElementsByClassName("MuiDrawer-root");
                  let appMenu =
                    document.getElementsByClassName("MuiPaper-root");
                  let appBar = document.getElementsByClassName("theRoot");
                  let table = document.getElementsByTagName("table");
                  let sidBarStyle = sidebar[0].style.display;
                  let MenuStyle = appMenu[0].style.display;
                  let appBarMargin = appBar[0].style.marginTop;
                  sidebar[0].style.display = "none";
                  appMenu[0].style.display = "none";
                  appBar[0].style.marginTop = "0px";
                  table[0].style.fontSize = "12px";

                  window.print();

                  sidebar[0].style.display = sidBarStyle;
                  appMenu[0].style.display = MenuStyle;
                  appBar[0].style.marginTop = appBarMargin;
                  table[0].style.fontSize = "15px";
                }}
              >
                <FaPrint /> {translate("resources.root.print")}{" "}
              </Button>
            </Col>
            <Col className="col-4 mt-4">
              <Button
                variant={"primary btn-block"}
                onClick={() => {
                  setIsLoading(true);
                  getData();
                }}
              >
                {translate(
                  `resources.root.${isLoading ? "isLoading" : "search"}`
                )}
              </Button>
            </Col>
          </Row>{" "}
        </NoPrint>
        <div className={`${classes.printWrapper} p-4`}>
          <Row>
            {isLoading && <LoadingScreen />}
            {!isLoading && (
              <Col xs={12}>
                {paramsSearch.get("isOtherProduct") === "true" ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{translate("resources.root.id")}</th>
                        <th>{translate("resources.root.name")}</th>
                        <th>{translate("resources.root.barcode")}</th>
                        <th>{translate("resources.root.quantity")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {products &&
                        products.map((one, index) => {
                          return (
                            <tr>
                              <td>{++index}</td>
                              <td>{one?.id}</td>
                              <td>{one.name}</td>
                              <td>{one.barcode}</td>

                              <td>
                                {one.qteInStock} + {one.smallMeasureQte}
                              </td>
                              {/* <td>
                              {one.byMain
                                ? one.mcActualPrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.usd")
                                : one.scActualPrice?.toLocaleString() +
                                  " " +
                                  translate("resources.root.iqd")}
                            </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ) : process.env.REACT_APP_APP_NAME === "Bahez" ||
                  process.env.REACT_APP_APP_NAME === "Safeen" ? (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{translate("resources.root.id")}</th>
                        <th>{translate("resources.root.name")}</th>
                        <th>{translate("resources.root.barcode")}</th>
                        {showDate && (
                          <th>{translate("resources.root.date")}</th>
                        )}
                        <th>{translate("resources.root.quantity")}</th>
                        {type === "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.newQty")}</th>
                        )}
                        {type !== "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.price")}</th>
                        )}
                        {/*{type === 'StoreInventoryInvoice' && <th>{translate('resources.root.newSmallMeasureQty')}</th>}*/}
                        {/* {type !== "StoreInventoryInvoice" && (
                        <th>{translate("resources.root.giftQuantity")}</th>
                      )} */}
                        {type !== "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.driver")}</th>
                        )}
                        {showTotalPrice && (
                          <th>{translate("resources.root.total")}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {products &&
                        products.map((one, index) => {
                          return (
                            <tr>
                              <td>{++index}</td>
                              <td>{showInvoiceId ? one.invoiceId : one?.id}</td>
                              <td>{one.name}</td>
                              <td>{one.barcode}</td>
                              {showDate && (
                                <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                              )}
                              <td>
                                {one.qty} + {one.smallMeasureQty}
                              </td>
                              {type === "StoreInventoryInvoice" && (
                                <td>{one.newQty}</td>
                              )}
                              {type !== "StoreInventoryInvoice" && (
                                <td>
                                  {one.byMain
                                    ? one.mcAveragePrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.usd")
                                    : one.scAveragePrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.iqd")}
                                </td>
                              )}
                              {/*{type === 'StoreInventoryInvoice' && <td>{one.newSmallQty}</td>}*/}
                              {/* {type !== "StoreInventoryInvoice" && (
                              <td>{one.giftQty}</td>
                            )} */}
                              {type !== "StoreInventoryInvoice" && (
                                <td>{one.driver}</td>
                              )}
                              {showTotalPrice && (
                                <td>
                                  {one.byMain
                                    ? one.mcTotalPrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.usd")
                                    : one.scTotalPrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.iqd")}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                ) : (
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{translate("resources.root.id")}</th>
                        <th>{translate("resources.root.name")}</th>
                        <th>{translate("resources.root.barcode")}</th>
                        {showDate && (
                          <th>{translate("resources.root.date")}</th>
                        )}
                        <th>{translate("resources.root.quantity")}</th>
                        {type === "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.newQty")}</th>
                        )}
                        {/* 5 */}
                        {type !== "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.price")}</th>
                        )}
                        {type !== "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.sellPrice")}</th>
                        )}
                        {/*{type === 'StoreInventoryInvoice' && <th>{translate('resources.root.newSmallMeasureQty')}</th>}*/}

                        {type !== "StoreInventoryInvoice" && (
                          <th>{translate("resources.root.totalPrice")}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {products &&
                        products.map((one, index) => {
                          return (
                            <tr>
                              <td>{++index}</td>
                              <td>{showInvoiceId ? one.invoiceId : one?.id}</td>
                              <td>{one.name}</td>
                              <td>{one.barcode}</td>
                              {showDate && (
                                <td>{moment(one.date).format("YYYY-MM-DD")}</td>
                              )}
                              <td>
                                {one.qty} + {one.smallMeasureQty}
                              </td>
                              {type === "StoreInventoryInvoice" && (
                                <td>{one.newQty}</td>
                              )}
                              {/* 5 */}
                              {type !== "StoreInventoryInvoice" && (
                                <td>
                                  {one.byMain
                                    ? one.mcActualPrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.usd")
                                    : one.scActualPrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.iqd")}
                                </td>
                              )}
                              {type !== "StoreInventoryInvoice" && (
                                <td>
                                  {one.byMain
                                    ? one.mcAveragePrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.usd")
                                    : one.scAveragePrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.iqd")}
                                </td>
                              )}
                              {/*{type === 'StoreInventoryInvoice' && <td>{one.newSmallQty}</td>}*/}

                              {type !== "StoreInventoryInvoice" && (
                                <td>
                                  {one.byMain
                                    ? one.mcTotalPrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.usd")
                                    : one.scTotalPrice?.toLocaleString() +
                                      " " +
                                      translate("resources.root.iqd")}
                                </td>
                              )}
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                )}
              </Col>
            )}
          </Row>
        </div>
      </Print>
    </PrintProvider>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 275,
  },
  invoice: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  invoiceCols: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  toggles: {
    justifySelf: "flex-end",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    width: "100%",
  },
  orderCols: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  bottomLeftMargin: {
    marginLeft: 5,
    marginBottom: 10,
  },
  topLeftMargin: {
    marginLeft: 5,
    marginTop: 10,
  },
  invoiceInputs: {
    width: "80% !important",
  },
  addressInput: {
    width: "70% !important",
    marginTop: 8,
  },
  orderNumberInput: {
    width: "10% !important",
    marginLeft: 10,
    marginRight: 2,
  },
  orderTextInput: {
    width: "15% !important",
    marginLeft: 10,
  },
  noteInput: {
    width: "40% !important",
    marginLeft: 10,
  },
  discount: {
    width: "10% !important",
    marginLeft: 5,
    marginTop: 10,
  },
  inPercentage: {
    width: "10% !important",
    marginLeft: "auto",
    marginTop: 10,
  },
  autocomplete: {
    width: "100% !important",
  },
  orders: {
    marginTop: 30,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
  },
  tableContainer: {
    marginTop: 20,
    overflow: "auto",
  },
  botton: {
    backgroundColor: "#1976d2",
    color: "#fff",
    marginTop: 10,
    "&:hover, &:focus": {
      backgroundColor: "rgb(17, 82, 147)",
    },
  },
  primaryChips: {
    backgroundColor: "#1976d2",
    color: "#fff",
  },
  printWrapper: {
    width: "100%",
    height: "950",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
  ordersPrintWrapper: {
    display: "none",
    ["@media print"]: {
      display: "block",
      width: "100%",
    },
  },
}));
