import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  ImageField,
  ImageInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  useRedirect,
  NumberInput,
  Show,
  required,
  SelectInput,
  TabbedShowLayout,
  Tab,
  FormDataConsumer,
  minValue,
  TopToolbar,
  useRefresh,
  useTranslate,
  SaveButton,
  Toolbar,
  useNotify,
  ShowButton,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { path, url } from "../../request";
import Grid from "@mui/material/Grid";

import { Col, Form, Row } from "react-bootstrap";

import { useParams } from "react-router-dom";
export const EmployeesList = (props) => {
  const [currency, setCurrency] = React.useState("default");
  const translate = useTranslate();

  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate="hr.list"
        />
      }
    >
      <Datagrid
        bulkActionButtons={<BulkAction {...props} hasDelete="hr.list" />}
      >
        {process.env.REACT_APP_APP_NAME === "Mediply" && (
          <FunctionField
            label={`resources.root.image`}
            render={(record) => (
              <img
                style={{
                  width: 75,
                  height: 75,
                  objectFit: "scale-down",
                  cursor: "pointer",
                }}
                src={
                  record.attachment !== null
                    ? `${path}${record.attachment}`
                    : `https://via.placeholder.com/75`
                }
                alt={record.name}
                onClick={() =>
                  window.open(
                    record.attachment !== null
                      ? `${path}${record.attachment}`
                      : `https://via.placeholder.com/75`,
                    "_blank"
                  )
                }
              />
            )}
          />
        )}

        <FunctionField
          label="resources.root.name"
          sortBy={"firstName"}
          render={(record) =>
            `${record.firstName} ${record.middleName} ${record.lastName}`
          }
        />
        <TextField source="email" label="resources.root.email" />
        <TextField source="phone" label="resources.root.phone" />
        <TextField source="salaryType" label="resources.root.salaryType" />
        {currency === "default" && (
          <FunctionField
            label="resources.root.currentLoan"
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (record.loanByMain
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.currentLoan"
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              (record.mcCurrentLoan || record.scCurrentLoan) &&
              (currency === "dollar"
                ? `$${record.mcCurrentLoan?.toLocaleString()}`
                : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label="resources.root.salary"
            sortBy={"mcBasicSalary"}
            render={(record) =>
              (record.mcBasicSalary || record.scBasicSalary) &&
              (record.salaryByMain
                ? `$${record.mcBasicSalary?.toLocaleString()}`
                : `${record.scBasicSalary?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label="resources.root.salary"
            sortBy={"mcBasicSalary"}
            render={(record) =>
              (record.mcBasicSalary || record.scBasicSalary) &&
              (currency === "dollar"
                ? `$${record.mcBasicSalary?.toLocaleString()}`
                : `${record.scBasicSalary?.toLocaleString()} ${translate(
                    "resources.root.iqd"
                  )}`)
            }
          />
        )}
        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}

        <ShowButton />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const DateFilter = () => {
  const refresh = useRefresh();
  const translate = useTranslate();

  return (
    <Grid item className="col-12 ">
      <Row>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="startDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2 text-nowrap">
                {translate("resources.root.startDate")} :
              </Form.Label>
              <Form.Control
                className=""
                label="startDate"
                type="date"
                value={
                  localStorage.getItem("startDate") &&
                  localStorage.getItem("startDate")
                }
                onChange={(e) => {
                  localStorage.setItem("startDate", e.target.value);
                  refresh();
                }}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col className="col-12 col-md-6 mb-3">
          <Form.Group controlId="endDate">
            <Col className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.endDate")} :
              </Form.Label>
              <Form.Control
                type="date"
                onChange={(e) => {
                  localStorage.setItem("endDate", e.target.value);
                  refresh();
                }}
                value={
                  localStorage.getItem("endDate") &&
                  localStorage.getItem("endDate")
                }
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </Grid>
    // <div>
    //   {" "}
    //   <input
    //     style={{
    //       marginRight: 5,
    //       padding: 6,
    //       borderRadius: 4,
    //       borderWidth: 1,
    //       borderStyle: "solid",
    //       borderColor: "gray",
    //     }}
    //     type="date"
    //     label="resources.root.startDate"
    //     source="startDate"
    //     alwaysOn
    //     onChange={(e) => {
    //       localStorage.setItem("startDate", e.target.value);
    //       refresh();
    //     }}
    //     value={
    //       localStorage.getItem("startDate") && localStorage.getItem("startDate")
    //     }
    //   />
    //   <input
    //     style={{
    //       marginRight: 5,
    //       padding: 6,
    //       borderRadius: 4,
    //       borderWidth: 1,
    //       borderStyle: "solid",
    //       borderColor: "gray",
    //     }}
    //     type="date"
    //     label="resources.root.endDate"
    //     source="endDate"
    //     alwaysOn
    //     onChange={(e) => {
    //       localStorage.setItem("endDate", e.target.value);
    //       refresh();
    //     }}
    //     value={
    //       localStorage.getItem("endDate") && localStorage.getItem("endDate")
    //     }
    //   />
    // </div>
  );
};

const PostShowActions = ({ data, resource }) => (
  <TopToolbar>
    <DateFilter />
  </TopToolbar>
);

export const EmployeesShow = (props) => {
  const translate = useTranslate();

  return (
    <Show {...props} actions={<PostShowActions />} className="my-5">
      <TabbedShowLayout>
        <Tab label="resources.root.loans">
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              `${record.firstName} ${record.middleName} ${record.lastName}`
            }
          />
          <TextField source="phone" label="resources.root.phone" />

          <FunctionField
            label="resources.root.roofLoan"
            render={(record) =>
              `${
                record?.loanByMain
                  ? `${record.mcRoofLoan} ${translate("resources.root.usd")}`
                  : ` ${record.scRoofLoan} ${translate("resources.root.iqd")}`
              }`
            }
          />
          <FunctionField
            label="resources.root.currentLoan"
            render={(record) =>
              `${record?.employeeLoans
                ?.map((el) => (el?.loanByMain ? el.mcPrice : el.scPrice))
                .reduce((a, b) => a + b, 0)} ${
                record?.loanByMain
                  ? `${translate("resources.root.usd")}`
                  : ` ${translate("resources.root.iqd")}`
              }`
            }
          />
          <FunctionField
            label="resources.root.loans"
            render={(record) => {
              return (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.accountant")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.employeeLoans.map((loan) => (
                        <TableRow key={loan.id}>
                          <TableCell component="th" scope="row">
                            {loan.accountant}
                          </TableCell>
                          {record.loanByMain ? (
                            <TableCell align="right">
                              ${loan.mcPrice?.toLocaleString()}
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              {loan.scPrice?.toLocaleString()}{" "}
                              {translate("resources.root.iqd")}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {moment(loan.date).format("YYYY-MM-DD")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </Tab>
        <Tab label="resources.root.returnedLoan" path="returnedLoans">
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              `${record.firstName} ${record.middleName} ${record.lastName}`
            }
          />
          <TextField source="phone" label="resources.root.phone" />
          <FunctionField
            label="resources.root.TotalReturnedLoan"
            render={(record) =>
              `${record?.employeeReturnLoans
                ?.map((el) => (el.byMain ? el.mcPrice : el.scPrice))
                .reduce((a, b) => a + b, 0)} ${
                record.salaryByMain
                  ? `${translate("resources.root.iqd")}`
                  : ` ${translate("resources.root.iqd")}`
              }`
            }
          />
          <FunctionField
            label="resources.root.returnedLoan"
            render={(record) => {
              return (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.accountant")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.employeeReturnLoans.map((loan) => (
                        <TableRow key={loan.id}>
                          <TableCell component="th" scope="row">
                            {loan.accountant}
                          </TableCell>
                          {record.loanByMain ? (
                            <TableCell align="right">
                              ${loan.mcPrice?.toLocaleString()}
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              {loan.scPrice?.toLocaleString()}{" "}
                              {translate("resources.root.iqd")}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {moment(loan.date).format("YYYY-MM-DD")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </Tab>
        <Tab label="resources.root.absents" path="absents">
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              `${record.firstName} ${record.middleName} ${record.lastName}`
            }
          />
          <TextField source="phone" label="resources.root.phone" />
          <FunctionField
            label="resources.root.absents"
            render={(record) => {
              return (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell align="right">
                          {translate("resources.root.reason")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.note")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.employeeAbsents.map((absent) => (
                        <TableRow key={absent.id}>
                          <TableCell align="right">{absent.reason}</TableCell>
                          <TableCell align="right">{absent.note}</TableCell>
                          <TableCell align="right">
                            {moment(absent.date).format("YYYY-MM-DD")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </Tab>
        <Tab label="resources.root.rewards" path="rewards">
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              `${record.firstName} ${record.middleName} ${record.lastName}`
            }
          />
          <TextField source="phone" label="resources.root.phone" />
          <FunctionField
            label="resources.root.totalRewards"
            render={(record) =>
              `${record.employeeRewards
                .filter((el) => !el.cashPaid)
                .map((el) => (el.byMain ? el.mcPrice : el.scPrice))
                .reduce((a, b) => a + b, 0)} ${
                record.salaryByMain
                  ? `${translate("resources.root.usd")}`
                  : `${translate("resources.root.iqd")}`
              }`
            }
          />
          <FunctionField
            label="resources.root.returnedLoan"
            render={(record) => {
              return (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.rewardedBy")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.reason")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.date")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.type")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.employeeRewards.map((reward) => (
                        <TableRow
                          style={{
                            backgroundColor: reward.cashPaid && "#f2f2f2",
                          }}
                          key={reward.id}
                        >
                          <TableCell component="th" scope="row">
                            {reward.rewardedBy}
                          </TableCell>
                          {reward.byMain ? (
                            <TableCell align="right">
                              ${reward.mcPrice?.toLocaleString()}
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              {reward.scPrice?.toLocaleString()}{" "}
                              {translate("resources.root.iqd")}
                            </TableCell>
                          )}
                          <TableCell align="right">{reward.reason}</TableCell>
                          <TableCell align="right">
                            {moment(reward.date).format("YYYY-MM-DD")}
                          </TableCell>
                          <TableCell align="right">
                            {reward.cashPaid
                              ? translate("resources.root.cash")
                              : translate("resources.root.loan")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </Tab>
        <Tab label="resources.root.punishments" path="punishments">
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              `${record.firstName} ${record.middleName} ${record.lastName}`
            }
          />
          <TextField source="phone" label="resources.root.phone" />
          <FunctionField
            label="resources.root.totalPunishments"
            render={(record) =>
              `${record.employeePunishments
                .map((el) => (el.byMain ? el.mcPrice : el.scPrice))
                .reduce((a, b) => a + b, 0)} ${
                record.salaryByMain
                  ? `${translate("resources.root.usd")}`
                  : `${translate("resources.root.iqd")}`
              }`
            }
          />
          <FunctionField
            label="resources.root.punishments"
            render={(record) => {
              return (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.punishedBy")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.price")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.reason")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.employeePunishments.map((punishment) => (
                        <TableRow key={punishment.id}>
                          <TableCell component="th" scope="row">
                            {punishment.punishedBy}
                          </TableCell>
                          {punishment.byMain ? (
                            <TableCell align="right">
                              ${punishment.mcPrice?.toLocaleString()}
                            </TableCell>
                          ) : (
                            <TableCell align="right">
                              {punishment.scPrice?.toLocaleString()}{" "}
                              {translate("resources.root.iqd")}
                            </TableCell>
                          )}
                          <TableCell align="right">
                            {punishment.reason}
                          </TableCell>
                          <TableCell align="right">
                            {moment(punishment.date).format("YYYY-MM-DD")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </Tab>
        <Tab label="resources.root.permissions" path="permissions">
          <FunctionField
            label="resources.root.name"
            render={(record) =>
              `${record.firstName} ${record.middleName} ${record.lastName}`
            }
          />
          <TextField source="phone" label="resources.root.phone" />
          <FunctionField
            label="resources.root.totalSubtractedSalary"
            render={(record) =>
              `${record.employeeAllows
                .map((el) =>
                  el.byMain ? el.mcSubtractedSalary : el.scSubtractedSalary
                )
                .reduce((a, b) => a + b, 0)} ${
                record.salaryByMain
                  ? `${translate("resources.root.usd")}`
                  : `${translate("resources.root.iqd")}`
              }`
            }
          />
          <FunctionField
            label="resources.root.permissions"
            render={(record) => {
              return (
                <TableContainer component={Paper}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          {translate("resources.root.days")}
                        </TableCell>
                        <TableCell>
                          {translate("resources.root.hours")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.reason")}
                        </TableCell>
                        <TableCell align="right">
                          {translate("resources.root.date")}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {record.employeeAllows.map((permission) => (
                        <TableRow key={permission.id}>
                          <TableCell component="th" scope="row">
                            {permission.days}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {permission.hours}
                          </TableCell>
                          <TableCell align="right">
                            {permission.reason}
                          </TableCell>
                          <TableCell align="right">
                            {moment(permission.date).format("YYYY-MM-DD")}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              );
            }}
          />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export const EmployeesCreate = (props) => {
  const translate = useTranslate();
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  const [salaryCurrency, setSalaryCurrency] = React.useState(true);
  const classes = useStyles();
  React.useEffect(() => {
    localStorage.removeItem("startDate");
    localStorage.removeItem("endDate");
  }, []);

  return (
    <Create
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <TextInput
              source="firstName"
              label="resources.root.firstName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="middleName"
              label="resources.root.middleName"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              label="resources.root.lastName"
              className={classes.input}
            />
            <TextInput
              source="email"
              label="resources.root.email"
              className={classes.input}
            />
            <TextInput
              source="address"
              label="resources.root.address"
              className={classes.input}
            />
            <TextInput
              source="phone"
              label="resources.root.phone"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label="resources.root.phone2"
              className={classes.input}
            />
            <DateInput
              defaultValue={new Date()}
              source="startingDate"
              label="resources.root.startingDate"
              className={classes.input}
            />
            <SelectInput
              source="salaryType"
              label="resources.root.salaryType"
              validate={[required()]}
              className={classes.input}
              defaultValue={"monthly"}
              choices={[
                {
                  id: "monthly",
                  name: `${translate("resources.root.monthly")}`,
                },
                { id: "weekly", name: `${translate("resources.root.weekly")}` },
              ]}
            />
            {salaryCurrency ? (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      defaultValue={0}
                      min={0}
                      source="mcBasicSalary"
                      label="resources.root.mcBasicSalary"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      defaultValue={0}
                      min={0}
                      source="scBasicSalary"
                      label="resources.root.scBasicSalary"
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            )}
            <BooleanInput
              source="salaryByMain"
              className={classes.input}
              label={
                salaryCurrency
                  ? "resources.root.salaryUsd"
                  : "resources.root.salaryIqd"
              }
              defaultValue={true}
              onChange={(e) => setSalaryCurrency(e.target.checked)}
            />
            <TextInput
              source="monthlyAllowDays"
              label="resources.root.monthlyAllowDays"
              defaultValue="0"
              className={classes.input}
            />
          </div>
          <div className={classes.formCol}>
            <DateInput
              source="dob"
              label="resources.root.dob"
              defaultValue={moment().subtract(18, "years")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="country"
              label="resources.root.country"
              defaultValue="Iraq"
              className={classes.input}
            />
            <TextInput
              source="city"
              label="resources.root.city"
              defaultValue="Erbil"
              className={classes.input}
            />
            <TextInput
              source="nationality"
              label="resources.root.nationality"
              defaultValue="Iraqi"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="note"
              label="resources.root.note"
              className={classes.input}
            />
            <TextInput
              source="idCardType"
              label="resources.root.idCardType"
              className={classes.input}
            />
            <TextInput
              source="idCardNumber"
              label="resources.root.idCardNumber"
              className={classes.input}
            />
            {currency ? (
              <NumberInput
                validate={[minValue(0)]}
                min={0}
                id="mcRoofLoan"
                defaultValue={0}
                source="mcRoofLoan"
                label="resources.root.mcRoofLoan"
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[minValue(0)]}
                min={0}
                id="scRoofLoan"
                defaultValue={0}
                source="scRoofLoan"
                label="resources.root.scRoofLoan"
                className={classes.input}
              />
            )}
            <BooleanInput
              source="loanByMain"
              className={classes.input}
              label={
                currency ? "resources.root.loanUsd" : "resources.root.loanIqd"
              }
              defaultValue={currency}
              onChange={(e) => setCurrency(e.target.checked)}
              validate={[required()]}
            />
            <BooleanInput
              source="male"
              label={gender ? "resources.root.male" : "resources.root.female"}
              defaultValue={true}
              onChange={(e) => setGender(e.target.checked)}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="workingHoursPerDay"
              label="resources.root.workingHoursPerDay"
              defaultValue="0"
              className={classes.input}
            />
          </div>
        </div>
      </SimpleForm>
    </Create>
  );
};

export const EmployeesEdit = (props) => {
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(false);

  const [salaryCurrency, setSalaryCurrency] = React.useState(true);

  const [scRoofLoan, setScRoofLoan] = React.useState(0);
  const [mcRoofLoan, setMcRoofLoan] = React.useState(0);
  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const refresh = useRefresh();
  const notify = useNotify();
  const { id } = useParams();

  React.useEffect(() => {
    HTTP.get(`/Employees/${id}`).then((response) => {
      setData(response.data);
      setCurrency(response.data?.loanByMain);
      setScRoofLoan(response.data?.scRoofLoan);
      setMcRoofLoan(response.data?.mcRoofLoan);
    });
  }, []);
  return (
    <Edit {...props} redirect="list">
      <SimpleForm>
        {data.id && (
          <div className={classes.container}>
            <div className={classes.formCol}>
              <TextInput
                source="firstName"
                label="resources.root.firstName"
                className={classes.input}
                validate={[required()]}
              />
              <TextInput
                source="middleName"
                label="resources.root.middleName"
                className={classes.input}
                validate={[required()]}
              />
              <TextInput
                source="lastName"
                label="resources.root.lastName"
                className={classes.input}
              />
              <TextInput
                source="email"
                label="resources.root.email"
                className={classes.input}
              />
              <TextInput
                source="address"
                label="resources.root.address"
                className={classes.input}
              />
              <TextInput
                source="phone"
                label="resources.root.phone"
                className={classes.input}
                validate={[required()]}
              />
              <TextInput
                source="phone2"
                label="resources.root.phone2"
                className={classes.input}
              />
              <SelectInput
                source="salaryType"
                label="resources.root.salaryType"
                validate={[required()]}
                className={classes.input}
                choices={[
                  { id: "Monthly", name: "Monthly" },
                  // { id: "Weekly", name: "Weekly" },
                ]}
              />
              <TextInput
                source="monthlyAllowDays"
                label="resources.root.monthlyAllowDays"
                defaultValue="0"
                className={classes.input}
              />
              <ImageInput
                source="attachment"
                label="resources.root.attachment"
                accept="image/*"
              >
                <ImageField source="src" title="title" />
              </ImageInput>
              <FunctionField
                label="resources.root.attachment"
                render={(record) =>
                  record.attachment !== null && (
                    <div style={{ position: "relative" }}>
                      <button
                        type="button"
                        className="btn btn-danger p-2"
                        style={{ right: "0px", position: "absolute" }}
                        onClick={() => {
                          HTTP.delete(
                            `${url}/Employees/DeleteEmployeeImage?id=${record.id}`
                          )
                            .then(() => {
                              notify("Image deleted successfully", {
                                type: "success",
                                undoable: true,
                              });
                              refresh();
                            })
                            .catch((err) =>
                              notify(err.response.data.message, {
                                type: "error",
                                undoable: true,
                              })
                            );
                        }}
                      >
                        <span>&times;</span>
                      </button>
                      <img
                        style={{
                          width: 200,
                          height: 200,
                          objectFit: "scale-down",
                          cursor: "pointer",
                        }}
                        src={`${path}${record.attachment}`}
                        alt="Attachment"
                        onClick={() =>
                          window.open(`${path}${record.attachment}`, "_blank")
                        }
                      />
                    </div>
                  )
                }
              />
            </div>
            <div className={classes.formCol}>
              <DateInput
                source="dob"
                label="resources.root.dob"
                defaultValue={moment().subtract(18, "years")}
                className={classes.input}
                validate={[required()]}
              />
              <TextInput
                source="country"
                label="resources.root.country"
                defaultValue="Iraq"
                className={classes.input}
              />
              <TextInput
                source="city"
                label="resources.root.city"
                defaultValue="Erbil"
                className={classes.input}
              />
              <TextInput
                source="nationality"
                label="resources.root.nationality"
                defaultValue="Iraqi"
                className={classes.input}
                validate={[required()]}
              />
              <TextInput
                source="note"
                label="resources.root.note"
                className={classes.input}
              />
              <TextInput
                source="idCardType"
                label="resources.root.idCardType"
                className={classes.input}
              />
              <TextInput
                source="idCardNumber"
                label="resources.root.idCardNumber"
                className={classes.input}
              />
              {currency ? (
                <NumberInput
                  validate={[required(), minValue(0)]}
                  min={0}
                  source="mcRoofLoan"
                  label="resources.root.mcRoofLoan"
                  className={classes.input}
                />
              ) : (
                <NumberInput
                  validate={[required(), minValue(0)]}
                  min={0}
                  source="scRoofLoan"
                  label="resources.root.scRoofLoan"
                  className={classes.input}
                />
              )}
              {/* <BooleanInput
              source="loanByMain"
              className={classes.input}
              label={
                currency ? "resources.root.loanUsd" : "resources.root.loanIqd"
              }
              defaultValue={currency}
              onChange={(e) => setCurrency(e.target.checked)}
              validate={[required()]}
            /> */}
              <BooleanInput
                source="male"
                label={"resources.root.male"}
                defaultValue={data && data.male}
                onChange={(e) => setGender(e.target.checked)}
                className={classes.input}
                validate={[required()]}
              />
              <TextInput
                source="workingHoursPerDay"
                label="resources.root.workingHoursPerDay"
                defaultValue="0"
                className={classes.input}
              />
            </div>
          </div>
        )}
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
});
