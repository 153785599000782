import { HTTP } from "./axios";
import { url } from "./request";
import axios from "axios";

const authProvider = {
  login: async ({ username, password }) => {
    try {
      const res = await HTTP.post(`/Accounts/authenticate`, {
        username,
        password,
      });

      if (res?.data?.token) {
        localStorage.setItem("token", res.data.token);
        HTTP.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
      }
      localStorage.setItem(
        "name",
        `${res?.data?.firstName} ${res?.data?.middleName}`
      );
      localStorage.setItem("firstName", `${res?.data?.firstName}`);
      localStorage.setItem("middleName", `${res?.data?.middleName}`);
      localStorage.setItem("lastName", `${res?.data?.lastName}`);
      localStorage.setItem("image", `${res?.data?.image}`);
      if (
        process.env.REACT_APP_APP_NAME === "Bahez" ||
        process.env.REACT_APP_APP_NAME === "Safeen"
      ) {
        localStorage.setItem(
          "role",
          res?.data?.definer !== null && res?.data?.definer !== ""
            ? res?.data?.userType + res?.data?.definer
            : res?.data?.userType
        );
      } else {
        localStorage.setItem("role", res?.data?.userType);
      }
      localStorage.setItem("userId", res?.data?.userId);
      localStorage.setItem("storeId", res?.data?.storeId);
      localStorage.setItem("userName", username);

      try {
        const preferencesRes = await HTTP.get("/DbPrefenses");
        localStorage.setItem(
          "lastEmptySafeBoxDate",
          preferencesRes.data[0].lastEmptySafeBoxDate
        );
        localStorage.setItem("dollarPrice", preferencesRes.data[0].mcToScPrice);
        localStorage.setItem("mcLabel", preferencesRes.data[0].mcLabel);
        localStorage.setItem("scLabel", preferencesRes.data[0].scLabel);
        localStorage.setItem("mcSymbol", preferencesRes.data[0].mcSymbol);
        localStorage.setItem("scSymbol", preferencesRes.data[0].scSymbol);
        localStorage.setItem(
          "canSellBellowZero",
          preferencesRes.data[0].canSellBellowZero
        );
      } catch (e) {
        try {
          HTTP.post(`/DbPrefenses`, {
            lastEmptySafeBoxDate: null,
            mcLabel: "Dollar",
            scLabel: "Dinar",
            mcSymbol: "USD",
            scSymbol: " IQD",
            mcToScPrice: 1480,
            canSellBellowZero: false,
          }).then((res) => {
            localStorage.setItem("lastEmptySafeBoxDate", null);
            localStorage.setItem("dollarPrice", "1450");
            localStorage.setItem("mcLabel", "Dollar");
            localStorage.setItem("scLabel", "Dinar");
            localStorage.setItem("mcSymbol", "USD");
            localStorage.setItem("scSymbol", " IQD");
            localStorage.setItem("canSellBellowZero", "false");
          });
        } catch (err) {
          throw new Error(err.response.data);
        }
      }
    } catch (err) {
      if (err.response.status === 401) {
        throw new Error("Username or Password is incorrect!");
      } else if (err.response.status < 200 || err.response.status >= 300) {
        throw new Error(err.response.data);
      }
    } finally {
      window.location.reload();
    }
  },
  logout: () => {
    HTTP.post(`${url}/Accounts/LogOut`).then((resp) => {});
    localStorage.removeItem("token");
    return Promise.resolve();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: () =>
    localStorage.getItem("token") ? Promise.resolve() : Promise.reject(),
  getPermissions: () => {
    const role = localStorage.getItem("role");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
