import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import loadOptions from "../../loadOptions";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [supplier, setSupplier] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);

  const [total, setTotal] = React.useState({
    mcTotalPrice: 0,
    scTotalPrice: 0,
    totalQte: 0,
    totalSmallQte: 0,
  });

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let supplierOb = ``;
    if (supplier !== 0) {
      supplierOb = `${supplier}`;
    } else {
      supplierOb = "";
    }
    await HTTP.get(
      `${url}/Reports/SupplierStockRemain?_start=${start}&_end=${end}${supplierOb}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setTotal({
          mcTotalPrice: parseFloat(response.headers["mctotalsellprice"]),
          scTotalPrice: parseFloat(response.headers["sctotalsellprice"]),
          totalQte: parseFloat(response.headers["totalqte"]),
          totalSmallQte: parseFloat(response.headers["totalsmallqte"]),
        });
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div>
      <PrintProvider>
        <Print single name={`supplierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.supplierStockRemain")}
            </h1>
          </div>
          <NoPrint force>
            <Row style={{ fontSize: "15px" }}>
              {isLoading && <LoadingScreen />}

              <Col className="col-12 col-lg-6 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.supplier")} :
                </p>
                <AsyncSelect
                  isMulti
                  defaultOptions
                  className="w-100"
                  loadOptions={loadOptions.suppliers}
                  onChange={(e) => {
                    let expense = "";
                    if (e.length !== 0) {
                      e.map((x) => (expense += "&id=" + x.value));
                      setSupplier(expense);
                    } else {
                      setSupplier(``);
                    }
                  }}
                />
              </Col>
              <Col className="col-12 col-lg-6 mt-4 row row-cols-2 justify-content-start">
                <h5>
                  {translate("resources.root.totalPrice")}: $
                  {parseFloat(total?.mcTotalPrice)?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.totalPrice")}:{" "}
                  {parseFloat(total?.scTotalPrice)?.toLocaleString()}{" "}
                  {translate("resources.root.iqd")}
                </h5>
                <h5>
                  {translate("resources.root.totalQte")}:
                  {parseFloat(total?.totalQte)?.toLocaleString()}
                </h5>
                <h5>
                  {translate("resources.root.totalSmallQte")}:{" "}
                  {parseFloat(total?.totalSmallQte)?.toLocaleString()}{" "}
                </h5>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={data.length <= 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  disabled={data.length <= 0}
                  variant={"outline-primary btn-block"}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-4 mt-4">
                <Button
                  variant={"primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.barcode")}</th>
                    <th>{translate("resources.root.name")}</th>
                    <th>{translate("resources.root.purchasePrice")}</th>
                    <th>{translate("resources.root.sellPrice")}</th>
                    <th>{translate("resources.root.qtyInStock")}</th>
                    <th>{translate("resources.root.smallMeasureQty")}</th>
                    <th>{translate("resources.root.alertQty")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr key={one.id}>
                          <td>
                            <a
                              href={`#/Products/${one.id}/show`}
                              target={"_blank"}
                            >
                              {one.barcode}
                            </a>
                          </td>
                          <td>{one.name}</td>
                          <td>
                            {one.purchaseByMain
                              ? `$${one.mcPurchasePrice?.toLocaleString()}`
                              : `${one.scPurchasePrice?.toLocaleString()} IQD`}
                          </td>
                          <td>
                            {one.sellByMain
                              ? `$${one.mcSellPrice?.toLocaleString()}`
                              : `${one.scSellPrice?.toLocaleString()} IQD`}
                          </td>
                          <td>{one.qteInStock}</td>
                          <td>{one.smallMeasureQte}</td>
                          <td>{one.alertQte}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2500}>2500</option>
                      <option value={5000}>5000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
