import * as React from "react";
import { useRedirect, useNotify, useTranslate } from "react-admin";
import CardActions from "@mui/material/CardActions";
import { url } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteIcon from "@mui/icons-material/Delete";
import MuiAlert from "@mui/lab/Alert";
import Snackbar from "@mui/material/Snackbar";
import swal from "sweetalert";
import { Form } from "react-bootstrap";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import { HTTP } from "../../axios";
import AsyncSelect from "react-select/async";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import loadOptions from "../../loadOptions";

const CreateSellInvoice = (props) => {
  const { invoice } = props;
  const { id } = useParams();
  const notify = useNotify();
  const translate = useTranslate();
  const [customers, setCustomers] = React.useState([]);
  const [previousPrice, setPreviousPrice] = React.useState(null);
  const [selectedCustomer, setSelectedCustomer] = React.useState(null);
  const [products, setProducts] = React.useState([]);
  const [selectedProduct, setSelectedProduct] = React.useState(null);
  const [cashPayment, setCashPayment] = React.useState(false);
  const [address, setAddress] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [orderQtt, setOrderQtt] = React.useState(1);
  const [productSerial, setProductSerial] = React.useState(null); // use this to sset the selected serial string
  const [productSerials, setProductSerials] = React.useState([]); // usisng for the list of product serialials
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  console.log(selectedDate);
  const [orders, setOrders] = React.useState(
    invoice === "return"
      ? localStorage.getItem("InvoiceReturnOrders")
        ? JSON.parse(localStorage.getItem("InvoiceReturnOrders"))
        : []
      : localStorage.getItem("InvoiceOrders")
      ? JSON.parse(localStorage.getItem("InvoiceOrders"))
      : []
  );
  if (invoice === "return") {
    localStorage.setItem("InvoiceReturnOrders", JSON.stringify(orders));
  } else {
    localStorage.setItem("InvoiceOrders", JSON.stringify(orders));
  }

  const [discount, setDiscount] = React.useState(0);
  const [percentageDiscount, setPercentageDiscount] = React.useState(0);
  const [discountInPercent, setDiscountInPercent] = React.useState(true);
  const [invoiceNumber, setInvoiceNumber] = React.useState(null);

  const [modifiedPrice, setModifiedPrice] = React.useState(0);
  const [orderDiscount, setOrderDiscount] = React.useState(0);
  const [orderDiscountPercentage, setOrderDiscountPercentage] =
    React.useState(0);
  const [orderDiscountInPercent, setOrderDiscountInPercent] =
    React.useState(true);
  const [gift, setGift] = React.useState(0);
  const [note, setNote] = React.useState("");
  const [invoiceNote, setInvoiceNote] = React.useState("");
  const [driver, setDriver] = React.useState("");
  const [orderToEdit, setOrderToEdit] = React.useState(-1);
  const [open, setOpen] = React.useState(false);
  const [submitAttempted, setSubmitAttempted] = React.useState(false);
  const [delay, setDelay] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const redirect = useRedirect();
  const idemPotent = uuidv4();

  React.useEffect(() => {
    if (id) {
      HTTP.get(`/sellInvoices/${id}`).then((res) => {
        console.log(res.data);
        setOrders(res.data.sellOrders);
        setSelectedCustomer(res.data.customer);
        setAddress(res.data.address);
      });
    } else {
      HTTP.get(`/customers?_end=25000`).then((res) => {
        setCustomers(res.data);
      });
    }

    HTTP.get(`${url}/Products?_end=10000`).then((res) => {
      setProducts(res.data);
    });
  }, [0]);

  React.useEffect(() => {
    setProductSerials([]);
    setProductSerial(null);
    if (selectedProduct != null && selectedProduct?.id != undefined) {
      HTTP.get(`${url}/ProductSerials/${selectedProduct?.id}`).then((res) => {
        setProductSerials(res.data);
      });
    }
    selectedProduct &&
      selectedCustomer &&
      HTTP.get(
        `${url}/SellInvoices/GetLastPriceOfProduct?customerId=${selectedCustomer?.id}&productId=${selectedProduct?.id}`
      ).then((res) => {
        setPreviousPrice({ ...previousPrice, sell: res.data });
      });
    selectedProduct &&
      HTTP.get(
        `${url}/PurchaseInvoices/GetLastPriceOfProduct?supplierId=${0}&productId=${
          selectedProduct?.id
        }`
      ).then((res) => {
        setPreviousPrice({ ...previousPrice, sell: res.data });
      });
  }, [selectedCustomer, selectedProduct]);

  React.useEffect(() => {
    setSelectedProduct(products.filter((el) => el.id === orderToEdit)[0]);
  }, [orderToEdit]);

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  function roundToTwo(num, to = 3) {
    return +(Math.round(num + "e+" + to) + "e-" + to);
  }

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const minMaxPriceHandler = () => {
    const maxProductPrice = selectedCustomer?.payLoanByMain
      ? selectedProduct?.mcSellMaxPrice
      : selectedProduct?.scSellMaxPrice;
    const minProductPrice = selectedCustomer?.payLoanByMain
      ? selectedProduct?.mcSellMinPrice
      : selectedProduct?.scSellMinPrice;

    if (minProductPrice !== 0 && minProductPrice > modifiedPrice) {
      Swal.fire({
        title: translate("resources.root.swalTitle"),
        text: translate("resources.root.lowerPrice"),
        icon: "warning",
        buttons: true,
        confirmButtonText: translate("resources.root.yes"),
        cancelButtonText: translate("resources.root.no"),
        confirmButtonColor: "#1e6fc3",

        dangerMode: true,
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          addOrderToList();
        }
      });
    } else if (maxProductPrice !== 0 && maxProductPrice < modifiedPrice) {
      Swal.fire({
        title: translate("resources.root.swalTitle"),
        text: translate("resources.root.higherPrice"),
        icon: "warning",
        buttons: true,
        cancelButtonText: translate("resources.root.no"),
        confirmButtonText: translate("resources.root.yes"),
        dangerMode: true,
        confirmButtonColor: "#1e6fc3",

        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          addOrderToList();
        }
      });
    } else {
      addOrderToList();
    }
  };
  const addOrderToList = () => {
    const dollarPrice = localStorage.getItem("dollarPrice") || 1450;

    (parseFloat(orderQtt) > 0 || parseFloat(gift) > 0) &&
      setOrders((prev) => {
        const found = prev.find(
          (el) => el.productId === parseInt(selectedProduct?.id)
        );
        if (found) {
          const newOrder = prev.map(
            (el) =>
              el.productId === selectedProduct?.id && {
                productId: selectedProduct?.id,
                giftQte: el.giftQte + gift,
                payByMain: selectedCustomer.payLoanByMain,
                barcode: selectedProduct.barcode,
                productSerial: {
                  serialNumber: productSerial,
                },
                name: selectedProduct.name,
                note: note,
                qte: parseFloat(orderQtt) + parseFloat(el.qte ? el.qte : 0),
                mcLastPrice:
                  selectedProduct.mcSellPrice *
                  (parseFloat(orderQtt) + parseFloat(el.qte ? el.qte : 0)),
                mcSellPrice:
                  (selectedCustomer.payLoanByMain
                    ? modifiedPrice
                    : modifiedPrice / dollarPrice) ||
                  selectedProduct.mcSellPrice,
                mcPurchasePrice: selectedProduct.mcPurchasePrice,
                mcDiscount: orderDiscount,
                mcTotalPrice:
                  ((selectedCustomer.payLoanByMain
                    ? modifiedPrice
                    : modifiedPrice / dollarPrice) ||
                    selectedProduct.mcSellPrice) *
                    (parseFloat(orderQtt) + el.qte || 0) -
                  orderDiscount,
                scLastPrice: selectedProduct.scSellPrice * parseFloat(orderQtt),
                scPurchasePrice: selectedProduct.scPurchasePrice,
                scDiscount: orderDiscount,
                scSellPrice:
                  (selectedCustomer.payLoanByMain
                    ? modifiedPrice * dollarPrice
                    : modifiedPrice) || selectedProduct.mcSellPrice,
                scTotalPrice:
                  ((selectedCustomer.payLoanByMain
                    ? modifiedPrice * dollarPrice
                    : modifiedPrice) || selectedProduct.mcSellPrice) *
                    (parseFloat(orderQtt) + el.qte || 0) -
                  orderDiscount,
              }
          );
          return [
            ...prev.filter((el) => el.productId !== selectedProduct?.id),
            ...newOrder.filter((el) => el !== false),
          ];
        } else {
          return [
            ...prev,
            {
              productId: selectedProduct?.id,
              giftQte: gift,
              payByMain: selectedCustomer.payLoanByMain,
              barcode: selectedProduct.barcode,
              name: selectedProduct.name,
              note: note,
              productSerial: {
                serialNumber: productSerial,
              },
              qte: parseFloat(orderQtt),
              mcLastPrice: selectedProduct.mcSellPrice * parseFloat(orderQtt),
              mcPurchasePrice: selectedProduct.mcPurchasePrice,
              mcDiscount: orderDiscount,
              mcSellPrice:
                (selectedCustomer.payLoanByMain
                  ? modifiedPrice
                  : modifiedPrice / dollarPrice) || 0,
              mcTotalPrice:
                ((selectedCustomer.payLoanByMain
                  ? modifiedPrice
                  : modifiedPrice / dollarPrice) || 0) *
                  (parseFloat(orderQtt) || 0) -
                orderDiscount,
              scLastPrice: selectedProduct.scSellPrice * parseFloat(orderQtt),
              scPurchasePrice: selectedProduct.scPurchasePrice,
              scDiscount: orderDiscount,
              scSellPrice:
                (selectedCustomer.payLoanByMain
                  ? modifiedPrice * dollarPrice
                  : modifiedPrice) || 0,
              scTotalPrice:
                ((selectedCustomer.payLoanByMain
                  ? modifiedPrice * dollarPrice
                  : modifiedPrice) || 0) *
                  (parseFloat(orderQtt) || 0) -
                orderDiscount,
            },
          ];
        }
      });
    if (localStorage.getItem("updateSellingPrice") === "true") {
      HTTP.post(
        `${url}/Products/UpdateProductPrice?byMain=${true}&productId=${
          selectedProduct?.id
        }&scPrice=${(
          selectedProduct.scSellPrice * localStorage.getItem("dollarPrice")
        ).toFixed(3)}&mcPrice=${selectedProduct.mcSellPrice.toFixed(3)}`,
        {}
      );
    }
    setOrderQtt(1);
    setOrderToEdit(-1);
    setModifiedPrice(0);
    setGift(0);
    setNote("");
    setDelay(false);
    setSelectedProduct(null);
  };
  const changeCol = (id, newValue, col) => {
    const dollarPrice = localStorage.getItem("dollarPrice") || 1450;

    let selectedOrder = orders.find((el) => el.productId === id);
    let selectedOrderIndex = orders.indexOf(selectedOrder);

    selectedOrder[col] = newValue;
    if (selectedCustomer.payLoanByMain) {
      selectedOrder.mcTotalPrice =
        selectedOrder.qte * selectedOrder.mcSellPrice;
      selectedOrder.scSellPrice = selectedOrder.mcSellPrice * dollarPrice;
      selectedOrder.scTotalPrice =
        selectedOrder.scSellPrice * selectedOrder.qte;
    } else {
      selectedOrder.scTotalPrice =
        selectedOrder.qte * selectedOrder.scSellPrice;
      selectedOrder.mcSellPrice = selectedOrder.scSellPrice / dollarPrice;
      selectedOrder.mcTotalPrice =
        selectedOrder.mcSellPrice * selectedOrder.qte;
    }

    let newOrders = orders.filter((x) => x.productId !== id);

    newOrders = [
      ...newOrders.slice(0, selectedOrderIndex),
      selectedOrder,
      ...newOrders.slice(selectedOrderIndex),
    ];
    setOrders(newOrders);
  };
  const submit = () => {
    setSubmitAttempted(true);
    if (!selectedCustomer) {
      setErrorMessage(translate("resources.root.selectCustomer"));
      return;
    }
    Swal.fire({
      title: translate("resources.root.makeSure"),
      text: translate("resources.root.confirmCreateNewInvoice"),
      confirmButtonText: translate("resources.root.yes"),
      cancelButtonText: translate("resources.root.no"),
      showDenyButton: true,
      confirmButtonColor: "#1e6fc3",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      setDelay(true);
      if (result.isConfirmed) {
        setIsLoading(true);
        let sellOrders, returnOrders;
        if (invoice === "return") {
          returnOrders = orders;
        } else {
          sellOrders = orders;
        }
        HTTP.post(
          `${url}/${
            invoice === "return" ? "SellReturnInvoices" : "SellInvoices"
          }`,
          selectedCustomer?.payLoanByMain
            ? {
                invoiceNumber,
                voucherNumber: invoiceNumber,
                address,
                customerId: selectedCustomer?.id,
                date: moment(selectedDate).add(3, "hours"),
                mcTotalPrice: orders
                  .map((el) => parseFloat(el.mcSellPrice) * parseFloat(el.qte))
                  .reduce((a, b) => a + b, 0),
                mcFinalPrice:
                  orders
                    .map(
                      (el) => parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                    )
                    .reduce((a, b) => a + b, 0) - discount,
                isCash: cashPayment,
                payByMain: true,
                totalQte: orders.map((el) => el.qte).reduce((a, b) => a + b, 0),
                totalGiftQte: orders
                  .map((el) => el.giftQte)
                  .reduce((a, b) => a + b, 0),
                mcInvoiceDiscount: discount,
                mcOrdersDiscount: 0,
                mcFinalDiscount: 0,
                note: invoiceNote,
                driver,
                sellOrders: sellOrders,
                idemPotentToken: idemPotent,
                sellReturnOrders: returnOrders,
              }
            : {
                invoiceNumber,
                voucherNumber: invoiceNumber,
                address,
                customerId: selectedCustomer?.id,
                date: moment(selectedDate).add(3, "hours"),
                scTotalPrice: orders
                  .map((el) => parseFloat(el.scSellPrice) * parseFloat(el.qte))
                  .reduce((a, b) => a + b, 0),
                scFinalPrice:
                  orders
                    .map(
                      (el) => parseFloat(el.scSellPrice) * parseFloat(el.qte)
                    )
                    .reduce((a, b) => a + b, 0) - discount,
                isCash: cashPayment,
                payByMain: false,
                totalQte: orders.map((el) => el.qte).reduce((a, b) => a + b, 0),
                totalGiftQte: orders
                  .map((el) => el.giftQte)
                  .reduce((a, b) => a + b, 0),
                scInvoiceDiscount: discount,
                scOrdersDiscount: 0,
                scFinalDiscount: 0,
                note: invoiceNote,
                driver,
                sellOrders: sellOrders,
                idemPotentToken: idemPotent,
                sellReturnOrders: returnOrders,
              }
        )
          .then((res) => {
            if (invoice === "return") {
              localStorage.removeItem("InvoiceReturnOrders");
            } else {
              localStorage.removeItem("InvoiceOrders");
            }
            redirect(
              invoice !== "return"
                ? "/SellInvoices/" + res.data.id + "/show"
                : "/SellReturnInvoices"
            );
          })
          .catch((err) => {
            setIsLoading(false);
            notify(err.response.data.message, {
              type: "error",
              undoable: true,
            });
          });
      } else {
        swal(translate("resources.root.createCancel"));
      }
    });
  };
  const update = () => {
    setSubmitAttempted(true);

    Swal.fire({
      title: translate("resources.root.makeSure"),
      text: translate("resources.root.sureUpdate"),
      confirmButtonText: translate("resources.root.yes"),
      cancelButtonText: translate("resources.root.no"),
      showDenyButton: true,
      confirmButtonColor: "#1e6fc3",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      setDelay(true);
      if (result.isConfirmed) {
        setIsLoading(true);
        let sellOrders, returnOrders;
        if (invoice === "return") {
          returnOrders = orders;
        } else {
          sellOrders = orders;
        }
        HTTP.put(
          `/${
            invoice === "return" ? "SellReturnInvoices" : "SellInvoices"
          }/${id}`,
          selectedCustomer?.payLoanByMain
            ? {
                address,
                customerId: selectedCustomer?.id,
                date: moment(selectedDate).add(3, "hours"),
                mcTotalPrice: orders
                  .map((el) => parseFloat(el.mcSellPrice) * parseFloat(el.qte))
                  .reduce((a, b) => a + b, 0),
                mcFinalPrice:
                  orders
                    .map(
                      (el) => parseFloat(el.mcSellPrice) * parseFloat(el.qte)
                    )
                    .reduce((a, b) => a + b, 0) - discount,
                isCash: cashPayment,
                payByMain: true,
                totalQte: orders.map((el) => el.qte).reduce((a, b) => a + b, 0),
                totalGiftQte: orders
                  .map((el) => el.giftQte)
                  .reduce((a, b) => a + b, 0),
                mcInvoiceDiscount: discount,
                mcOrdersDiscount: 0,
                mcFinalDiscount: 0,
                note: invoiceNote,
                driver,
                sellOrders: sellOrders,
                idemPotentToken: idemPotent,
                sellReturnOrders: returnOrders,
              }
            : {
                address,
                customerId: selectedCustomer?.id,
                date: moment(selectedDate).add(3, "hours"),
                scTotalPrice: orders
                  .map((el) => parseFloat(el.scSellPrice) * parseFloat(el.qte))
                  .reduce((a, b) => a + b, 0),
                scFinalPrice:
                  orders
                    .map(
                      (el) => parseFloat(el.scSellPrice) * parseFloat(el.qte)
                    )
                    .reduce((a, b) => a + b, 0) - discount,
                isCash: cashPayment,
                payByMain: false,
                totalQte: orders.map((el) => el.qte).reduce((a, b) => a + b, 0),
                totalGiftQte: orders
                  .map((el) => el.giftQte)
                  .reduce((a, b) => a + b, 0),
                scInvoiceDiscount: discount,
                scOrdersDiscount: 0,
                scFinalDiscount: 0,
                note: invoiceNote,
                driver,
                sellOrders: sellOrders,
                idemPotentToken: idemPotent,
                sellReturnOrders: returnOrders,
              }
        )
          .then((res) => {
            if (invoice === "return") {
              localStorage.removeItem("InvoiceReturnOrders");
            } else {
              localStorage.removeItem("InvoiceOrders");
            }
            redirect(
              invoice !== "return"
                ? "/SellInvoices/" + res.data.id + "/show"
                : "/SellReturnInvoices"
            );
          })
          .catch((err) => {
            setIsLoading(false);
            notify(err.response.data.message, {
              type: "error",
              undoable: true,
            });
          });
      } else {
        swal(translate("resources.root.createCancel"));
      }
    });
  };
  const DeleteItem = (order) => {
    swal({
      title: translate("resources.root.makeSure"),
      text: translate("resources.root.confirmPerminantDelete"),
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        let tempOrders = orders;
        if (id) {
          HTTP.delete(`/sellOrders/${order.id}`)
            .then((response) => {
              tempOrders = tempOrders.filter(
                (el) => el.productId !== order.productId
              );
              setOrders(tempOrders);
            })
            .catch((error) => {
              notify(error.response.data.message, "error");
            });
        } else {
          tempOrders = tempOrders.filter(
            (el) => el.productId !== order.productId
          );
          setOrders(tempOrders);
        }
      }
    });
  };
  return (
    <main className="d-flex flex-column gap-4 w-100 p-3">
      <section className="d-flex flex-column gap-2">
        <div className="d-flex gap-4 justify-content-center align-items-center">
          <div className="flex-1">
            {localStorage.getItem("useOldSelect") === "true" ? (
              <Autocomplete
                style={{ flex: 1, flexBasis: 33.3333 }}
                className="w-100"
                id="combo-box-demo"
                options={customers}
                getOptionLabel={(option) =>
                  `${
                    option.fullName
                      ? option.fullName
                      : option.firstName + " " + option.middleName
                  } `
                }
                onChange={(el, val) => {
                  setDelay(false);
                  setSelectedCustomer(val);
                  setAddress(val?.address ?? "");
                  if (selectedProduct) {
                    setModifiedPrice(
                      val?.payLoanByMain
                        ? selectedProduct.mcSellPrice
                        : selectedProduct.scSellPrice
                    );
                  }
                }}
                renderInput={(params) => (
                  <MaterialTextField
                    {...params}
                    error={submitAttempted && !selectedCustomer}
                    label={translate("resources.root.chooseCustomer")}
                    variant="outlined"
                  />
                )}
              />
            ) : (
              <AsyncSelect
                defaultOptions
                menuPortalTarget={document.body}
                placeholder={`${translate("resources.root.chooseCustomer")}`}
                loadOptions={loadOptions.customers}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
                onChange={(el) => {
                  setDelay(false);
                  setSelectedCustomer(el?.customer);
                  setAddress(el?.customer?.address ?? "");

                  if (selectedProduct) {
                    setModifiedPrice(
                      el?.customer?.payLoanByMain
                        ? selectedProduct.mcSellPrice
                        : selectedProduct.scSellPrice
                    );
                  }
                }}
              />
            )}
          </div>
          <Form.Group style={{ flexBasis: 33.3333 }} className="h-100 flex-1">
            <div className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.date")} :
              </Form.Label>
              <Form.Control
                type="datetime-local"
                initialValue={selectedDate}
                defaultShow={true}
                dateFormat="YYYY-MM-DD"
                timeFormat="HH:mm:ss"
                onChange={(e) => {
                  setSelectedDate(
                    moment(e.target.value).format("YYYY-MM-DD HH:mm:ss")
                  );
                }}
              />
            </div>
          </Form.Group>
          <Form.Group style={{ flexBasis: 33.3333 }} className="h-100 flex-1">
            <div className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.invoiceNumber")} :
              </Form.Label>
              <Form.Control
                type="text"
                onChange={(e) => setInvoiceNumber(e.target.value)}
              />
            </div>
          </Form.Group>
          <Form.Group style={{ flexBasis: 33.3333 }} className="h-100 flex-1">
            <div className="d-flex align-items-center m-0 p-0">
              <Form.Label className="m-0 mr-2  text-nowrap">
                {translate("resources.root.address")} :
              </Form.Label>
              <Form.Control
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
          </Form.Group>
          <div>
            {!invoice && (
              <FormControlLabel
                control={
                  <Switch
                    checked={cashPayment}
                    onChange={(e) => {
                      setCashPayment(e.target.checked);
                      setDelay(false);
                    }}
                    color="primary"
                  />
                }
                label={translate("resources.root.cash")}
              />
            )}
          </div>
        </div>
        {selectedCustomer && (
          <div className=" d-flex justify-content-center align-items-center gap-3">
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.name")}: {selectedCustomer?.fullName}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.phone")}: {selectedCustomer?.phone}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.currentLoan")}:{" "}
                {selectedCustomer?.payLoanByMain ? (
                  <>
                    {selectedCustomer?.mcCurrentLoan?.toLocaleString()}{" "}
                    {translate("resources.root.usd")}
                  </>
                ) : (
                  <>
                    {selectedCustomer?.scCurrentLoan?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </>
                )}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {translate("resources.root.maxLoan")}:{" "}
                {selectedCustomer?.payLoanByMain ? (
                  <>
                    {selectedCustomer?.mcRoofLoan?.toLocaleString()}{" "}
                    {translate("resources.root.usd")}
                  </>
                ) : (
                  <>
                    {selectedCustomer?.scRoofLoan?.toLocaleString()}{" "}
                    {translate("resources.root.iqd")}
                  </>
                )}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white">
              <p className="p-0 m-0 ">
                {selectedCustomer &&
                  `${translate("resources.root.futureLoan")} ${(
                    roundToTwo(
                      selectedCustomer?.payLoanByMain
                        ? parseFloat(selectedCustomer?.mcCurrentLoan)
                        : parseFloat(selectedCustomer?.scCurrentLoan)
                    ) +
                    (invoice === "return" ? -1 : 1) *
                      (!cashPayment
                        ? parseFloat(
                            orders
                              .map((el) =>
                                selectedCustomer?.payLoanByMain
                                  ? parseFloat(el.mcSellPrice) * el.qte
                                  : parseFloat(el.scSellPrice) * el.qte
                              )
                              .reduce((a, b) => a + b, 0) - discount
                          )
                        : 0)
                  )?.toLocaleString()} ${
                    selectedCustomer?.payLoanByMain
                      ? translate("resources.root.usd")
                      : translate("resources.root.iqd")
                  }`}
              </p>
            </div>
          </div>
        )}
      </section>
      {selectedCustomer && (
        <section className="d-flex flex-column justify-content-center align-items-center gap-2 bg-gray-100 p-2 py-5">
          <div className="d-flex gap-2 justify-content-center align-items-center w-100">
            <div className="flex-2">
              {localStorage.getItem("useOldSelect") === "true" ? (
                <Autocomplete
                  id="combo-box-demo"
                  options={products}
                  getOptionLabel={(option) =>
                    `${option.name} - ${option.barcode}`
                  }
                  onChange={(el, val) => {
                    setSelectedProduct(val);
                    setOrderQtt(0);
                    setModifiedPrice(
                      val
                        ? selectedCustomer?.payLoanByMain
                          ? val.mcSellPrice
                          : val.scSellPrice
                        : 0
                    );
                  }}
                  renderInput={(params) => (
                    <MaterialTextField
                      {...params}
                      label={translate("resources.root.chooseProduct")}
                      variant="outlined"
                    />
                  )}
                />
              ) : (
                <AsyncSelect
                  defaultOptions
                  menuPortalTarget={document.body}
                  placeholder={`${translate("resources.root.chooseProduct")}`}
                  loadOptions={loadOptions.products}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  }}
                  onChange={(e) => {
                    setSelectedProduct(e.product);
                    setOrderQtt(1);
                    setModifiedPrice(
                      e.product
                        ? selectedCustomer?.payLoanByMain
                          ? e.product.mcSellPrice
                          : e.product.scSellPrice
                        : 0
                    );
                  }}
                />
              )}
            </div>
            {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
              <Autocomplete
                className="flex-1"
                id="combo-box-demo"
                options={productSerials}
                getOptionLabel={(option) => `${option.serialNumber}`}
                onChange={(el, val) => {
                  setProductSerial(val?.serialNumber ?? null);
                }}
                renderInput={(params) => (
                  <MaterialTextField
                    {...params}
                    label={translate("resources.root.chooseSerial")}
                    variant="outlined"
                  />
                )}
              />
            )}
            <MaterialTextField
              className="flex-1"
              type="number"
              label={translate("resources.root.quantity")}
              variant="outlined"
              disabled={!selectedProduct}
              value={orderQtt}
              onChange={(e) => {
                !invoice
                  ? ((parseFloat(e.target.value) +
                      orders
                        .filter((el) => el.productId === selectedProduct?.d)
                        .map(
                          (el) => parseFloat(el.qte) + parseFloat(el.giftQte)
                        )
                        .reduce((a, b) => a + b, 0) +
                      gift <=
                      (selectedProduct &&
                        parseFloat(selectedProduct.qteInStock)) &&
                      e.target.value >= 0) ||
                      selectedProduct.canSellBellowZero) &&
                    setOrderQtt(e.target.value)
                  : setOrderQtt(e.target.value);
              }}
            />
            <MaterialTextField
              className="flex-1"
              type="number"
              disabled={!selectedProduct}
              label={
                selectedCustomer?.payLoanByMain
                  ? translate("resources.root.priceInUSD")
                  : translate("resources.root.priceInIQD")
              }
              value={modifiedPrice}
              onChange={(e) => setModifiedPrice(parseFloat(e.target.value))}
              variant="outlined"
            />
            {/* &nbsp; */}
            <MaterialTextField
              className="flex-1"
              type="number"
              label={translate("resources.root.gifts")}
              disabled={!selectedProduct}
              value={gift}
              onChange={(e) =>
                parseFloat(e.target.value) +
                  orders
                    .filter((el) => el.productId === selectedProduct?.d)
                    .map((el) => parseFloat(el.qte) + parseFloat(el.giftQte))
                    .reduce((a, b) => a + b, 0) +
                  parseFloat(orderQtt) <=
                  (selectedProduct && parseFloat(selectedProduct.qteInStock)) &&
                e.target.value >= 0 &&
                setGift(parseFloat(e.target.value))
              }
              variant="outlined"
            />
            {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={orderDiscountInPercent}
                      onChange={(e) =>
                        setOrderDiscountInPercent(e.target.checked)
                      }
                      color="primary"
                    />
                  }
                  label={translate("resources.root.inPercentage")}
                />
                <MaterialTextField
                  type="number"
                  label={translate("resources.root.discountPer")}
                  disabled={
                    !orderDiscountInPercent ||
                    !selectedProduct ||
                    percentageDiscount > 0
                  }
                  value={orderDiscountPercentage}
                  variant="filled"
                  onChange={(e) => {
                    e.target.value >= 0 &&
                      e.target.value <= 100 &&
                      setOrderDiscountPercentage(e.target.value);
                    setOrderDiscount(
                      (e.target.value / 100) * (modifiedPrice * orderQtt)
                    );
                  }}
                />
                <MaterialTextField
                  type="number"
                  label={translate("resources.root.discount")}
                  disabled={
                    orderDiscountInPercent || !selectedProduct || discount > 0
                  }
                  value={orderDiscount}
                  variant="filled"
                  onChange={(e) => {
                    e.target.value >= 0 && setOrderDiscount(e.target.value);
                    setOrderDiscountPercentage(
                      (e.target.value / (modifiedPrice * orderQtt)) * 100
                    );
                  }}
                />
              </div>
            )}
            <div className="btn btn-outline-primary cursor-normal flex-1">
              <p className="p-0 m-0">
                {translate("resources.root.totalPrice")}:{" "}
                {selectedCustomer?.payLoanByMain
                  ? `${(modifiedPrice > 0
                      ? roundToTwo(modifiedPrice * orderQtt) || 0
                      : roundToTwo(selectedProduct?.mcSellPrice * orderQtt) || 0
                    )?.toLocaleString()}
              ${translate("resources.root.usd")}`
                  : `${(modifiedPrice > 0
                      ? roundToTwo(modifiedPrice * orderQtt) || 0
                      : roundToTwo(selectedProduct?.scSellPrice * orderQtt) || 0
                    )?.toLocaleString()}
               ${translate("resources.root.iqd")}`}
              </p>
            </div>
            <button
              type="button"
              className="btn btn-primary btn-block m-0 flex-1"
              disabled={
                (parseFloat(orderQtt) <= 0 && parseFloat(gift) <= 0) ||
                !selectedProduct ||
                !selectedCustomer
              }
              onClick={minMaxPriceHandler}
            >
              {translate("resources.root.addOrder")}
            </button>
          </div>
          {selectedProduct && (
            <div className=" d-flex justify-content-center align-items-center gap-3">
              <div className="rounded p-1 px-3  bg-primary text-white fs-6">
                <p className="p-0 m-0 ">
                  {translate("resources.root.productMeasure")}:{" "}
                  {selectedProduct.productMeasure}
                </p>
              </div>
              <div className="rounded p-1 px-3  bg-primary text-white fs-6">
                <p className="p-0 m-0 ">
                  {translate("resources.root.oneMeasure")}:{" "}
                  {selectedProduct.oneMeasure}
                </p>
              </div>
              <div className="rounded p-1 px-3  bg-primary text-white fs-6">
                {translate("resources.root.oneContains")}:{" "}
                {selectedProduct.oneContains}{" "}
                {translate("resources.root.items")}
              </div>

              <div className="rounded p-1 px-3  bg-primary text-white fs-6">
                <p className="p-0 m-0 ">
                  {translate("resources.root.currency")}:{" "}
                  {selectedCustomer?.payLoanByMain
                    ? translate("resources.root.usd")
                    : translate("resources.root.iqd")}
                </p>
              </div>
              {previousPrice?.sell && (
                <div className="rounded p-1 px-3  bg-success text-white fs-6">
                  <p className="p-0 m-0 ">
                    {translate("resources.root.previousSellPrice")}:{" "}
                    {selectedCustomer?.payLoanByMain
                      ? `${previousPrice?.sell.mcSellPrice?.toLocaleString()} ${translate(
                          "resources.root.usd"
                        )}`
                      : `${previousPrice?.sell.scSellPrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </p>
                </div>
              )}
              {previousPrice?.purchase && (
                <div className="rounded p-1 px-3  bg-success text-white fs-6">
                  <p className="p-0 m-0 ">
                    {translate("resources.root.previousPurchasePrice")}:{" "}
                    {selectedCustomer?.payLoanByMain
                      ? `${previousPrice?.purchase.mcPurchasePrice?.toLocaleString()} ${translate(
                          "resources.root.usd"
                        )}`
                      : `${previousPrice?.purchase.scPurchasePrice?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`}
                  </p>
                </div>
              )}
              <div className="rounded p-1 px-3  bg-primary text-white">
                <p className="p-0 m-0 ">
                  {translate("resources.root.purchasePrice")}:{" "}
                  {selectedCustomer?.payLoanByMain
                    ? `${selectedProduct.mcPurchasePrice?.toLocaleString()} ${translate(
                        "resources.root.usd"
                      )}`
                    : `${selectedProduct.scPurchasePrice?.toLocaleString()} ${translate(
                        "resources.root.iqd"
                      )}`}
                </p>
              </div>
              <div className="rounded p-1 px-3  bg-primary text-white">
                <p className="p-0 m-0 ">
                  {translate("resources.root.max")}:{" "}
                  {selectedProduct.qteInStock?.toLocaleString()}
                </p>
              </div>
            </div>
          )}
          <MaterialTextField
            variant="outlined"
            className="w-100 text-dark flex-1"
            type="text"
            label={translate("resources.root.note")}
            value={note}
            onChange={(e) => setNote(e.target.value)}
            multiline={true}
          />
        </section>
      )}
      {orders?.length > 0 && (
        <section className="d-flex flex-column gap-2">
          <TableContainer component={Paper}>
            <Table stickyHeader size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>#</TableCell>
                  <TableCell>{translate("resources.root.id")}</TableCell>
                  <TableCell>{translate("resources.root.barcode")}</TableCell>
                  <TableCell align="start">
                    {translate("resources.root.name")}
                  </TableCell>
                  {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                    <TableCell align="start">
                      {translate("resources.root.serial")}
                    </TableCell>
                  )}
                  <TableCell align="start">
                    {translate("resources.root.quantity")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.gifts")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.price")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.purchasePrice")}
                  </TableCell>
                  {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                    <TableCell align="start">
                      {translate("resources.root.discount")}
                    </TableCell>
                  )}
                  <TableCell align="start">
                    {translate("resources.root.totalPrice")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.note")}
                  </TableCell>
                  <TableCell align="start">
                    {translate("resources.root.actions")}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {orders &&
                  orders.map((order, index) => (
                    <TableRow
                      style={{
                        backgroundColor:
                          order.mcPurchasePrice > order.mcSellPrice &&
                          "#FA5C5C",
                      }}
                      key={index}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {order?.productId}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {order.barcode || order.product.barcode}
                      </TableCell>
                      <TableCell align="start">
                        {order.name || order.product.name}
                      </TableCell>
                      {process.env.REACT_APP_PRODUCT_SERIAL === "true" && (
                        <TableCell align="start">
                          {order.productSerial?.serialNumber}
                        </TableCell>
                      )}
                      <TableCell
                        onDoubleClick={() => {
                          if (selectedCustomer) {
                            Swal.fire({
                              title: "Change order Quantity",
                              input: "number",
                              inputValue: order.qte,
                              showCancelButton: true,
                              focusConfirm: true,
                              confirmButtonColor: "#117baf",
                              cancelButtonColor: "#B71316",
                              inputValidator: (value) => {
                                if (!value) {
                                  return "You need to write something!";
                                }
                              },
                              confirmButtonText: translate(
                                "resources.root.submit"
                              ),

                              preConfirm: (value) => {
                                changeCol(
                                  order.productId,
                                  parseFloat(value),
                                  "qte"
                                );
                              },
                            });
                          } else {
                            Swal.fire({
                              title: "Pleae Select the Customer",
                              text: "You can't change the item details unless you select the customer",
                              icon: "warning",
                            });
                          }
                        }}
                        align="start"
                      >
                        {order.qte}
                      </TableCell>
                      <TableCell align="start">{order.giftQte}</TableCell>
                      <TableCell
                        onDoubleClick={() => {
                          if (selectedCustomer) {
                            Swal.fire({
                              title: "Change order Sell Price",
                              input: "number",
                              inputValue: selectedCustomer.payLoanByMain
                                ? order.mcSellPrice
                                : order.scSellPrice,
                              showCancelButton: true,
                              focusConfirm: true,
                              confirmButtonColor: "#117baf",
                              cancelButtonColor: "#B71316",
                              inputValidator: (value) => {
                                if (!value) {
                                  return "You need to write something!";
                                }
                              },
                              confirmButtonText: translate(
                                "resources.root.submit"
                              ),

                              preConfirm: (value) => {
                                changeCol(
                                  order.productId,
                                  parseFloat(value),
                                  selectedCustomer?.payLoanByMain
                                    ? "mcSellPrice"
                                    : "scSellPrice"
                                );
                              },
                            });
                          } else {
                            Swal.fire({
                              title: "Pleae Select the Customer",
                              text: "You can't change the item details unless you select the customer",
                              icon: "warning",
                            });
                          }
                        }}
                        align="start"
                      >
                        {selectedCustomer?.payLoanByMain
                          ? roundToTwo(order.mcSellPrice)?.toLocaleString()
                          : roundToTwo(
                              order.scSellPrice
                            )?.toLocaleString()}{" "}
                      </TableCell>
                      <TableCell align="start">
                        {selectedCustomer?.payLoanByMain
                          ? roundToTwo(
                              order.mcPurchasePrice,
                              3
                            )?.toLocaleString()
                          : roundToTwo(
                              order.scPurchasePrice,
                              3
                            )?.toLocaleString()}
                      </TableCell>
                      {process.env.REACT_APP_ORDER_DISCOUNT === "true" && (
                        <TableCell align="start">
                          {roundToTwo(order.mcDiscount)?.toLocaleString()}
                        </TableCell>
                      )}
                      <TableCell align="start">
                        {selectedCustomer?.payLoanByMain
                          ? roundToTwo(
                              order?.mcTotalPrice || order?.mcLastPrice
                            )?.toLocaleString()
                          : roundToTwo(
                              order?.scTotalPrice || order?.scLastPrice
                            )?.toLocaleString()}
                      </TableCell>
                      <TableCell align="start">{order.note}</TableCell>
                      <TableCell align="start">
                        <DeleteIcon onClick={() => DeleteItem(order)} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <div className=" d-flex justify-content-center align-items-center gap-3">
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {selectedCustomer?.payLoanByMain
                  ? `${translate("resources.root.totalPrice")}: ${orders
                      .map(
                        (el) =>
                          parseFloat(el.mcSellPrice) * parseFloat(el.qte) -
                          parseFloat(el.mcDiscount)
                      )
                      .reduce((a, b) => roundToTwo(a + b), 0)
                      ?.toLocaleString()} ${translate("resources.root.usd")}`
                  : `${translate("resources.root.totalPrice")}: ${orders
                      .map(
                        (el) =>
                          parseFloat(el.scSellPrice) * parseFloat(el.qte) -
                          parseFloat(el.scDiscount)
                      )
                      .reduce((a, b) => roundToTwo(a + b), 0)
                      ?.toLocaleString()} ${translate("resources.root.iqd")}`}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {selectedCustomer?.payLoanByMain
                  ? `${translate("resources.root.finalPrice")}: ${(
                      orders
                        .map(
                          (el) =>
                            parseFloat(el.mcSellPrice) * parseFloat(el.qte) -
                            parseFloat(el.mcDiscount)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0) - discount
                    )?.toLocaleString()} ${translate("resources.root.usd")}`
                  : `${translate("resources.root.finalPrice")}: ${(
                      orders
                        .map(
                          (el) =>
                            parseFloat(el.scSellPrice) * parseFloat(el.qte) -
                            parseFloat(el.scDiscount)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0) - discount
                    )?.toLocaleString()} ${translate("resources.root.iqd")}`}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {`${translate("resources.root.totalQuantity")}: ${orders
                  .map((el) => parseFloat(el.qte))
                  .reduce((a, b) => a + b, 0)
                  ?.toLocaleString()}`}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white fs-6">
              <p className="p-0 m-0 ">
                {`${translate("resources.root.totalGifts")}: ${orders
                  .map((el) => parseFloat(el.giftQte))
                  .reduce((a, b) => a + b, 0)
                  ?.toLocaleString()}`}
              </p>
            </div>
            <div className="rounded p-1 px-3  bg-primary text-white">
              <p className="p-0 m-0 ">
                {`${translate("resources.root.finalQuantity")}: ${orders
                  .map((el) => parseFloat(el.qte) + parseFloat(el.giftQte))
                  .reduce((a, b) => a + b, 0)
                  ?.toLocaleString()}`}
              </p>
            </div>
          </div>
        </section>
      )}
      {orders?.length > 0 && (
        <section className="d-flex flex-column justify-content-between bg-gray-100 p-2 py-5 gap-3">
          <div className=" d-flex justify-content-center align-items-center gap-3 ">
            <FormControlLabel
              className="m-0"
              control={
                <Switch
                  checked={discountInPercent}
                  onChange={(e) => setDiscountInPercent(e.target.checked)}
                  color="primary"
                />
              }
              label={translate("resources.root.inPercentage")}
            />
            <MaterialTextField
              type="number"
              color="primary"
              focused
              label={translate("resources.root.discountInPercentage")}
              disabled={!discountInPercent || orderDiscountPercentage > 0}
              value={
                discountInPercent
                  ? roundToTwo(percentageDiscount)
                  : roundToTwo(
                      parseFloat(
                        roundToTwo(
                          (discount * 100) /
                            orders
                              .map(
                                (el) =>
                                  parseFloat(
                                    selectedCustomer?.payLoanByMain
                                      ? el.mcSellPrice
                                      : el.scSellPrice
                                  ) * parseFloat(el.qte)
                              )
                              .reduce((a, b) => roundToTwo(a + b), 0)
                        )
                      )
                    )
              }
              variant="outlined"
              onChange={(e) => {
                e.target.value >= 0 &&
                  e.target.value <= 100 &&
                  setPercentageDiscount(e.target.value);
                setDiscount(
                  roundToTwo(
                    (orders
                      .map(
                        (el) =>
                          parseFloat(
                            selectedCustomer?.payLoanByMain
                              ? el.mcSellPrice
                              : el.scSellPrice
                          ) * parseFloat(el.qte)
                      )
                      .reduce((a, b) => a + b, 0) /
                      100) *
                      e.target.value
                  )
                );
              }}
            />
            <MaterialTextField
              type="number"
              color="primary"
              focused
              label={translate("resources.root.discount")}
              disabled={discountInPercent || orderDiscount > 0}
              value={
                !discountInPercent
                  ? roundToTwo(discount)
                  : roundToTwo(
                      (orders
                        .map(
                          (el) =>
                            parseFloat(
                              selectedCustomer?.payLoanByMain
                                ? el.mcSellPrice
                                : el.scSellPrice
                            ) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => a + b, 0) /
                        100) *
                        percentageDiscount
                    )
              }
              variant="outlined"
              onChange={(e) => {
                e.target.value >= 0 &&
                  e.target.value <=
                    orders
                      .map(
                        (el) =>
                          parseFloat(
                            selectedCustomer?.payLoanByMain
                              ? el.mcSellPrice
                              : el.scSellPrice
                          ) * parseFloat(el.qte)
                      )
                      .reduce((a, b) => a + b, 0) &&
                  setDiscount(e.target.value);
                setPercentageDiscount(
                  parseFloat(
                    (discount * 100) /
                      orders
                        .map(
                          (el) =>
                            parseFloat(
                              selectedCustomer?.payLoanByMain
                                ? el.mcSellPrice
                                : el.scSellPrice
                            ) * parseFloat(el.qte)
                        )
                        .reduce((a, b) => roundToTwo(a + b), 0)
                  )
                );
              }}
            />{" "}
            <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
              <Alert onClose={handleClose} severity="error">
                {errorMessage}
              </Alert>
            </Snackbar>{" "}
            <MaterialTextField
              variant="outlined"
              label={translate("resources.root.note")}
              value={invoiceNote}
              onChange={(e) => setInvoiceNote(e.target.value)}
            />
            <MaterialTextField
              variant="outlined"
              label={
                process.env.REACT_APP_APP_NAME === "Techeye" &&
                selectedCustomer?.id === 35
                  ? translate("resources.root.customer")
                  : translate("resources.root.driver")
              }
              value={driver}
              onChange={(e) => setDriver(e.target.value)}
            />
          </div>
          <CardActions>
            {/* Submit the invoice to database */}
            <div className="d-flex justify-content-center align-items-center gap-3 w-100">
              <button
                type="button"
                className="btn btn-primary btn-block m-0"
                disabled={delay || !selectedCustomer}
                onClick={id ? update : submit}
              >
                {id
                  ? translate("resources.root.update")
                  : translate("resources.root.create")}
              </button>
              <button
                type="button"
                className="btn btn-outline-primary btn-block m-0"
                onClick={() => {
                  swal({
                    title: translate("resources.root.makeSure"),
                    text: translate("resources.root.confirmCreateNewInvoice"),
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                  }).then((willDelete) => {
                    if (willDelete) {
                      setOrders([]);
                    } else {
                      swal(translate("resources.root.createCancel"));
                    }
                  });
                }}
              >
                {translate("resources.root.clearOrders")}
              </button>
            </div>
          </CardActions>
        </section>
      )}

      {isLoading && <LoadingScreen />}
    </main>
  );
};

export default CreateSellInvoice;
