import { HTTP } from "./axios";

const loadOptions = {
  customers: async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(`/customers?_end=100&search=${inputValue}`);
      let newArray = [];
      response.data.map((customer) => {
        newArray.push({
          value: customer.id,
          label: `${customer.firstName} ${customer.middleName} ${customer.lastName}`,
          customer: customer,
        });
      });
      callback(newArray);
    }
  },
  suppliers: async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(`/suppliers?_end=100&search=${inputValue}`);
      let newArray = [];
      response.data.map((supplier) => {
        newArray.push({
          value: supplier.id,
          label: `${supplier.supplierName} |  ${supplier.phone}`,
          supplier: supplier,
        });
      });
      callback(newArray);
    }
  },
  products: async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(`/Products?_end=100&search=${inputValue}`);
      let newArray = [];
      response.data.map((product) => {
        newArray.push({
          value: product.id,
          label: product.name,
          product: product,
        });
      });
      callback(newArray);
    }
  },
  sellInvoices: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/SellInvoices?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((invoice) => {
        newArray.push({
          value: invoice.id,
          label: `${invoice.invoiceNumber} (${invoice.customerName})`,
          invoice: invoice,
        });
      });
      callback(newArray);
    }
  },
  categories: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/ProductCategories?_end=10&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((category) => {
        newArray.push({
          value: category.id,
          label: category.name,
          category: category,
        });
      });
      callback(newArray);
    }
  },
  users: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(`/Users?_end=5000&search=${inputValue}`);
      let newArray = [];
      response.data.map((user) => {
        newArray.push({
          value: user.id,
          label: `${user.firstName} ${user.middleName} (${user.userType})`,
          user: user,
        });
      });
      callback(newArray);
    }
  },
  cashers: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/UserStoreBankAccounts?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((cashier) => {
        newArray.push({
          value: cashier.id,
          label: `${cashier.firstName} ${
            cashier.middleName
          } (${cashier.phone.replace("+964", "0")})`,
          cashier: cashier,
        });
      });
      callback(newArray);
    }
  },
  quickSellInvoices: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/QuickSellInvoices/GetDistinctNames?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((name) => {
        newArray.push({
          value: name,
          label: `${name}`,
          name: name,
        });
      });
      callback(newArray);
    }
  },
  doctors: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(`/Doctors?_end=50&search=${inputValue}`);
      let newArray = [];
      newArray.push({
        value: 0,
        label: "none",
        productCategory: "none",
      });
      response.data.map((productCategory) => {
        newArray.push({
          value: productCategory.id,
          label: productCategory.name,
          productCategory: productCategory,
        });
      });
      callback(newArray);
    }
  },
  productDamageTypes: async (inputValue, callback) => {
    if (inputValue.length > 0) {
      let response = await HTTP.get(`/ProductDamageTypes`);
      let newArray = [];
      response.data.map((damageType) => {
        newArray.push({
          value: damageType.id,
          label: damageType.title,
          damageType: damageType,
        });
      });
      callback(newArray);
    }
  },
  brands: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(`/Brands?_end=100&search=${inputValue}`);
      let newArray = [];
      response.data.map((brand) => {
        newArray.push({
          value: brand.id,
          label: `${brand.name}`,
          brand: brand,
        });
      });
      callback(newArray);
    }
  },
  expenseTypes: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/ExpenseTypes?_end=10000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((expenseCategory) => {
        newArray.push({
          value: expenseCategory.id,
          label: expenseCategory.label,
          expenseCategory: expenseCategory,
        });
      });
      callback(newArray);
    }
  },
  depositTypes: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/DepositTypes?_end=10000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((expenseCategory) => {
        newArray.push({
          value: expenseCategory.id,
          label: expenseCategory.label,
          expenseCategory: expenseCategory,
        });
      });
      callback(newArray);
    }
  },
  withdrawTypes: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/WithdrawTypes?_end=10000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((expenseCategory) => {
        newArray.push({
          value: expenseCategory.id,
          label: expenseCategory.label,
          expenseCategory: expenseCategory,
        });
      });
      callback(newArray);
    }
  },
  representatives: async (inputValue, callback) => {
    if (inputValue.length > 1) {
      let response = await HTTP.get(
        `/Representatives?_end=5000&search=${inputValue}`
      );
      let newArray = [];
      response.data.map((representative) => {
        newArray.push({
          value: representative.id,
          label: `${representative.firstName} ${
            representative.middleName
          } (${representative.phone.replace("+964", "0")})`,
          representative: representative,
        });
      });
      callback(newArray);
    }
  },
};
export default loadOptions;
