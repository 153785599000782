import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import "./Style.css";
import moment from "moment";
import { useTranslate } from "react-admin";

const Page = ({
  title,
  human,
  invoice,
  order,
  showPrice = true,
  showCustomer = true,
  isTransferInvoice = false,
}) => {
  const translate = useTranslate();

  let isSellInvoice = true;
  if (
    localStorage.getItem("isSpearateDesign") === "true" &&
    title === translate("resources.root.purchaseInvoice")
  ) {
    isSellInvoice = false;
  }

  const pageRefFromLocalStorage = {
    page: isSellInvoice ? "page2" : "page3",
    logo: isSellInvoice ? "logo2" : "logo3",
    invoiceDescription: isSellInvoice
      ? "invoiceDescription2"
      : "invoiceDescription3",
    infoSize: isSellInvoice ? "infoSize2" : "infoSize3",
    infoRefresh: isSellInvoice ? "infoRefresh2" : "infoRefresh3",
    addressPhone: isSellInvoice ? "addressPhone2" : "addressPhone3",
    show: isSellInvoice ? "show2" : "show3",
  };
  const page = {
    Height:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))?.Height ||
      37,
    Width:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))?.Width ||
      25.9,
    FontSize:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))
        ?.FontSize || 15,

    Color:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))?.Color ||
      "#2171c6",
    TextColor:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))
        ?.TextColor || "#fff",
    OddCellColor:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))
        ?.OddCellColor || "#fff",
    EvenCellColor:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.page))
        ?.EvenCellColor || "#E6E6E6",
  };
  const logo = {
    Url:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))?.Url ||
      "https://i.ibb.co/k0X12vp/logo.jpg",
    Xaxis:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))?.Xaxis ||
      0,
    Yaxis:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))?.Yaxis ||
      25,
    Width:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))?.Width ||
      100,
    Height:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))?.Height ||
      100,
    Radius:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))?.Radius ||
      25,
    HeaderHight:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.logo))
        ?.HeaderHight || 200,
  };
  const addressPhone = {
    Size:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.addressPhone))
        ?.Size || 18,
    Xaxis:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.addressPhone))
        ?.Xaxis || 125,
    Yaxis:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.addressPhone))
        ?.Yaxis || 25,
  };
  const invoiceDescription = {
    Size:
      JSON.parse(
        localStorage.getItem(pageRefFromLocalStorage.invoiceDescription)
      )?.Size || 18,
    Xaxis:
      JSON.parse(
        localStorage.getItem(pageRefFromLocalStorage.invoiceDescription)
      )?.Xaxis || 15,
    Yaxis:
      JSON.parse(
        localStorage.getItem(pageRefFromLocalStorage.invoiceDescription)
      )?.Yaxis || 150,
  };

  const infoSize = {
    Size:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoSize))
        ?.Size || 18,
    Xaxis:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoSize))
        ?.Xaxis || 600,
    Yaxis:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoSize))
        ?.Yaxis || 75,
  };

  const infoRefresh = {
    Phone1:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.Phone1 || "00000000",
    Phone2:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.Phone2 || "00000000",
    KurdishAddress:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.KurdishAddress || "KurdishAddress",
    ArabicAddress:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.ArabicAddress || "ArabicAddress",
    EnglishAddress:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.EnglishAddress || "EnglishAddress",
    KurdishDescription:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.KurdishDescription || " KurdishDescription",
    ArabicDescription:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.ArabicDescription || "ArabicDescription",
    EnglishDescription:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.infoRefresh))
        ?.EnglishDescription || "EnglishDescription",
  };

  const show = {
    Barcode:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Barcode ??
      false,

    OrderCounter:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.OrderCounter ?? false,

    FirstIdentifier:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.FirstIdentifier ?? false,

    Description:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.Description ?? false,

    ProductMeasure:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.ProductMeasure ?? false,

    Price:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Price ??
      false,

    TotalPrice:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.TotalPrice ?? false,

    SmallPrice:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.SmallPrice ?? false,

    SmallMeasureQte:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.SmallMeasureQte ?? false,

    Image:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Image ??
      false,
    Gift:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Gift ??
      false,
    Discount:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.Discount ?? false,
    Serial:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Serial ??
      false,
    Type:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Type ??
      false,

    CurrentLoan:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.CurrentLoan ?? false,

    PreviousLoan:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.PreviousLoan ?? false,
    PreferName:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.PreferName ?? false,

    TotalInvoicePrice:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.TotalInvoicePrice ?? false,

    TotalQuantity:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))
        ?.TotalQuantity ?? false,

    Note:
      JSON.parse(localStorage.getItem(pageRefFromLocalStorage.show))?.Note ??
      false,
  };
  return (
    <div
      className="page "
      style={{
        width: `${page?.Width}cm`,
        height: `${page?.Height}cm`,
        textAlign:
          localStorage.getItem("direction") === "rtl" ? "right" : "left",
      }}
    >
      <div
        className="position-absolute"
        style={{
          marginInlineStart: `${logo?.Xaxis}px`,
          marginTop: `${logo?.Yaxis}px`,
        }}
      >
        <img
          className="img-fluid object-contain"
          style={{
            borderRadius: `${logo?.Radius}%`,
            width: `${logo?.Width}px`,
            height: `${logo?.Height}px`,
          }}
          src={logo?.Url}
          alt="logo"
        />
      </div>
      <div
        className="d-block position-absolute "
        style={{
          marginInlineStart: `${addressPhone?.Xaxis}px`,
          marginTop: `${addressPhone?.Yaxis}px`,
          fontSize: `${addressPhone?.Size}px`,
        }}
      >
        <div className="mb-1">{infoRefresh?.EnglishAddress}</div>
        <div>{infoRefresh?.Phone1}</div>
        <div>{infoRefresh?.Phone2}</div>
      </div>
      <div
        className="d-block position-absolute "
        style={{
          marginInlineStart: `${infoSize?.Xaxis}px`,
          marginTop: `${infoSize?.Yaxis}px`,
          fontSize: `${infoSize?.Size}px`,
        }}
      >
        <span style={{ fontWeight: "bold" }}>{title || "Title"}</span>
        <br />
        {translate("resources.root.date")}:{" "}
        <span className="bold">
          {moment(invoice?.date).format("YYYY-MM-DD - hh:mm") || "24-08-2021"}
        </span>
        <br />
        {translate("resources.root.invoiceNumber")}:{" "}
        <span className="bold">
          {invoice?.invoiceNumber?.toUpperCase() || "125488"}
        </span>
        <br />
        <div className={!show?.Serial && "d-none"}>
          {translate("resources.root.id")}:{" "}
          <span className="bold">{invoice?.id || "125514"}</span>
        </div>
        <div className={!show?.Type && "d-none"}>
          {translate("resources.root.type")}:{" "}
          <span className="bold">
            {invoice?.isCash
              ? translate("resources.root.cash")
              : translate("resources.root.debt")}{" "}
          </span>
        </div>
      </div>
      <div
        className="position-absolute"
        style={{
          marginTop: `${invoiceDescription?.Yaxis}px`,
          marginInlineStart: `${invoiceDescription?.Xaxis}px`,
          fontSize: `${invoiceDescription?.Size}px`,
        }}
      >
        {infoRefresh?.EnglishDescription}
      </div>

      <div
        style={{
          marginTop: `${logo?.HeaderHight}px`,
        }}
      >
        {showCustomer && (
          <Row
            className="mb-3 px-3 headerLine border-0"
            style={{
              backgroundColor: page?.Color,
              color: page?.TextColor,
            }}
          >
            <Col>
              {translate("resources.root.name")}:{" "}
              {process.env.REACT_APP_APP_NAME === "Techeye" &&
              (human?.id === 35 || human?.id === 142) ? (
                <span className="bold">{human?.driver}</span>
              ) : (
                <span className="bold">
                  {human
                    ? typeof human === "string" || human instanceof String
                      ? human
                      : human?.firstName
                      ? human?.firstName + " " + human?.middleName
                      : human?.supplierName
                      ? human?.supplierName
                      : human?.placeName
                    : "-"}
                </span>
              )}
              <br />
              {translate("resources.root.address")}:{" "}
              <span className="bold">{invoice?.address || "-"}</span>
            </Col>
            <Col>
              {translate("resources.root.phone")}:{" "}
              <span className="bold">{human?.phone || "-"}</span>
              <br />
              {process.env.REACT_APP_APP_NAME !== "Techeye" &&
                human?.id !== 35 &&
                human?.id !== 142 && (
                  <span>
                    {translate("resources.root.driver")}:{" "}
                    <span className="bold">{invoice?.driver || "-"}</span>
                  </span>
                )}
            </Col>
          </Row>
        )}
      </div>

      <div className="static">
        <table
          className="table-sell-invoice my-5"
          style={{
            fontSize: `${page.FontSize}px`,
          }}
        >
          <thead
            style={{
              backgroundColor: page?.Color,
              color: page?.TextColor,
            }}
          >
            <tr>
              <th>#</th>
              <th className={!show?.Barcode && "d-none"}>
                {translate("resources.root.barcode")}
              </th>
              <th className={!show?.FirstIdentifier && "d-none"}>
                {translate("resources.root.FirstIdentifier")}
              </th>
              <th className={!show?.Image && "d-none"}>
                {translate("resources.root.image")}
              </th>
              <th>{translate("resources.root.name")}</th>
              {isTransferInvoice ? (
                <th>{translate("resources.root.sentQty")}</th>
              ) : (
                <th>{translate("resources.root.quantity")}</th>
              )}
              <th className={!show?.SmallMeasureQte && "d-none"}>
                {translate("resources.root.smallQty")}
              </th>
              {show.Price && <th>{translate("resources.root.price")}</th>}{" "}
              {show.SmallPrice && (
                <th>{translate("resources.root.smallMeasurePrice")}</th>
              )}
              <th className={!show?.Gift && "d-none"}>
                {translate("resources.root.gift")}
              </th>
              {show.TotalPrice && (
                <th>{translate("resources.root.totalPrice")}</th>
              )}
            </tr>
          </thead>
          <tbody className="text-center">
            {order?.map((row, index) => {
              return (
                <tr
                  key={row.id}
                  style={{
                    textAlign: "start",
                    borderBottom: `2px solid ${page?.Color}`,
                    backgroundColor:
                      index % 2 === 0
                        ? page?.EvenCellColor
                        : page?.OddCellColor,
                  }}
                  className={`${index % 2 == 0 && ""}`}
                >
                  <td>{show?.OrderCounter ? index + 1 : row.id}</td>

                  <td className={!show?.Barcode && "d-none"}>
                    {row.product.barcode}
                  </td>

                  <td className={!show?.FirstIdentifier && "d-none"}>
                    {row.product.barcode1}
                  </td>
                  <td className={`p-1 ${!show?.Image && "d-none"}`}>
                    <img
                      src={process.env.REACT_APP_PATH + row.product.attachment}
                      className="img-fluid"
                      style={{ width: "75px", borderRadius: "5px" }}
                      alt={``}
                    />
                  </td>
                  <td>
                    {show?.PreferName
                      ? row?.product.preferName
                      : row.product.name}
                    <br />
                    {row.note && row.note.length > 0 && (
                      <span className="tiny">{row.note} </span>
                    )}
                    {row.note && row.note.length > 0 && <br />}
                    <span style={{ lineBreak: "anywhere" }}>
                      {show?.Description ? row.product.shortDescription : ""}
                    </span>
                  </td>
                  {isTransferInvoice ? (
                    <td>{row.sentQte}</td>
                  ) : (
                    <td>
                      {row.qte} {show?.ProductMeasure ? row.productMeasure : ""}
                    </td>
                  )}
                  <td className={!show?.SmallMeasureQte && "d-none"}>
                    {row.smallMeasureQte} {row.oneMeasure}
                  </td>
                  {show.Price && (
                    <td>
                      {invoice.purchaseOrders &&
                      invoice.purchaseOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcPurchasePrice?.toLocaleString()
                          : row.scPurchasePrice?.toLocaleString() + " IQD"
                        : ""}
                      {invoice.purchaseReturnOrders &&
                      invoice.purchaseReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcPurchasePrice?.toLocaleString()
                          : row.scPurchasePrice?.toLocaleString() + " IQD"
                        : ""}
                      {invoice.sellOrders && invoice.sellOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSellPrice?.toLocaleString()
                          : row.scSellPrice?.toLocaleString() + " IQD"
                        : ""}
                      {invoice.sellReturnOrders &&
                      invoice.sellReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSellPrice?.toLocaleString()
                          : row.scSellPrice?.toLocaleString() + " IQD"
                        : ""}

                      {invoice.representativeOrders &&
                      invoice.representativeOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSellPrice?.toLocaleString()
                          : row.scSellPrice?.toLocaleString() + " IQD"
                        : ""}
                      {invoice.representativeReturnOrders &&
                      invoice.representativeReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSellPrice?.toLocaleString()
                          : row.scSellPrice?.toLocaleString() + " IQD"
                        : ""}

                      {invoice.representativeCustomerSellOrders &&
                      invoice.representativeCustomerSellOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSellPrice?.toLocaleString()
                          : row.scSellPrice?.toLocaleString() + " IQD"
                        : ""}
                      {invoice.representativeCustomerReturnOrders &&
                      invoice.representativeCustomerReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSellPrice?.toLocaleString()
                          : row.scSellPrice?.toLocaleString() + " IQD"
                        : ""}
                    </td>
                  )}
                  {show.SmallPrice && (
                    <td className={!show?.SmallPrice && "d-none"}>
                      {invoice.purchaseOrders &&
                      invoice.purchaseOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " +
                            row.mcSmallMeasurePurchasePrice?.toLocaleString()
                          : row.scSmallMeasurePurchasePrice?.toLocaleString() +
                            " IQD"
                        : ""}
                      {invoice.purchaseReturnOrders &&
                      invoice.purchaseReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " +
                            row.mcSmallMeasurePurchasePrice?.toLocaleString()
                          : row.scSmallMeasurePurchasePrice?.toLocaleString() +
                            " IQD"
                        : ""}

                      {invoice.sellOrders && invoice.sellOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSmallMeasureSellPrice?.toLocaleString()
                          : row.scSmallMeasureSellPrice?.toLocaleString() +
                            " IQD"
                        : ""}
                      {invoice.sellReturnOrders &&
                      invoice.sellReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSmallMeasureSellPrice?.toLocaleString()
                          : row.scSmallMeasureSellPrice?.toLocaleString() +
                            " IQD"
                        : ""}

                      {invoice.representativeOrders &&
                      invoice.representativeOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSmallMeasureSellPrice?.toLocaleString()
                          : row.scSmallMeasureSellPrice?.toLocaleString() +
                            " IQD"
                        : ""}
                      {invoice.representativeReturnOrders &&
                      invoice.representativeReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSmallMeasureSellPrice?.toLocaleString()
                          : row.scSmallMeasureSellPrice?.toLocaleString() +
                            " IQD"
                        : ""}

                      {invoice.representativeCustomerSellOrders &&
                      invoice.representativeCustomerSellOrders.length > 0
                        ? invoice.payByMain
                          ? "$" + row.mcSmallMeasureSellPrice?.toLocaleString()
                          : row.scSmallMeasureSellPrice?.toLocaleString() +
                            " IQD"
                        : ""}
                      {invoice.representativeCustomerReturnOrders &&
                      invoice.representativeCustomerReturnOrders.length > 0
                        ? invoice.payByMain
                          ? "$ " + row.mcSmallMeasureSellPrice?.toLocaleString()
                          : row.scSmallMeasureSellPrice?.toLocaleString() +
                            " IQD"
                        : ""}
                    </td>
                  )}
                  {/* Gift Qty */}
                  <td className={!show?.Gift && "d-none"}>{row.giftQte}</td>
                  {show.TotalPrice && (
                    <td>
                      {invoice.payByMain
                        ? "$ " + row.mcLastPrice?.toLocaleString()
                        : row.scLastPrice?.toLocaleString() + " IQD"}
                    </td>
                  )}
                </tr>
              );
            }) || null}
          </tbody>
        </table>
        <div>
          <Row>
            {showPrice && (
              <Col>
                <span className={!show?.TotalInvoicePrice && "d-none"}>
                  {translate("resources.root.totalInvoicePrice")}: &nbsp;
                  <span className="bold">
                    {invoice.payByMain
                      ? invoice.mcTotalPrice &&
                        "$ " + invoice.mcTotalPrice?.toLocaleString()
                      : invoice.scTotalPrice &&
                        invoice.scTotalPrice?.toLocaleString() + " IQD"}
                  </span>
                  <br />
                </span>
                <span className={!show?.TotalQuantity && "d-none"}>
                  {translate("resources.root.totalNumber")}:{" "}
                  <span className="bold">
                    {isTransferInvoice
                      ? invoice?.totalSentQte
                      : invoice.totalQte}
                  </span>
                  <br />
                </span>
              </Col>
            )}
            <Col>
              {" "}
              <span className={!show?.Discount && "d-none"}>
                {translate("resources.root.discount")}:{" "}
                <span className="bold">
                  {invoice?.payByMain
                    ? invoice?.mcFinalDiscount &&
                      "$ " + invoice?.mcFinalDiscount?.toLocaleString()
                    : invoice?.scFinalDiscount &&
                      invoice?.scFinalDiscount?.toLocaleString() + " IQD"}
                </span>
                <br />
                {translate("resources.root.purePrice")}:{" "}
                <span className="bold">
                  {invoice.payByMain
                    ? invoice.mcFinalPrice &&
                      "$ " + invoice.mcFinalPrice?.toLocaleString()
                    : invoice.scFinalPrice &&
                      invoice.scFinalPrice?.toLocaleString() + " IQD"}
                </span>
                <br />
              </span>
            </Col>
            <Col>
              <span className={!show?.PreviousLoan && "d-none"}>
                {translate("resources.root.previousPrice")}:
                <span className="bold">
                  {invoice.payByMain
                    ? invoice.mcLoanBefore &&
                      "$ " + invoice.mcLoanBefore?.toLocaleString()
                    : invoice.scLoanBefore &&
                      invoice.scLoanBefore?.toLocaleString() + " IQD"}
                </span>
                <br />
              </span>

              <span className={!show?.CurrentLoan && "d-none"}>
                {translate("resources.root.currentLoan")}:{" "}
                <span className="bold">
                  {human
                    ? typeof human === "string" ||
                      human instanceof String ||
                      human === null
                      ? 0
                      : invoice?.payByMain
                      ? "$ " + human.mcCurrentLoan?.toLocaleString()
                      : human.scCurrentLoan?.toLocaleString() + " IQD"
                    : 0}
                </span>
              </span>
            </Col>
            <Col xs={12}>
              <span className={!show?.Note && "d-none"}>
                {translate("resources.root.note")}:{" "}
                <span className="bold">{invoice?.note || "-"}</span>
                <br />
              </span>
            </Col>
            {process.env.REACT_APP_APP_NAME === "SherkoNew" && (
              <Col
                xs={12}
                className="d-flex justify-content-around align-items-center gap-5 mt-5"
              >
                <p className="border-bottom border-dark px-5">
                  وەرگیرا لەلایەن
                </p>
                <p className="border-bottom border-dark px-5">
                  دروستکەری پسووڵە{" "}
                </p>
              </Col>
            )}
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Page;
