import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useSearchParams } from "react-router-dom";
import { Checkbox } from "@mui/material";
import _ from "lodash";
import loadOptions from "../../loadOptions";

export default () => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [params, setParams] = useSearchParams();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(params?.get("id") ? 10000 : 10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(params?.get("id") ? 10000 : 10);
  const [data, setData] = React.useState([]);
  const [dataRender, setDataRender] = React.useState([]);
  const [supplier, setSupplier] = React.useState(params?.get("id") || 0);
  const [supplierObject, setSupplierObject] = React.useState({});
  const [onlyDeposite, setOnlyDeposite] = React.useState(false);
  const [onlyWithdraw, setOnlyWithdraw] = React.useState(false);
  const [startDate, setStartDate] = React.useState(
    params?.get("id") ? null : moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    params?.get("id") ? null : moment(new Date()).format("YYYY-MM-DD")
  );
  const [startFromZero, setStartFromZero] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const [total, setTotal] = React.useState({
    mcPreviousBalance: 0,
    scPreviousBalance: 0,
    mcCurrentBalance: 0,
    scCurrentBalance: 0,
    mcInitialBalance: 0,
    scInitialBalance: 0,
    mcReturnInvoice: 0,
    scReturnInvoice: 0,
    mcSupplierInvoice: 0,
    scSupplierInvoice: 0,
    mcSupplierPayment: 0,
    scSupplierPayment: 0,
  });

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData(supplierObject) {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    await HTTP.get(
      `${url}/Reports/SupplierAccountStatement/${supplier}?_start=${start}&_end=${end}&zero=${startFromZero}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);

        let tempBalance = {
          mcCurrentBalance: response.headers.mccurrentbalance ?? 0,
          scCurrentBalance: response.headers.sccurrentbalance ?? 0,

          mcInitialBalance: response.headers.mcopenaccountbalance ?? 0,
          scInitialBalance: response.headers.scopenaccountbalance ?? 0,

          mcPreviousBalance: response.headers.mcpreviousbalance ?? 0,
          scPreviousBalance: response.headers.scpreviousbalance ?? 0,

          mcReturnInvoice: response.headers.mcreturninvoice ?? 0,
          scReturnInvoice: response.headers.screturninvoice ?? 0,

          mcSupplierInvoice: response.headers.mcsupplierinvoice ?? 0,
          scSupplierInvoice: response.headers.scsupplierinvoice ?? 0,

          mcSupplierPayment: response.headers.mcsupplierpayment ?? 0,
          scSupplierPayment: response.headers.scsupplierpayment ?? 0,
        };

        let tempData = [];
        if (onlyDeposite === false && onlyWithdraw === true) {
          tempData = response?.data?.filter((item) => item.isWithdraw);
        } else if (onlyDeposite === true && onlyWithdraw === false) {
          tempData = response?.data?.filter((item) => !item.isWithdraw);
        } else {
          tempData = response?.data;
        }

        if (tempData?.length > 0) {
          let firstBalance = startFromZero
            ? 0
            : supplierObject?.payLoanByMain
            ? startDate && endDate
              ? parseFloat(tempBalance?.mcPreviousBalance ?? 0)
              : parseFloat(tempBalance?.mcInitialBalance ?? 0)
            : startDate && endDate
            ? parseFloat(tempBalance?.scPreviousBalance ?? 0)
            : parseFloat(tempBalance?.scInitialBalance ?? 0);

          let newBalance = !tempData[0].isWithdraw
            ? firstBalance +
              parseFloat(
                supplierObject.payLoanByMain
                  ? tempData[0].mcWithdraw
                  : tempData[0].scWithdraw
              )
            : firstBalance -
              parseFloat(
                supplierObject.payLoanByMain
                  ? tempData[0].mcDeposit
                  : tempData[0].scDeposit
              );
          tempData[0].balance = newBalance;

          for (let i = 1; i < tempData?.length; i++) {
            let temp = !tempData[i].isWithdraw
              ? newBalance +
                parseFloat(
                  supplierObject.payLoanByMain
                    ? tempData[i].mcWithdraw
                    : tempData[i].scWithdraw
                )
              : newBalance -
                parseFloat(
                  supplierObject.payLoanByMain
                    ? tempData[i].mcDeposit
                    : tempData[i].scDeposit
                );
            tempData[i].balance = temp;
            newBalance = temp;
          }
        }

        setTotal(tempBalance);

        setData(tempData);
        setDataRender(tempData);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData(supplierObject);
    },
    [start, end, end, onlyDeposite, onlyWithdraw, startFromZero]
  );

  React.useEffect(
    function () {
      if (params?.get("id")) {
        HTTP.get(`${url}/Suppliers/${params.get("id")}`).then((response) => {
          setSupplierObject(response.data);
          getData(response.data);
        });
      }
    },
    [0]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div className="p-2">
      <PrintProvider>
        <Print single name={`supplierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.supplierStatement")}
              {supplier === 0 ? "" : "(" + supplierObject.supplierName + ")"}
            </h1>
          </div>
          <NoPrint force>
            <Row style={{ fontSize: "15px" }}>
              {isLoading && <LoadingScreen />}
              {!params?.get("id") && (
                <Col className="col-12 col-lg-3 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
                  <p className="m-0 mr-2 text-nowrap">
                    {translate("resources.root.supplier")} :
                  </p>
                  <AsyncSelect
                    defaultOptions
                    className="flex-grow-1"
                    loadOptions={loadOptions.suppliers}
                    onChange={(e) => {
                      setSupplier(e ? e.value : 0);
                      setSupplierObject(e ? e.supplier : 0);
                      setData([]);
                      setDataRender([]);
                    }}
                  />
                </Col>
              )}
              <Col
                className={`col-12 ${
                  params?.get("id") ? "col-lg-12" : "col-lg-8"
                } mt-4`}
              >
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
              <Col
                className={`col-lg-12 mt-4 row row-cols-3 justify-content-start align-items-center mx-auto`}
              >
                {startDate && startDate.length > 0 ? (
                  <h6>
                    {translate("resources.root.previousBalance")}:
                    {supplierObject?.payLoanByMain ? (
                      <>
                        ${" "}
                        {parseFloat(total?.mcPreviousBalance)?.toLocaleString()}
                      </>
                    ) : (
                      <>
                        {parseFloat(total?.scPreviousBalance)?.toLocaleString()}{" "}
                        {translate("resources.root.iqd")}
                      </>
                    )}
                  </h6>
                ) : (
                  <h6>
                    {translate("resources.root.initialBalance")}:{" "}
                    {supplierObject?.payLoanByMain ? (
                      <>
                        ${" "}
                        {parseFloat(total?.mcInitialBalance)?.toLocaleString()}
                      </>
                    ) : (
                      <>
                        {parseFloat(total?.scInitialBalance)?.toLocaleString()}{" "}
                        {translate("resources.root.iqd")}
                      </>
                    )}
                  </h6>
                )}
                <h6>
                  {translate("resources.root.currentBalance")}:{" "}
                  {supplierObject?.payLoanByMain ? (
                    <>
                      $ {parseFloat(total?.mcCurrentBalance)?.toLocaleString()}
                    </>
                  ) : (
                    <>
                      {parseFloat(total?.scCurrentBalance)?.toLocaleString()}{" "}
                      {translate("resources.root.iqd")}
                    </>
                  )}
                </h6>
                <h6>
                  {translate("resources.root.returnInvoice")}:{" "}
                  {supplierObject?.payLoanByMain ? (
                    <>
                      $ {parseFloat(total?.mcReturnInvoice)?.toLocaleString()}
                    </>
                  ) : (
                    <>
                      {parseFloat(total?.scReturnInvoice)?.toLocaleString()}{" "}
                      {translate("resources.root.iqd")}
                    </>
                  )}
                </h6>
                <h6>
                  {translate("resources.root.supplierInvoice")}:{" "}
                  {supplierObject?.payLoanByMain ? (
                    <>
                      $ {parseFloat(total?.mcSupplierInvoice)?.toLocaleString()}
                    </>
                  ) : (
                    <>
                      {parseFloat(total?.scSupplierInvoice)?.toLocaleString()}{" "}
                      {translate("resources.root.iqd")}
                    </>
                  )}
                </h6>
                <h6>
                  {translate("resources.root.supplierPayment")}:{" "}
                  {supplierObject?.payLoanByMain ? (
                    <>
                      $ {parseFloat(total?.mcSupplierPayment)?.toLocaleString()}
                    </>
                  ) : (
                    <>
                      {parseFloat(total?.scSupplierPayment)?.toLocaleString()}{" "}
                      {translate("resources.root.iqd")}
                    </>
                  )}
                </h6>
                <InputGroup className="row justify-content-start mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setStartFromZero(e.target.checked)}
                        defaultChecked={startFromZero}
                      />
                    }
                    label={translate("resources.root.startFromZero")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  variant={"primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    setIsLoading(true);
                    getData(supplierObject);
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>{" "}
              <Col className="col-3 p-0 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    control={
                      <Checkbox
                        disabled={data.length === 0}
                        value={onlyDeposite}
                        onChange={(e) => setOnlyDeposite(e.target.checked)}
                      />
                    }
                    label={translate("resources.root.withdraw")}
                  />
                  <FormControlLabel
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    control={
                      <Checkbox
                        disabled={data.length === 0}
                        value={onlyWithdraw}
                        onChange={(e) => setOnlyWithdraw(e.target.checked)}
                      />
                    }
                    label={translate("resources.root.deposit")}
                  />
                </InputGroup>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`m-2 mt-4`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    {supplierObject.payLoanByMain ? (
                      <>
                        <th>{translate("resources.root.dollarWithdraw")}</th>
                        <th>{translate("resources.root.dollarDeposit")}</th>
                      </>
                    ) : (
                      <>
                        <th>{translate("resources.root.dinarWithdraw")}</th>
                        <th>{translate("resources.root.dinarDeposit")}</th>
                      </>
                    )}
                    <th>{translate("resources.root.balance")}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr style={{ backgroundColor: "#dcf7f7" }}>
                    <td colSpan={6} className="text-center">
                      {startDate && startDate.length > 0 ? (
                        <span>
                          {translate("resources.root.previousBalance")}
                        </span>
                      ) : (
                        <span>
                          {translate("resources.root.initialBalance")}
                        </span>
                      )}
                    </td>

                    <td>
                      {!startFromZero ? (
                        startDate && startDate.length > 0 ? (
                          <span>
                            {supplierObject?.payLoanByMain ? (
                              <>
                                ${" "}
                                {parseFloat(
                                  total?.mcPreviousBalance
                                )?.toLocaleString()}
                              </>
                            ) : (
                              <>
                                {parseFloat(
                                  total?.scPreviousBalance
                                )?.toLocaleString()}{" "}
                                {translate("resources.root.iqd")}
                              </>
                            )}
                          </span>
                        ) : (
                          <span>
                            {supplierObject?.payLoanByMain ? (
                              <>
                                ${" "}
                                {parseFloat(
                                  total?.mcInitialBalance
                                )?.toLocaleString()}
                              </>
                            ) : (
                              <>
                                {parseFloat(
                                  total?.scInitialBalance
                                )?.toLocaleString()}{" "}
                                {translate("resources.root.iqd")}
                              </>
                            )}
                          </span>
                        )
                      ) : (
                        0
                      )}
                    </td>
                  </tr>
                  {!isLoading &&
                    dataRender &&
                    dataRender.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: one.isWithdraw
                              ? "#BFEAA3"
                              : "#FCE8E9",
                          }}
                        >
                          <td>{one.id}</td>
                          <td>
                            <a
                              href={
                                one.description === "Supplier Payment"
                                  ? `#/SupplierPayments/${one.id}/show`
                                  : one.description === "Return Invoice"
                                  ? `#/PurchaseReturnInvoices/${one.id}/show`
                                  : one.description === "Supplier Invoice"
                                  ? `#/PurchaseInvoices/${one.id}/show`
                                  : "#/"
                              }
                            >
                              {one.invoiceNumber &&
                                one.invoiceNumber.toUpperCase()}
                            </a>
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          {supplierObject.payLoanByMain ? (
                            <>
                              <td>$ {one.mcWithdraw.toLocaleString()}</td>
                              <td>$ {one.mcDeposit.toLocaleString()}</td>
                            </>
                          ) : (
                            <>
                              <td>{one.scWithdraw.toLocaleString()} IQD</td>
                              <td>{one.scDeposit.toLocaleString()} IQD</td>
                            </>
                          )}

                          <td>
                            {supplierObject.payLoanByMain ? (
                              <>$ {one.balance?.toLocaleString()}</>
                            ) : (
                              <>
                                {one.balance?.toLocaleString()} {""}{" "}
                                {translate("resources.root.iqd")}
                              </>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              {!params?.get("id") && (
                <NoPrint force>
                  <div style={{ position: "relative" }}>
                    <InputGroup style={{ width: "200px", float: "right" }}>
                      <InputGroup.Text>
                        {translate("resources.root.perPage")}
                      </InputGroup.Text>
                      <Form.Control
                        onChange={(e) => setPerPage(e.target.value)}
                        as="select"
                        custom
                      >
                        <option value={10}>10</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        <option value={200}>200</option>
                        <option value={500}>500</option>
                        <option value={1000}>1000</option>
                        <option value={2000}>2000</option>
                      </Form.Control>
                    </InputGroup>
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={perPage}
                      totalItemsCount={totalItems}
                      pageRangeDisplayed={5}
                      onChange={handlePageChange}
                      prevPageText={translate("resources.root.previous")}
                      nextPageText={translate("resources.root.next")}
                      itemClass="page-item"
                      linkClass="page-link"
                    />

                    <div className={`clearfix`}> </div>
                  </div>
                </NoPrint>
              )}
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
