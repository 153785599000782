import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  Create,
  SimpleForm,
  TextInput,
  Edit,
  EditButton,
  FunctionField,
  DateInput,
  BooleanInput,
  NumberInput,
  required,
  SelectInput,
  Tab,
  TabbedShowLayout,
  FormDataConsumer,
  Show,
  minValue,
  useRefresh,
  BooleanField,
  useNotify,
  useTranslate,
  ShowButton,
  ImageField,
  ImageInput,
} from "react-admin";
import { makeStyles } from "@mui/styles";
import { url, handleRequestResponse } from "../../request";
import MaterialTextField from "@mui/material/TextField";
import MaterialButton from "@mui/material/Button";
import { ListActions } from "../templates/ListActions";
import moment from "moment";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ClearIcon from "@mui/icons-material/Clear";
import CheckIcon from "@mui/icons-material/Check";
import IconButton from "@mui/material/IconButton";
import { FaTrash } from "react-icons/fa";
import swal from "sweetalert2";
import { MyCustomPagination, SearchFilter } from "../../App";
import BulkAction from "../CustomComponents/BulkActions";
import { HTTP } from "../../axios";
import { useParams } from "react-router-dom";
import { path } from "../../request";

export const RepresentativesList = (props) => {
  const translate = useTranslate();
  const refresh = useRefresh();
  const notify = useNotify();
  const [currency, setCurrency] = React.useState("default");

  return (
    <List
      {...props}
      filters={<SearchFilter />}
      pagination={<MyCustomPagination />}
      sort={{ field: "id", order: "DESC" }}
      actions={
        <ListActions
          setCurrency={setCurrency}
          currency={currency}
          hasCreate={"representative.create"}
        />
      }
    >
      <Datagrid
        bulkActionButtons={
          <BulkAction {...props} hasDelete="representative.delete" />
        }
      >
        <FunctionField
          label={translate("resources.root.name")}
          sortBy={"firstName"}
          render={(record) =>
            `${record.firstName} ${record.middleName} ${record.lastName}`
          }
        />
        <TextField source="email" label={translate("resources.root.email")} />
        <TextField source="phone" label={translate("resources.root.phone")} />
        <TextField
          source="address"
          label={translate("resources.root.address")}
        />
        {currency === "default" && (
          <FunctionField
            label={translate("resources.root.currentLoan")}
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              record.loanByMain
                ? `$${record.mcCurrentLoan}`
                : `${record.scCurrentLoan} ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.currentLoan")}
            sortBy={"mcCurrentLoan"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcCurrentLoan}`
                : `${record.scCurrentLoan} ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency === "default" && (
          <FunctionField
            label={translate("resources.root.salary")}
            sortBy={"mcBasicSalary"}
            render={(record) =>
              record.salaryByMain
                ? `$${record.mcBasicSalary}`
                : `${record.scBasicSalary} ${translate("resources.root.iqd")}`
            }
          />
        )}
        {currency !== "default" && (
          <FunctionField
            label={translate("resources.root.salary")}
            sortBy={"mcBasicSalary"}
            render={(record) =>
              currency === "dollar"
                ? `$${record.mcBasicSalary}`
                : `${record.scBasicSalary} ${translate("resources.root.iqd")}`
            }
          />
        )}
        <FunctionField
          label={translate("resources.root.type")}
          sortBy={"userType"}
          render={(record) => {
            return (
              <TextField
                className={`btn btn-danger`}
                style={{
                  fontSize: "17px",
                  fontWeight: "bold",
                  padding: "10px",
                  cursor: "pointer",
                }}
                source="userType"
                label={record.userType}
                onClick={() => {
                  HTTP.post(
                    `${url}/Representatives/ToggleRepresentativeType?id=${
                      record.id
                    }&Cashvan=${record.userType !== "Cashvan"}`
                  )
                    .then(() => {
                      notify("Successfully Toggled Selected Rows", {
                        type: "success",
                        undoable: true,
                      });
                      refresh();
                    })
                    .catch((err) =>
                      notify(err.response.data.message, {
                        type: "error",
                        undoable: true,
                      })
                    );
                }}
              />
            );
          }}
        />
        {localStorage.getItem("showNote") === "true" && (
          <TextField source="note" label="resources.root.note" />
        )}

        {localStorage.getItem("showLastUser") === "true" && (
          <TextField source="lastUser" label="resources.root.lastUser" />
        )}
        {localStorage.getItem("showCreatedAt") === "true" && (
          <FunctionField
            sortBy={"createdAt"}
            label="resources.root.createdAt"
            render={(record) =>
              record.createdAt
                ? moment(record.createdAt).format("DD/MM/YYYY HH:MM:SS A")
                : "-"
            }
          />
        )}
        <EditButton />
        <ShowButton />
      </Datagrid>
    </List>
  );
};

export const RepresentativesShow = () => {
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [allSelected, setAllSeleted] = React.useState(false);
  const [selectedSatIds, setSelectedSatIds] = React.useState([]);
  const [selectedSunIds, setSelectedSunIds] = React.useState([]);
  const [selectedMonIds, setSelectedMonIds] = React.useState([]);
  const [selectedTueIds, setSelectedTueIds] = React.useState([]);
  const [selectedWedIds, setSelectedWedIds] = React.useState([]);
  const [selectedThuIds, setSelectedThuIds] = React.useState([]);
  const [selectedFriIds, setSelectedFriIds] = React.useState([]);

  let ids = [];

  const notify = useNotify();
  const refresh = useRefresh();

  React.useEffect(() => {
    allSelected ? setSelectedIds(ids) : setSelectedIds([]);
  }, [allSelected]);

  const checkOne = (id, day) => {
    HTTP.post(
      `${url}/RepresentativeCustomers/ToggleDays?day=${day}&Toggle=true&value=false`,
      [id]
    )
      .then((res) => {
        notify("Successfully Toggled Selected Rows");
        refresh();
      })
      .catch((err) =>
        notify(err.response.data.message, { type: "error", undoable: true })
      );
  };

  const updateSellPrice = (e, id, type) => {
    HTTP.put(
      `${url}/StockRepresentatives/${id}?newPrice=${parseFloat(
        e.target.value
      )}&smallMeasure=${type}`,
      [id]
    )
      .then((res) => {
        notify("Successfully Updated");
      })
      .catch((err) => {
        if (err.response.status === 444) {
          window.location = "#/Settings/create";
        }
        notify(err.response.data.message, { type: "error", undoable: true });
      });
  };
  const deleteProductStock = (e, record) => {
    if (record.qteInStock > 0) {
      swal.fire({
        position: "top-end",
        icon: "error",
        title: translate("resources.root.cantDeleteNonZeroProducts"),
        showConfirmButton: false,
        timer: 1500,
      });
    } else {
      swal
        .fire({
          title: translate("resources.root.areYouSure"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: translate("resources.root.yes"),
          cancelButtonText: translate("resources.root.No"),
          reverseButtons: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            HTTP.post(
              `${url}/Products/RemoveProductFromRepresentative/${record.id}`,
              {}
            )
              .then((response) => {
                refresh();
              })
              .catch((err) =>
                notify(err.response.data.message, {
                  type: "error",
                  undoable: true,
                })
              );
          }
        });
    }
  };
  const translate = useTranslate();

  let stockRepresentativesProps = {
    basePath: "/StockRepresentatives",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "StockRepresentatives",
    exporter: false,
  };

  let representativeCustomersProps = {
    basePath: "/RepresentativeCustomers",
    hasCreate: false,
    hasEdit: false,
    hasList: true,
    hasShow: false,
    history: {},
    location: { pathname: "/", search: "", hash: "", state: undefined },
    match: { path: "/", url: "/", isExact: true, params: {} },
    options: {},
    permissions: null,
    resource: "RepresentativeCustomers",
    exporter: false,
  };

  const { id: representativeId } = useParams();

  return (
    <div>
      <Show>
        <TabbedShowLayout>
          <Tab label={translate("resources.root.Representative")}>
            <FunctionField
              label={translate("resources.root.name")}
              render={(record) =>
                `${record.firstName} ${record.middleName} ${record.lastName}`
              }
            />
            <TextField
              source="userType"
              label={translate("resources.root.userType")}
            />
            <TextField
              source="email"
              label={translate("resources.root.email")}
            />
            <TextField
              source="phone"
              label={translate("resources.root.phone")}
            />
            <TextField
              source="address"
              label={translate("resources.root.address")}
            />
            {
              <FunctionField
                label={translate("resources.root.currentLoan")}
                render={(record) =>
                  record.loanByMain
                    ? `$${record.mcCurrentLoan}`
                    : `${record.scCurrentLoan} ${translate(
                        "resources.root.iqd"
                      )}`
                }
              />
            }
            {
              <FunctionField
                label={translate("resources.root.salary")}
                render={(record) =>
                  record.salaryByMain
                    ? `$${record.mcBasicSalary}`
                    : `${record.scBasicSalary} ${translate(
                        "resources.root.iqd"
                      )}`
                }
              />
            }

            <FunctionField
              label="resources.root.attachment"
              render={(record) =>
                record.attachment !== null && (
                  <div style={{ position: "relative" }}>
                    <img
                      style={{
                        width: 200,
                        height: 200,
                        objectFit: "scale-down",
                        cursor: "pointer",
                      }}
                      src={`${path}${record.attachment}`}
                      alt="Attachment"
                      onClick={() =>
                        window.open(`${path}${record.attachment}`, "_blank")
                      }
                    />
                  </div>
                )
              }
            />
          </Tab>
          <Tab label={translate("resources.root.stock")}>
            <List
              filters={<SearchFilter />}
              {...stockRepresentativesProps}
              filter={{ subLevelId: representativeId }}
              sort={{ field: "id", order: "DESC" }}
            >
              {/* <ArrayField source="stockRepresentatives" label=""> */}
              <Datagrid>
                <FunctionField
                  label={translate("resources.root.id")}
                  render={(record) => record.product.id}
                />
                <FunctionField
                  label={translate("resources.root.barcode")}
                  render={(record) => record.product.barcode}
                />
                <FunctionField
                  label={translate("resources.root.name")}
                  render={(record) => record.product.name}
                />
                <FunctionField
                  label={translate("resources.root.sellPrice")}
                  render={(record) => (
                    <MaterialTextField
                      label={
                        record.sellByMain
                          ? `$`
                          : `${translate("resources.root.iqd")}`
                      }
                      defaultValue={
                        record.sellByMain
                          ? `${record.mcSellPrice}`
                          : `${record.scSellPrice}`
                      }
                      onBlur={(event) =>
                        updateSellPrice(event, record.id, false)
                      }
                    />
                  )}
                />
                <TextField
                  source="product.productMeasure"
                  label={translate("resources.root.productMeasure")}
                />
                <FunctionField
                  label={translate("resources.root.sellPrice")}
                  render={(record) => (
                    <MaterialTextField
                      label={
                        record.sellByMain
                          ? `$`
                          : `${translate("resources.root.iqd")}`
                      }
                      defaultValue={
                        record.sellByMain
                          ? `${record.mcSmallMeasureSellPrice}`
                          : `${record.scSmallMeasureSellPrice}`
                      }
                      onBlur={(event) =>
                        updateSellPrice(event, record.id, true)
                      }
                    />
                  )}
                />
                <TextField
                  source="product.oneMeasure"
                  label={translate("resources.root.oneMeasure")}
                />
                <TextField
                  source="qteInStock"
                  label={translate("resources.root.qteInStock")}
                />
                <BooleanField
                  source="sellByMain"
                  label={translate("resources.root.sellByMain")}
                />

                <FunctionField
                  label={translate("resources.root.sellPrice")}
                  render={(record) => (
                    <IconButton
                      onClick={(event) => deleteProductStock(event, record)}
                    >
                      <FaTrash />
                    </IconButton>
                  )}
                />
              </Datagrid>
              {/* </ArrayField> */}
            </List>
          </Tab>
          <Tab label={translate("resources.root.customers")}>
            {/* <ArrayField source="representativeCustomers" label=""> */}
            <List
              filters={<SearchFilter />}
              {...representativeCustomersProps}
              filter={{ subLevelId: representativeId }}
              sort={{ field: "id", order: "DESC" }}
            >
              <Datagrid>
                <TextField
                  source="placeName"
                  label={translate("resources.root.placeName")}
                />
                <TextField
                  source="owner"
                  label={translate("resources.root.owner")}
                />

                <FunctionField
                  label={translate("resources.root.phone")}
                  render={(record) => record.phone.replace("+964", "0")}
                />
                <FunctionField
                  label={translate("resources.root.currentLoan")}
                  render={(record) =>
                    record.loanByMain
                      ? `$${record.mcCurrentLoan?.toLocaleString()}`
                      : `${record.scCurrentLoan?.toLocaleString()} ${translate(
                          "resources.root.iqd"
                        )}`
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedSatIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.saturday")}
                    </p>
                  }
                  render={(record) =>
                    record.saturday ? (
                      <CheckIcon
                        style={{
                          color: selectedSatIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 0)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedSatIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 0)}
                      />
                    )
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedSunIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.sunday")}
                    </p>
                  }
                  render={(record) =>
                    record.sunday ? (
                      <CheckIcon
                        style={{
                          color: selectedSunIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 1)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedSunIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 1)}
                      />
                    )
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedMonIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.monday")}
                    </p>
                  }
                  render={(record) =>
                    record.monday ? (
                      <CheckIcon
                        style={{
                          color: selectedMonIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 2)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedMonIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 2)}
                      />
                    )
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedTueIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.tuesday")}
                    </p>
                  }
                  render={(record) =>
                    record.tuesday ? (
                      <CheckIcon
                        style={{
                          color: selectedTueIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 3)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedTueIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 3)}
                      />
                    )
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedWedIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.wednesday")}
                    </p>
                  }
                  render={(record) =>
                    record.wednesday ? (
                      <CheckIcon
                        style={{
                          color: selectedWedIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 4)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedWedIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 4)}
                      />
                    )
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedThuIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.thursday")}
                    </p>
                  }
                  render={(record) =>
                    record.thursday ? (
                      <CheckIcon
                        style={{
                          color: selectedThuIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 5)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedThuIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 5)}
                      />
                    )
                  }
                />
                <FunctionField
                  label={
                    <p
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setSelectedFriIds((prev) => {
                          return prev[0] ? [] : ids;
                        })
                      }
                    >
                      {translate("resources.root.friday")}
                    </p>
                  }
                  render={(record) =>
                    record.friday ? (
                      <CheckIcon
                        style={{
                          color: selectedFriIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 6)}
                      />
                    ) : (
                      <ClearIcon
                        style={{
                          color: selectedFriIds.includes(record.id) && "red",
                        }}
                        onClick={() => checkOne(record.id, 6)}
                      />
                    )
                  }
                />
                <FunctionField
                  render={(record) => {
                    !ids.includes(record.id) && (ids = [...ids, record.id]);
                    return record.gpsLock ? (
                      <LockIcon
                        style={{
                          color: selectedIds.includes(record.id) && "red",
                        }}
                        onClick={() => {
                          setSelectedIds((prevIds) =>
                            prevIds.includes(record.id)
                              ? prevIds.filter((el) => el !== record.id)
                              : [...prevIds, record.id]
                          );
                        }}
                      />
                    ) : (
                      <LockOpenIcon
                        style={{
                          color: selectedIds.includes(record.id) && "red",
                        }}
                        onClick={() => {
                          setSelectedIds((prevIds) =>
                            prevIds.includes(record.id)
                              ? prevIds.filter((el) => el !== record.id)
                              : [...prevIds, record.id]
                          );
                        }}
                      />
                    );
                  }}
                  label={
                    <p style={{ cursor: "pointer" }}>
                      {translate("resources.root.gpsLock")}
                    </p>
                  }
                  onClick={(e) => console.log(e)}
                />
              </Datagrid>
            </List>
            {/* </ArrayField> */}
          </Tab>
        </TabbedShowLayout>
      </Show>
      <MaterialButton
        variant="contained"
        disabled={
          !selectedIds[0] &&
          !selectedSatIds[0] &&
          !selectedSunIds[0] &&
          !selectedMonIds[0] &&
          !selectedTueIds[0] &&
          !selectedWedIds[0] &&
          !selectedThuIds[0] &&
          !selectedFriIds[0]
        }
        color="primary"
        style={{ marginTop: 10, marginRight: 5 }}
        onClick={() => {
          HTTP.post(
            `${url}/RepresentativeCustomers/ToggleEverything?Toggle=true&value=true`,
            {
              days: [
                selectedSatIds.length > 0 && 0,
                selectedSunIds.length > 0 && 1,
                selectedMonIds.length > 0 && 2,
                selectedTueIds.length > 0 && 3,
                selectedWedIds.length > 0 && 4,
                selectedThuIds.length > 0 && 5,
                selectedFriIds.length > 0 && 6,
                selectedFriIds.length > 0 && 6,
                selectedIds.length > 0 && 7,
              ].filter((el) => el || el === 0),
              ids: ids,
            }
          )
            .then((res) => {
              notify("Successfully Toggled Selected Rows");
              setSelectedIds([]);
              setSelectedSatIds([]);
              setSelectedSunIds([]);
              setSelectedMonIds([]);
              setSelectedTueIds([]);
              setSelectedWedIds([]);
              setSelectedThuIds([]);
              setSelectedFriIds([]);
              refresh();
            })
            .catch((err) =>
              notify(err.response.data.message, {
                type: "error",
                undoable: true,
              })
            );
        }}
      >
        {translate("resources.root.toggle")}
      </MaterialButton>
      <MaterialButton
        variant="contained"
        disabled={
          !selectedIds[0] &&
          !selectedSatIds[0] &&
          !selectedSunIds[0] &&
          !selectedMonIds[0] &&
          !selectedTueIds[0] &&
          !selectedWedIds[0] &&
          !selectedThuIds[0] &&
          !selectedFriIds[0]
        }
        color="primary"
        style={{ marginTop: 10, marginRight: 5 }}
        onClick={() => {
          HTTP.post(
            `${url}/RepresentativeCustomers/ToggleEverything?Toggle=false&value=true`,
            {
              days: [
                selectedSatIds.length > 0 && 0,
                selectedSunIds.length > 0 && 1,
                selectedMonIds.length > 0 && 2,
                selectedTueIds.length > 0 && 3,
                selectedWedIds.length > 0 && 4,
                selectedThuIds.length > 0 && 5,
                selectedFriIds.length > 0 && 6,
                selectedFriIds.length > 0 && 6,
                selectedIds.length > 0 && 7,
              ].filter((el) => el || el === 0),
              ids: ids,
            }
          )
            .then((res) => {
              notify("Successfully Toggled Selected Rows");
              setSelectedIds([]);
              setSelectedSatIds([]);
              setSelectedSunIds([]);
              setSelectedMonIds([]);
              setSelectedTueIds([]);
              setSelectedWedIds([]);
              setSelectedThuIds([]);
              setSelectedFriIds([]);
              refresh();
            })
            .catch((err) =>
              notify(err.response.data.message, {
                type: "error",
                undoable: true,
              })
            );
        }}
      >
        {translate("resources.root.lockAll")}
      </MaterialButton>
      <MaterialButton
        variant="contained"
        disabled={
          !selectedIds[0] &&
          !selectedSatIds[0] &&
          !selectedSunIds[0] &&
          !selectedMonIds[0] &&
          !selectedTueIds[0] &&
          !selectedWedIds[0] &&
          !selectedThuIds[0] &&
          !selectedFriIds[0]
        }
        color="primary"
        style={{ marginTop: 10, marginRight: 5 }}
        onClick={() => {
          HTTP.post(
            `${url}/RepresentativeCustomers/ToggleEverything?Toggle=false&value=false`,
            {
              days: [
                selectedSatIds.length > 0 && 0,
                selectedSunIds.length > 0 && 1,
                selectedMonIds.length > 0 && 2,
                selectedTueIds.length > 0 && 3,
                selectedWedIds.length > 0 && 4,
                selectedThuIds.length > 0 && 5,
                selectedFriIds.length > 0 && 6,
                selectedFriIds.length > 0 && 6,
                selectedIds.length > 0 && 7,
              ].filter((el) => el || el === 0),
              ids: ids,
            }
          )
            .then((res) => {
              notify("Successfully Toggled Selected Rows");
              setSelectedIds([]);
              setSelectedSatIds([]);
              setSelectedSunIds([]);
              setSelectedMonIds([]);
              setSelectedTueIds([]);
              setSelectedWedIds([]);
              setSelectedThuIds([]);
              setSelectedFriIds([]);
              refresh();
            })
            .catch((err) =>
              notify(err.response.data.message, {
                type: "error",
                undoable: true,
              })
            );
        }}
      >
        {translate("resources.root.unlockAll")}
      </MaterialButton>
    </div>
  );
};

export const RepresentativesCreate = (props) => {
  const translate = useTranslate();
  const [gender, setGender] = React.useState(true);
  const [userType, setUserType] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  const [salaryCurrency, setSalaryCurrency] = React.useState(true);

  const classes = useStyles();
  React.useEffect(() => {}, []);

  return (
    <Create
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <SimpleForm>
        <div className={`row row-cols-1 row-cols-md-2 row-cols-lg-3 gap`}>
          <TextInput
            source="firstName"
            label={translate("resources.root.firstName")}
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="middleName"
            label={translate("resources.root.middleName")}
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="lastName"
            label={translate("resources.root.lastName")}
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="email"
            label={translate("resources.root.email")}
            className={classes.input}
          />
          <TextInput
            source="address"
            label={translate("resources.root.address")}
            className={classes.input}
          />
          <TextInput
            source="phone"
            label={translate("resources.root.phone")}
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="phone2"
            label={translate("resources.root.phone2")}
            className={classes.input}
          />
          <SelectInput
            source="userType"
            label={translate("resources.root.userType")}
            validate={[required()]}
            className={classes.input}
            choices={[
              { id: "Cashvan", name: translate("resources.root.cashvan") },
              {
                id: "Representative",
                name: translate("resources.root.Representative"),
              },
            ]}
          />
          {salaryCurrency ? (
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <NumberInput
                    validate={[required(), minValue(0)]}
                    min={0}
                    source="mcBasicSalary"
                    defaultValue={0}
                    label={translate("resources.root.mcBasicSalary")}
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
          ) : (
            <FormDataConsumer>
              {({ formData, ...rest }) => {
                return (
                  <NumberInput
                    validate={[required(), minValue(0)]}
                    min={0}
                    defaultValue={0}
                    source="scBasicSalary"
                    label={translate("resources.root.scBasicSalary")}
                    className={classes.input}
                  />
                );
              }}
            </FormDataConsumer>
          )}
          <BooleanInput
            source="salaryByMain"
            className={classes.input}
            label={
              salaryCurrency
                ? translate("resources.root.salaryUsd")
                : translate("resources.root.salaryIqd")
            }
            defaultValue={true}
            onChange={(e) => setSalaryCurrency(e.target.checked)}
          />

          <DateInput
            source="dob"
            label={translate("resources.root.dob")}
            defaultValue={moment().subtract(18, "years")}
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="country"
            label={translate("resources.root.country")}
            defaultValue="Iraq"
            className={classes.input}
          />
          <TextInput
            source="city"
            label={translate("resources.root.city")}
            defaultValue="Erbil"
            className={classes.input}
          />
          <TextInput
            source="nationality"
            label={translate("resources.root.nationality")}
            defaultValue="Iraqi"
            className={classes.input}
            validate={[required()]}
          />
          <TextInput
            source="note"
            label={translate("resources.root.note")}
            className={classes.input}
          />
          <TextInput
            source="idCardType"
            label={translate("resources.root.idCardType")}
            className={classes.input}
          />
          <TextInput
            source="idCardNumber"
            label={translate("resources.root.idCardNumber")}
            className={classes.input}
          />
          {currency ? (
            <NumberInput
              validate={[required(), minValue(0)]}
              min={0}
              defaultValue={0}
              source="mcRoofLoan"
              label={translate("resources.root.maxPossibleLoanInUSD")}
              className={classes.input}
            />
          ) : (
            <NumberInput
              validate={[required(), minValue(0)]}
              min={0}
              defaultValue={0}
              source="scRoofLoan"
              label={translate("resources.root.maxPossibleLoanInIQD")}
              className={classes.input}
            />
          )}
          <BooleanInput
            source="loanByMain"
            className={classes.input}
            label={
              currency
                ? translate("resources.root.loanUsd")
                : translate("resources.root.loanIqd")
            }
            defaultValue={true}
            onChange={(e) => setCurrency(e.target.checked)}
          />
          <BooleanInput
            source="male"
            label={
              gender
                ? translate("resources.root.male")
                : translate("resources.root.female")
            }
            defaultValue={true}
            onChange={(e) => setGender(e.target.checked)}
            className={classes.input}
            validate={[required()]}
          />
        </div>
      </SimpleForm>
    </Create>
  );
};

export const RepresentativesEdit = (props) => {
  const [gender, setGender] = React.useState(true);
  const [currency, setCurrency] = React.useState(true);
  const [salaryCurrency, setSalaryCurrency] = React.useState(true);
  const translate = useTranslate();
  const dp = localStorage.getItem("dollarPrice");
  const classes = useStyles();
  const notify = useNotify();
  const refresh = useRefresh();
  const { id } = useParams();

  return (
    <Edit
      {...props}
      transform={(data) => ({ ...data, country: "Iraq", city: "Erbil" })}
      redirect="list"
    >
      <SimpleForm>
        <div className={classes.container}>
          <div className={classes.formCol}>
            <TextInput
              source="firstName"
              label={translate("resources.root.firstName")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="middleName"
              label={translate("resources.root.middleName")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="lastName"
              label={translate("resources.root.lastName")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="email"
              label={translate("resources.root.email")}
              className={classes.input}
            />
            <TextInput
              source="address"
              label={translate("resources.root.address")}
              className={classes.input}
            />
            <TextInput
              source="phone"
              label={translate("resources.root.phone")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="phone2"
              label={translate("resources.root.phone2")}
              className={classes.input}
            />
            <SelectInput
              source="userType"
              label={translate("resources.root.userType")}
              validate={[required()]}
              className={classes.input}
              choices={[
                { id: "Cashvan", name: translate("resources.root.cashvan") },
                {
                  id: "Representative",
                  name: translate("resources.root.Representative"),
                },
              ]}
            />
            {salaryCurrency ? (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      // max={formData.mcRoofLoan ? formData.mcRoofLoan : 0}
                      min={0}
                      source="mcBasicSalary"
                      label={translate("resources.root.salary")}
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            ) : (
              <FormDataConsumer>
                {({ formData, ...rest }) => {
                  return (
                    <NumberInput
                      validate={[required(), minValue(0)]}
                      min={0}
                      source="scBasicSalary"
                      label={translate("resources.root.salary")}
                      className={classes.input}
                    />
                  );
                }}
              </FormDataConsumer>
            )}
            <BooleanInput
              source="salaryByMain"
              className={classes.input}
              label={
                salaryCurrency
                  ? translate("resources.root.salaryUsd")
                  : translate("resources.root.salaryIqd")
              }
              defaultValue={true}
              onChange={(e) => setSalaryCurrency(e.target.checked)}
            />

            <ImageInput
              source="attachment"
              label="resources.root.attachment"
              accept="image/*"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
            <FunctionField
              label="resources.root.attachment"
              render={(record) =>
                record.attachment !== null && (
                  <div style={{ position: "relative" }}>
                    <button
                      type="button"
                      className="btn btn-danger p-2"
                      style={{ right: "0px", position: "absolute" }}
                      onClick={() => {
                        HTTP.delete(
                          `${url}/Representatives/DeleteRepresentativeImage?id=${id}`
                        )
                          .then(() => {
                            notify("Image deleted successfully", {
                              type: "success",
                              undoable: true,
                            });
                            refresh();
                          })
                          .catch((err) =>
                            notify(err.response.data.message, {
                              type: "error",
                              undoable: true,
                            })
                          );
                      }}
                    >
                      <span>&times;</span>
                    </button>
                    <img
                      style={{
                        width: 200,
                        height: 200,
                        objectFit: "scale-down",
                        cursor: "pointer",
                      }}
                      src={`${path}${record.attachment}`}
                      alt="Attachment"
                      onClick={() =>
                        window.open(`${path}${record.attachment}`, "_blank")
                      }
                    />
                  </div>
                )
              }
            />
          </div>
          <div className={classes.formCol}>
            <DateInput
              source="dob"
              label={translate("resources.root.dob")}
              defaultValue={moment().subtract(18, "years")}
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="country"
              label={translate("resources.root.country")}
              defaultValue="Iraq"
              className={classes.input}
            />
            <TextInput
              source="city"
              label={translate("resources.root.city")}
              defaultValue="Erbil"
              className={classes.input}
            />
            <TextInput
              source="nationality"
              label={translate("resources.root.nationality")}
              defaultValue="Iraqi"
              className={classes.input}
              validate={[required()]}
            />
            <TextInput
              source="note"
              label={translate("resources.root.note")}
              className={classes.input}
            />
            <TextInput
              source="idCardType"
              label={translate("resources.root.idCardType")}
              className={classes.input}
            />
            <TextInput
              source="idCardNumber"
              label={translate("resources.root.idCardNumber")}
              className={classes.input}
            />
            {currency ? (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="mcRoofLoan"
                label={translate("resources.root.maxPossibleLoanInUSD")}
                className={classes.input}
              />
            ) : (
              <NumberInput
                validate={[required(), minValue(0)]}
                min={0}
                source="scRoofLoan"
                label={translate("resources.root.maxPossibleLoanInIQD")}
                className={classes.input}
              />
            )}
            <BooleanInput
              source="loanByMain"
              className={classes.input}
              label={
                currency
                  ? translate("resources.root.loanUsd")
                  : translate("resources.root.loanIqd")
              }
              defaultValue={true}
              onChange={(e) => setCurrency(e.target.checked)}
            />
            <BooleanInput
              source="male"
              label={
                gender
                  ? translate("resources.root.male")
                  : translate("resources.root.female")
              }
              defaultValue={true}
              onChange={(e) => setGender(e.target.checked)}
              className={classes.input}
              validate={[required()]}
            />
          </div>
        </div>
      </SimpleForm>
    </Edit>
  );
};

const useStyles = makeStyles({
  container: {
    display: "flex",
    width: "100%",
    alignItems: "flex-start",
  },
  formCol: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  input: {
    width: "70%",
    paddingLeft: "10px",
  },
  table: {
    minWidth: 500,
    marginTop: 20,
  },
  TableHead: {
    backgroundColor: "#111",
  },
  TableHeadCell: {
    color: "#fff",
  },
  addStoreSection: {
    display: "flex",
    justifyContent: "space-between",
    width: 500,
  },
});
