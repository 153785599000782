import * as React from "react";
import { url, handleRequestResponse } from "../../request";
import Pagination from "react-js-pagination";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { useRedirect, useTranslate } from "react-admin";
import moment from "moment";
import PrintProvider, { Print, NoPrint } from "react-easy-print";
import { FaPrint } from "react-icons/fa";
import LoadingScreen from "../CustomComponents/LoadingScreen";
import AsyncSelect from "react-select/async";
import { toCsv } from "../CustomComponents/Helpers";
import { HTTP } from "../../axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import loadOptions from "../../loadOptions";

export default (SupplierReport) => {
  const translate = useTranslate();
  const redirect = useRedirect();
  const [activePage, setActivePage] = React.useState(1);
  const [totalItems, setToTalItems] = React.useState(0);
  const [perPage, setPerPage] = React.useState(10);
  const [start, setStart] = React.useState(0);
  const [end, setEnd] = React.useState(10);
  const [data, setData] = React.useState([]);
  const [supplier, setSupplier] = React.useState(0);
  const [supplierObject, setSupplierObject] = React.useState([]);
  const [startDate, setStartDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = React.useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startFromZero, setStartFromZero] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  function handlePageChange(pageNumber) {
    setActivePage(pageNumber);
    setStart((pageNumber - 1) * perPage);
    setEnd(pageNumber * perPage);
  }

  async function getData() {
    let sendDates = ``;
    if (startDate && startDate.length > 0) {
      sendDates += `&startDate=${startDate}`;
    }
    if (endDate && endDate.length > 0) {
      sendDates += `&endDate=${endDate}`;
    }

    await HTTP.get(
      `${url}/Reports/SupplierReport/${supplier}?_start=${start}&_end=${end}&zero=${startFromZero}${sendDates}`
    )
      .then((response) => {
        setToTalItems(response.headers["x-total-count"]);
        setData(response.data);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          redirect("/Login");
        }
        console.log(error.response.status);
      });
    setIsLoading(false);
  }

  React.useEffect(
    function () {
      setIsLoading(true);
      getData();
    },
    [endDate, start, end]
  );

  React.useEffect(
    function () {
      setStart(0);
      setEnd(perPage);
    },
    [perPage]
  );

  return (
    <div>
      <PrintProvider>
        <Print single name={`supplierStatement`}>
          <div className={`text-center`}>
            <h1 className="text-primary mb-3 text-center d-inline-block">
              {translate("resources.root.supplierReport")}
              {supplier === 0 ? "" : "(" + supplierObject.supplierName + ")"}
            </h1>
          </div>
          <NoPrint force>
            <Row style={{ fontSize: "15px" }}>
              {isLoading && <LoadingScreen />}
              <Col className="col-12 col-lg-4 d-flex align-items-center justify-content-start m-0  mt-4 px-3">
                <p className="m-0 mr-2 text-nowrap">
                  {translate("resources.root.supplier")} :
                </p>
                <AsyncSelect
                  defaultOptions
                  className="flex-grow-1"
                  loadOptions={loadOptions.suppliers}
                  onChange={(e) => {
                    setSupplier(e ? e.value : 0);
                    setSupplierObject(e ? e.supplier : 0);
                  }}
                />
              </Col>
              <Col className="col-12 col-lg-8  mt-4">
                <Row>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="startDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2 text-nowrap">
                          {translate("resources.root.startDate")} :
                        </Form.Label>
                        <Form.Control
                          className=""
                          label="startDate"
                          type="date"
                          value={startDate}
                          onChange={(e) => setStartDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                  <Col className="col-12 col-md-6 mb-3">
                    <Form.Group controlId="endDate">
                      <Col className="d-flex align-items-center m-0 p-0">
                        <Form.Label className="m-0 mr-2  text-nowrap">
                          {translate("resources.root.endDate")} :
                        </Form.Label>
                        <Form.Control
                          type="date"
                          value={endDate}
                          onChange={(e) => setEndDate(e.target.value)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>

              <Col className="col-3 mt-4">
                <InputGroup className="row justify-content-center justify-content-md-start justify-content-lg-center mx-md-5 mx-lg-0  ">
                  <FormControlLabel
                    className="m-0 text-nowrap "
                    control={
                      <Switch
                        onChange={(e) => setStartFromZero(e.target.checked)}
                        defaultChecked={startFromZero}
                      />
                    }
                    label={translate("resources.root.startFromZero")}
                  />
                </InputGroup>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  id="export"
                  variant={"primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    toCsv(
                      document.getElementById("exportableTable"),
                      "download.csv"
                    );
                  }}
                >
                  {translate("resources.root.exportCsv")}
                </Button>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  variant={"outline-primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    let sidebar =
                      document.getElementsByClassName("MuiDrawer-root");
                    let appMenu =
                      document.getElementsByClassName("MuiPaper-root");
                    let appBar = document.getElementsByClassName("theRoot");
                    let table = document.getElementsByTagName("table");
                    sidebar[0].style.display = "none";
                    appMenu[0].style.display = "none";
                    appBar[0].style.marginTop = "0px";
                    table[0].style.fontSize = "12px";
                    window.print();
                    sidebar[0].style.display = "block";
                    appMenu[0].style.display = "block";
                    appBar[0].style.marginTop = "40px";
                    table[0].style.fontSize = "15px";
                  }}
                >
                  <FaPrint /> {translate("resources.root.print")}{" "}
                </Button>
              </Col>
              <Col className="col-3 mt-4">
                <Button
                  variant={"primary btn-block"}
                  disabled={supplier === 0}
                  onClick={() => {
                    setIsLoading(true);
                    getData();
                  }}
                >
                  {translate(
                    `resources.root.${isLoading ? "isLoading" : "search"}`
                  )}
                </Button>
              </Col>
            </Row>
          </NoPrint>
          <Row style={{ fontSize: "15px" }} className={`mt-2`}>
            <Col>
              <Table id={`exportableTable`} bordered hover>
                <thead>
                  <tr>
                    <th>{translate("resources.root.id")}</th>
                    <th>{translate("resources.root.invoiceNumber")}</th>
                    <th>{translate("resources.root.qty")}</th>
                    <th>{translate("resources.root.dollarWithdraw")}</th>
                    <th>{translate("resources.root.dinarWithdraw")}</th>
                    <th>{translate("resources.root.dollarDeposit")}</th>
                    <th>{translate("resources.root.dinarDeposit")}</th>
                    <th>{translate("resources.root.balance")}</th>
                    <th>{translate("resources.root.date")}</th>
                    <th>{translate("resources.root.description")}</th>
                    <th>{translate("resources.root.note")}</th>
                  </tr>
                </thead>
                <tbody>
                  {!isLoading &&
                    data &&
                    data.map((one) => {
                      return (
                        <tr
                          style={{
                            backgroundColor: one.isWithdraw
                              ? "#BFEAA3"
                              : "#FCE8E9",
                          }}
                        >
                          <td>{one.id}</td>
                          <td>
                            <a
                              href={
                                one.description === "Supplier Payment"
                                  ? `#/SupplierPayments/${one.id}/show`
                                  : one.description === "Return Invoice"
                                  ? `#/PurchaseReturnInvoices/${one.id}/show`
                                  : one.description === "Supplier Invoice"
                                  ? `#/PurchaseInvoices/${one.id}/show`
                                  : "#/"
                              }
                              target={"_blank"}
                            >
                              {one.invoiceNumber &&
                                one.invoiceNumber.toUpperCase()}
                            </a>
                          </td>
                          <td>{one.qte}</td>
                          <td>{one.mcWithdraw?.toLocaleString()}</td>
                          <td>{one.scWithdraw?.toLocaleString()}</td>
                          <td>{one.mcDeposit?.toLocaleString()}</td>
                          <td>{one.scDeposit?.toLocaleString()}</td>
                          <td>
                            {one.balance?.toLocaleString()}{" "}
                            {supplierObject.loanByMain
                              ? translate("resources.root.usd")
                              : translate("resources.root.iqd")}
                          </td>
                          <td>
                            {moment(one.date).format("YYYY-MM-DD hh:mm:ss A")}
                          </td>
                          <td>{one.description}</td>
                          <td>{one.note ?? "-"}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
              <NoPrint force>
                <div style={{ position: "relative" }}>
                  <InputGroup style={{ width: "200px", float: "right" }}>
                    <InputGroup.Text>
                      {translate("resources.root.perPage")}
                    </InputGroup.Text>
                    <Form.Control
                      onChange={(e) => setPerPage(e.target.value)}
                      as="select"
                      custom
                    >
                      <option value={10}>10</option>
                      <option value={50}>50</option>
                      <option value={100}>100</option>
                      <option value={200}>200</option>
                      <option value={500}>500</option>{" "}
                      <option value={1000}>1000</option>
                      <option value={2500}>2500</option>
                      <option value={5000}>5000</option>
                    </Form.Control>
                  </InputGroup>
                  <Pagination
                    activePage={activePage}
                    itemsCountPerPage={perPage}
                    totalItemsCount={totalItems}
                    pageRangeDisplayed={5}
                    onChange={handlePageChange}
                    prevPageText={translate("resources.root.previous")}
                    nextPageText={translate("resources.root.next")}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                  <div className={`clearfix`}> </div>
                </div>
              </NoPrint>
            </Col>
          </Row>
        </Print>
      </PrintProvider>
    </div>
  );
};
